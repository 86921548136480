.btn-en {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: calc((100vh - 10.5rem) * 0.438 * 0.065);
  height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.13);
  left: 1.75%;
  top: 70%;
  border-radius: 0 calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1)
    calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1) 0;
  background-color: var(---primary);
  opacity: 0.3;
  cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
}
.btn-ar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: calc((100vh - 10.5rem) * 0.438 * 0.065);
  height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.13);
  right: 1.75%;
  top: 70%;
  border-radius: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1) 0 0
    calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1);
  background-color: var(---primary);
  opacity: 0.3;
  cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
}

.drawer-view {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 36px;
  .drawer-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    background-color: var(---listBg);
    transition: width 2s ease-out;
    padding: 2% 5px 3% 5px;
    overflow-y: auto;
    font-weight: 300;
    gap: 3px;
    border-radius: 0 0 0 36px;

    .drawer-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1);
      min-height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.1);
      justify-content: flex-start;
      align-items: center;
      border-radius: 6px;
      padding: 0 3px;
      gap: 3px;

      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      .drawer-item-icon {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 3px;
        color: var(---primary);
      }
      .drawer-item-label {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: var(---primary);
      }
    }
    .selected-item {
      background-color: var(---SelectedDrawerItem);
      font-weight: 700;
    }
    .isModuleChild {
      padding-left: 12px;
    }
  }
  .drawer-container::-webkit-scrollbar {
    display: none;
  }
  .void {
    display: flex;
    flex-direction: column;
    width: 25%;
    background-color: #0003;
    .drawer-btn2-en {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.065);
      height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.13);
      margin-top: calc((100vh - 8rem) * 0.817 * 0.72);
      border-radius: 0 calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1)
        calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.1) 0;
      background-color: var(---primary);
      opacity: 0.3;
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    }
    .drawer-btn2-ar {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      width: calc((100vh - 2rem) * 0.438 * 0.065);
      height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.13);
      left: 17.5%;
      top: 70%;
      border-radius: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1) 0 0
        calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.1);
      background-color: var(---primary);
      opacity: 0.3;
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    }
  }
}
