.input2 {
  min-height: 2.5rem;
  font-size: 14px;
  padding: 0 0.5rem;
  border: 1px solid var(--BorderStroke);
  border-radius: 2px;
  color: var(--text);
  cursor: text;
  background-color: transparent;
  font-family: "Hanken Grotesk", sans-serif;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.menuDropdown {
  display: grid;
  grid-template-rows: repeat(auto-fill, 2rem);
  width: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .menuBtn {
    display: flex;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
  }
  .withSeparator {
    border-bottom: 1px solid #eee;
  }
}
.deleteBtn {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: row;
}

.Input-container {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  max-height: 2.5rem;
  min-height: 2.5rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 0px 14px;
  border: 1px solid #aaaaaa;
  width: 100%;
  .utilityValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    color: #4a3535;
  }
  .utilityInput {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    color: #39354a;
    border-color: transparent;
  }
  .utilityInput:focus {
    border-color: transparent;
    outline: none;
  }
  .UtilityLabel {
    font-size: 12px;
    font-weight: 400;
    color: #aaaaaa;
    margin-left: 0.5rem;
  }
}

.selectOption {
  display: flex;
  align-items: center;
  justify-content: center;
}

.conditionProperty {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  max-height: 2.5rem;
  min-height: 2.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .Condition-container {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
    width: calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    border-radius: 2px;
    padding: 0px 0.5rem;
    border: 1px solid #eee;
    justify-content: space-between;
    align-items: center;
    border-radius: 200px;
    .conditionRef {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .conditionActions {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      gap: 0.5rem;
      .unexistRefMsg {
        overflow: hidden;
        color: #dc2626;
        text-align: right;
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        // display: block;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
      }
    }
  }
  .deleteCondition {
    display: flex;
    max-height: 2.5rem;
    min-height: 2.5rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
    .dltCondition {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
    .UnexistRefDel {
      background-color: red;
    }
  }
  .UnexistRef {
    border: 2px dashed red;
    color: red;
  }
}

.deleteUtility {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.DropDownOptions {
  color: var(--BorderStroke);
}
