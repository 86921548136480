.editorContainer.Draft {
  border-top: solid 2px #4c36a5;
  border-right: solid 2px #4c36a5;
  border-left: solid 2px #4c36a5;
  border-radius: 6px 6px 0px 0px;
  .editorHeader {
    background-color: #fbfbfb;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 4rem;
    border-radius: 3px 3px 0px 0px;
    .editorName {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 1%;
      .statutDraft {
        background-color: #4c36a5;
        color: white;
        border-radius: 18px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
    .editorProprety {
      display: flex;
      align-items: center;
      gap: 14px;
      .changesDraft {
        color: #4c36a5;
      }
      .iconEditor {
        display: flex;
        cursor: url(../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      }
      .separator {
        display: flex;
        width: 3px;
        min-width: 3px;
        border-radius: 50px;
        margin: 0 5px;
        background-color: var(--separator);
        height: 24px;
        min-height: 24px;
      }
    }
  }
}
.editorContainer.newLeft {
  border-top: solid 2px #ec5c55;
  border-right: solid 2px #ec5c55;
  border-left: solid 2px #ec5c55;
  border-radius: 6px 6px 0px 0px;
  .editorHeader {
    background-color: #fbfbfb;
    padding: 1rem;
    width: 100%;
    border-radius: 3px 3px 0px 0px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 4rem;
    .editorName {
      display: inherit;
      /* align-items: center; */
      grid-gap: 10px;
      gap: 10px;
      align-items: center;
      padding: 1%;

      width: 360px;
      .statutNew {
        background-color: #ec5c55;
        color: white;
        border-radius: 18px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .statutNew .hoveredContext {
        display: none;
      }
      .statutNew:hover .originalContext {
        display: none;
      }
      .statutNew:hover .hoveredContext {
        display: flex;
        cursor: url(../../../ComponentsLibrary/Assets/Pointer.svg), auto;
        border-radius: 18px;
        align-items: center;
      }

      .iconClose {
        margin-right: -3.1px;
        margin-top: 1px;
      }
    }
    .editorProprety {
      display: flex;
      align-items: center;
      gap: 15px;

      .changesLeft {
        color: #ec5c55;
      }
      .iconEditor {
        display: flex;
      }
    }
  }
}
.editorContainernewRight {
  border-top: solid 2px #36a5a5;
  border-right: solid 2px #36a5a5;
  border-left: solid 2px #36a5a5;
  border-radius: 6px 6px 0px 0px;
  .editorHeader {
    display: flex;
    background-color: #fbfbfb;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    border-radius: 3px 3px 0px 0px;
    align-items: center;
    height: 4rem;
    .headerName {
      display: inherit;
      /* align-items: center; */
      grid-gap: 10px;
      gap: 10px;
      align-items: center;
      padding: 1%;

      width: 360px;

      .rightStatut {
        background-color: #36a5a5;
        color: white;
        border-radius: 18px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .rightStatut .hoveredContext {
        display: none;
      }
      .rightStatut:hover .originalContext {
        display: none;
      }
      .rightStatut:hover .hoveredContext {
        display: flex;
        cursor: url(../../../ComponentsLibrary/Assets/Pointer.svg), auto;
        border-radius: 18px;
        align-items: center;
      }
      .iconClose {
        margin-right: -3.1px;
        margin-top: 1px;
      }
    }
    .editorProprety {
      display: flex;
      align-items: center;
      gap: 15px;
      .changesRight {
        color: #36a5a5;
      }
      .iconEditor {
        display: flex;
      }
    }
  }
}
.editorValue {
  border: solid 1px #36a5a5;
  display: contents;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0);
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 132px;
  border-radius: 81px;
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
