.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  height: 100%;
  overflow-y: auto;
  padding: 4px 0;
  gap: 10px;
  border-radius: 0 0 36px 36px;
  .toolbar-container {
    display: flex;
    width: 100%;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    color: #6f53a3;
  }
  .signature-container {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    box-shadow: 0 4px 6px 0 #21212526;
    border-radius: 9px;
    background-color: white;
    .signature-body {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 5%;
      .signature-label {
        color: var(--primary);
        font-weight: 600;
        font-size: 15px;
      }
      .signature-date {
        color: #000;
        font-weight: 400;
        font-size: 10px;
        display: flex;
        align-items: center;
      }
    }
    .signature-icon {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  .carroussel-container {
    width: 335px;
    height: 250px;

    .carroussel-body {
      width: 332px;
      height: 245px;
      background-color: #000;
      margin: 2px 1px;
      .editImage {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #4c36a583;
        color: white;
        padding: 5px 10px;
        z-index: 1;
      }
      .fullScreen {
        display: flex;
        position: absolute;
        bottom: 5px;
        right: 0;
        background-color: #4c36a583;
        color: white;
        padding: 5px 10px;
        z-index: 1;
      }
      .swipeSlide-container {
        height: 250px;
        display: flex;
        justify-content: center;
        //background: black;
        align-items: center;
        .image-index {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background-color: #4c36a583;
          color: white;
          padding: 5px 10px;
          border-radius: 15px;
          font-size: 12px;
        }
      }
      .swiper-button-prev {
        background: url(../../../../../../../assets/icons/swipe-left.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
      .swiper-button-next .swiper-button-prev {
        width: calc(var(--swiper-navigation-size) / 20 * 27);
      }
      .swiper-button-next {
        background-image: url(../../../../../../../assets/icons/swipe-right.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
      .swiper-button-next::after,
      .swiper-button-prev::after {
        content: "";
      }
    }
  }
  .Map-View {
    display: flex;
    width: 100%;
    height: 97%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 36px 36px;
    .TabMap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 0;
      width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      min-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      max-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      // height: calc((100vh - 19.5rem) * 0.91) !important;
      // max-height: calc((100vh - 19.5rem) * 0.91) !important;
      // min-height: calc((100vh - 19.5rem) * 0.91) !important;
      height: 97% !important;
      border-radius: 36px;
    }
    .TabMapRelation {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 0;
      width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      min-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      max-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
      height: calc((100vh - 19.5rem) * 0.91) !important;
      max-height: calc((100vh - 19.5rem) * 0.91) !important;
      min-height: calc((100vh - 19.5rem) * 0.91) !important;

      border-radius: 36px;
    }
  }
  .tab-list {
    display: flex;
    width: 100%;
    height: auto;
  }
  .drag-section-zone {
    display: flex;
    width: 100%;
    height: auto;
  }
  .drop-tab-empty {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    .empty-tab {
      display: flex;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: 1px solid #aaaaaa;
      justify-content: center;
      align-items: center;
    }
  }
}
.tab-container::-webkit-scrollbar {
  display: none;
}
