.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc((100vh - 10.5rem) * 0.9 * 0.185);
  padding: 5px;
  background-color: var(---listBg);
  border-radius: 9px; //  a revenir
  .image-container {
    display: flex;
    width: 100%;
    height: calc((100vh - 10.5rem) * 0.9 * 0.185 * 0.85);
    max-height: calc((100vh - 10.5rem) * 0.9 * 0.185 * 0.85);
    min-height: calc((100vh - 10.5rem) * 0.9 * 0.185 * 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-label {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: calc((100vh - 10.5rem) * 0.9 * 0.185 * 0.15);
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: x-small;
    padding: 0 5px;
    gap: 5px;
    text-overflow: ellipsis;
    color: red;
    // background-color: red;
  }
}
