.setupComponentContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  flex-direction: column;
  .VerificationBody {
    display: flex;
    min-width: 100%;
    min-height: 30%;
    flex-direction: column;
    align-items: center;
    .VerificationTitle {
      color: var(--TitleContainer);
      font-family: "Hanken Grotesk";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 5%;
    }
  }
  .setupBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .setupTitles {
      display: flex;
      padding: 2px;
      flex-direction: row;
      justify-content: center;
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: column;
      height: 90%;
      max-width: 100%;

      .setup-messages {
        display: flex;
        flex: block;
        color: var(--cool-black, #39354a);
        margin: 3% 0;
        font-family: "Hanken Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-direction: row;

        .setup-title {
          display: flex;
          margin-right: 3%;
          flex-shrink: 0;
          color: var(--Globe);
        }
        .successMessage {
          color: var(--Green);
          font-family: "Hanken Grotesk";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 2%;
          .setup-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            height: 30px;
            width: 30px;
          }
        }
        .setupContent {
          display: flex;
          flex-direction: row;
          width: 50%;
          .errorMessage {
            color: var(--Red);
            font-family: "Hanken Grotesk";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 2%;
          }
          .setup-icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 30px;
            min-width: 50px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .setupFooter {
      display: flex;
      text-align: right;
      align-items: center;
      border-top: 1px solid var(--BorderDashed);
      justify-content: end;
      padding-top: 2%;
      .setup-next-button {
        margin-left: 2%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: var(--SupportButtonBg);
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 2px;
        font-size: 16px;
      }
      .grey-out {
        background-color: #dcdcdc;
        color: #908d9a;

        cursor: not-allowed;
      }
    }
  }
}
