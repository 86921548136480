.revisions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 16px 5.5px 0 16px;
  gap: 10px;
  background-color: var(--CardBg);
  .revision-search {
    display: flex;
    width: calc(100% - 5.5px);
    max-width: calc(100% - 5.5px);
    min-width: calc(100% - 5.5px);
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--searchRevisionBackground);
    border: 1px solid var(--BorderStroke);
    margin-right: 16px;
    padding: 0.5rem 0.5rem;

    .search-icon {
      display: flex;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
    }
    .search-input {
      display: flex;
      width: calc(100% - 49px);
      height: 100%;
      border: none;
      background-color: transparent;
      color: var(--text)

    }
  }
  .revision-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 90px);
    overflow-y: auto;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 50px;
  }
  /* width */
  .revision-list::-webkit-scrollbar {
    width: 5px;
    min-width: 5px;
    max-width: 5px;
  }

  /* Track */
  .revision-list::-webkit-scrollbar-track {
    background: white;
    width: 5px;
    min-width: 5px;
    max-width: 5px;
  }

  /* Handle */
  .revision-list::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 10px;
    width: 5px;
    min-width: 5px;
    max-width: 5px;
  }
}
