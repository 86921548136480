.disconnect-modal-container {
  height: 30%;
  width: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: var(---listBg);
  border-radius: 9px;
  .disconnect-modal-header {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title-disconnect-txt {
      color: var(---primary);
      font-weight: 700;
    }
    .horizontal-line {
      width: 90%;
      height: 0.5px;
      background-color: #e2e2e2;
    }
  }
  .disconnect-modal-body {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: row;
    .icon-disconnect-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-disconnect-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      .text-disconnect-style {
        font-size: 12px;
        font-weight: 600;
        color: var(---textColor);
      }
    }
  }
  .disconnect-modal-footer {
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    .btn-cancel-disconnect {
      height: 70%;
      min-height: 70%;
      min-width: 40%;
      width: 40%;
      background-color: var(---cancelButton);
      color: var(---textColor);
      border-width: 0px;
      border-radius: 6px;
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    }
    .btn-confirm-disconnect {
      height: 70%;
      min-height: 70%;

      min-width: 40%;
      width: 40%;
      background-color: var(---primary);
      color: #fff;
      border-width: 0px;
      border-radius: 6px;
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    }
  }
}
