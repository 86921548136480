.delete-media-modal-container {
  height: 30%;
  width: 92%;
  background-color: #fff;
  border-radius: 9px;
  padding: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.delete-media-modal-header {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-modal-delete-media {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #6a549e;
}

.delete-media-modal-body {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.media-image-container {
  width: 30%;
  height: 100%;
  padding: 3px;
}

.media-image-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: #fff;
  border-radius: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #6a549e;
  justify-content: center;
  align-items: center;
}

.file-content {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 22px;
  width: 18px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6a549e;
  z-index: 1;
  padding: 2px;
}

.media-delete-msg-avert {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  padding: 2px;
  font-size: 13px;
  font-weight: 500;
}

.delete-media-modal-footer {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cancel-btn-delete-media {
    height: 80%;
    width: 47%;
    background-color: #e2e2e2;
    border-radius: 9px;
    color: #000;
  }
  .confirm-btn-delete-media {
    height: 80%;
    width: 47%;
    background-color: #6a549e;
    border-radius: 9px;
    color: #fff;
  }
}
