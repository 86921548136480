.swipe-containerLg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0 0 36px 36px;
    gap: 2%;
    .close-button {
      display: flex;
      width: calc((100vh - 10.5rem) * 0.47 * 0.94 * 0.1);
      height: calc((100vh - 12.5rem) * 0.47 * 0.94 * 0.1);
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: white;
      .timesIcon {
        color: var(---primary);
      }
    }
    .swipe {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-height: 75%;
      min-height: 17%;
      border-radius: 9px 9px 36px 36px;
      padding: 15px;
      background-color: white;
      .language-option {
        display: flex;
        cursor: pointer;
        width: 100%;
        padding: 5px;
      
      .language-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 2px;
      }
    }}}
  



