.label-kpi {
  display: flex;
  width: calc(((100vh - 10.5rem) * 0.47 * 0.456) - 12px);
  min-width: calc(((100vh - 10.5rem) * 0.47 * 0.456) - 12px);
  justify-content: space-between;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 5px;
  font-size: 14px;
  gap: 5%;
  color: var(---textColor);
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(---listBg);
    color: var(---label);
    font-weight: 700;
    width: 20%;
  }
  .label-kpi-label {
    max-width: 75%;
    min-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}
.label-large {
  width: calc(((100vh - 10.5rem) * 0.9 * 0.47) - 12px);
}
