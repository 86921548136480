.sidebar-Monitor-container {
  position: relative;
  padding-right: 1.5rem;
  background: var(--SpBG);
  .monitor-sideBar {
    position: relative;
    min-width: 18.6rem;
    background: var(--SpBG);
    overflow-y: auto;
    padding: 1rem;
    padding-right: 6px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    height: 100%;
    overflow-y: auto;
    .sidebar-users {
      display: flex;
      flex-direction: column;
      gap: 0.62rem;
      .users-title {
        color: var(--UsersTitle);
        font-family: Hanken Grotesk;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .sidebar-performance {
      display: flex;
      flex-direction: column;
      gap: 0.62rem;
      .performance-title {
        color: var(--PerformanceTitle);
        font-family: Hanken Grotesk;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .toggle-sidebar {
    position: absolute;
    right: -5px;
    bottom: 50%;
    background-color: transparent;
    border: none;
    .icon-rotated {
      transform: rotate3d(0, 9, 0, 180deg);
    }
  }
}
