.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  background-color: var(--BodyBg);
  overflow-y: auto; 
}
