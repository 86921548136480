.question-container {
  height: 82%;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title-txt {
      font-size: 22px;
      font-weight: 700;
      color: #6a549e;
    }
    .title-txt-content {
      font-size: 12px;
      color: #6a549e;
    }
  }
  .question-num-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .question-txt {
      font-size: 22px;
      font-weight: 700;
      color: #6a549e;
    }
    .required-txt {
      font-size: 12px;
      color: #aaa;
    }
  }
  .question-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .input-observation-container {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    height: 50px;
    gap: 1.5px;
    flex: 1 0 0;
    .input-observation {
      height: 100%;
      width: 100%;
      border-radius: 9px;
      border: 1px solid #eee;
      background: #fff;
    }
  }
}
.feet {
  padding: 5px;
  height: 18%;
  width: 100%;
  .back-next-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .back-btn {
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 9px;
      border: 1px solid #aaa;
      background-color: #fff;
      opacity: 0.2;
      box-shadow: 0px 0px 10px 0px rgba(131, 86, 245, 0.25);
    }
    .next-btn {
      display: flex;
      padding: 7px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 9px;
      background-color: #fff;
      border: 1px solid #aaa;
      box-shadow: 0px 0px 10px 0px rgba(131, 86, 245, 0.25);
    }
  }
  .save-complete-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3px;
    .save-btn {
      border-radius: 9px;
      border: 2px solid #6a549e;
    }
    .complete-btn {
      display: flex;
      width: 150px;
      padding: 5px 2px;
      justify-content: center;
      align-items: center;
      color: #fff;
      gap: 10px;
      border-radius: 9px;
      background: #6a549e;
      box-shadow: 0px 0px 10px 0px rgba(131, 86, 245, 0.25);
    }
  }
}

.uploaded-photos-container {
  min-height: 40px;
  height: 60px;
  width: 100%;
  max-width: 100%;

  // background-color: #aaa;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  // padding: 5px;
}
.photo-uploaded {
  height: 25px;
  width: 25px;
  background-color: aqua;
  margin: 5px;
}

.media-image-container {
  width: 100%;
  min-width: 40% !important;
  height: 100%;
  // background-color: yellow;
  // padding: 3px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.media-image-wrap {
  height: 100%;
  width: 70px;
  max-width: 70px;
  display: flex;
  position: relative; /* Changed to relative */
  background-color: #fff;
  border-radius: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #6a549e;
  justify-content: center;
  align-items: center;
}

.file-content {
  position: absolute;
  bottom: 0; /* Positioned at the bottom */
  right: 0; /* Positioned at the right */
  height: 22px;
  width: 18px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6a549e;
  z-index: 1;
  padding: 2px;
}

.description-txt {
  font-size: 11px;
  color: #000;
}

.visual-title {
  font-size: 12px;
  font-weight: 700;
  color: #6a549e;
}
.input-check {
  border-radius: 3px;
  border-width: 2px;
  border-color: #c8c8c8;
  background-color: #fff;
  border-style: solid;
}

.content-qte {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.file-upload-wrapper {
  min-height: 50px;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.file-upload-btn {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 500px;
  border-width: 1px;
  border-style: solid;
  border-color: #6a549e;
}
