.ExplorerItems {
  margin-top: 20px;
  .add-new-page {
    margin-bottom: 20px;
    margin-left: 20px;
    background: transparent;
    border: none;

    .new-page {
      margin-left: 10px;
      color: #6f53a3;
      font-size: 12px;
    }
  }
}
