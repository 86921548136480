.layer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .right-btns-map-container {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((22.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      width: 40px;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        width: auto;
        border-radius: 25px;
        border: 1px, 0px, 0px, 0px;
        gap: 10px;
        padding: 3px;
        overflow: auto;
        max-height: 220px;
        opacity: 0.9;
        transition: opacity 0.3s;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          padding: 7px 7px;
          border-radius: 500px;
          margin: 3px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .right-btns-map-container-without-retry {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((22.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 100px;
        max-width: 100px;
        overflow: auto;
        padding: 3px;
        width: 40px;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .right-top-btns-map-container {
    min-height: 60px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((19vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .btn-back-wrapper {
      height: 34px;
      width: 34px;
      border-radius: 500px;
      background-color: #fff;
    }
  }
  .left-bottom-btns-map-container {
    min-height: 50px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((18.5vh - 10.5rem) * 0.9 * 0.82) !important;
    right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .btns-line-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .btn-style {
        height: 35px;
        width: 35px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
      }
      .btn-style-target {
        height: 40px;
        width: 40px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        align-self: "center";
        text-align: center;
      }
    }
  }
}

.list-extended-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  text-align: start;
  z-index: 2;
  top: calc((47.5vh - 10.5rem) * 0.9 * 0.82) !important;
  right: calc((34vh - 10.5rem) * 0.9 * 0.47 * 0.9);
}
.btns-line-content::-webkit-scrollbar{
  display: none;
}