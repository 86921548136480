.Sync-Search-Section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  width: 100%;
  margin-bottom: 8px;

  .Sync-Search-bar {
    height: 40px;
    min-width: 93%;
    border-radius: 2px;
    border: 1px solid var(--monitorBorder);
    background-color: var(--CardBg);
    padding: 10px;
    color: var(--Globe);
    &:focus {
      outline: none;
      border-color: var(--inputBorder);
    }
    &:hover {
      outline: none;
      border-color: var(--inputBorder);
    }
  }

  .Sync-Search-bar::placeholder {
    font-family: "Hanken Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--InputLabel);
  }
  .Sync-search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--inputBorder);
    min-height: 40px;
    min-width: 40px;
    border-radius: 4px;
    border: 1px solid var(--monitorBorder);
    margin-left: 10px;
  }
}
