.IMXLogger-container {
  padding: 1rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 1.25rem;
  overflow-y: auto;
  background: var(--monitorBg2);
  .IMXLogger-main {
    display: flex;
    justify-content: space-between;
    color: var(--monitorText);
    .vertical-seperator {
      width: 1px;
      height: 100%;
      background: var(--monitorBorder);
    }
  }
}
