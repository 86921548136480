.CompareFile-Container {
  background-color: #fff;
  width: 100%;
  padding: 16px;
  .CompareFiles {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: none;
    height: 100%;
    border: 1px solid #eeeeee;
    width: 100%;
    .split3 {
      width: 50%;
      padding-right: 5px;
    }
    .readOnly3 {
      width: 50%;
    }
    .split4 {
      width: 50%;
      padding-left: 5px;
      border-left: 1px solid var(--BorderStroke);
    }
    .readOnly4 {
      width: 50%;

      border-left: 1px solid var(--BorderStroke);
    }
  }
}
