/* Color Scheme for Visual Studio Code Light Theme */
.cm-s-vscode-light span.cm-meta {
  color: #0000ff;
}
.cm-s-vscode-light span.cm-number {
  color: #098658;
}
.cm-s-vscode-light span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: #0000ff;
}
.cm-s-vscode-light span.cm-def {
  color: #ff0000;
}
.cm-s-vscode-light span.cm-variable {
  color: #001080;
}
.cm-s-vscode-light span.cm-variable-2 {
  color: #001080;
}
.cm-s-vscode-light span.cm-variable-3,
.cm-s-vscode-light span.cm-type {
  color: #000000;
}
.cm-s-vscode-light span.cm-property {
  color: #001080;
}
.cm-s-vscode-light span.cm-operator {
  color: #000000;
}
.cm-s-vscode-light span.cm-string {
  color: #0000ff;
}
.cm-s-vscode-light span.cm-string-2 {
  color: #a31515;
}
.cm-s-vscode-light span.cm-comment {
  color: #008000;
}
.cm-s-vscode-light span.cm-link {
  color: #0000ff;
}
.cm-s-vscode-light span.cm-atom {
  color: #0000ff;
}
.cm-s-vscode-light span.cm-error {
  color: #bc3f3c;
}
.cm-s-vscode-light span.cm-tag {
  color: #800000;
}
.cm-s-vscode-light span.cm-attribute {
  color: #ff0000;
}
.cm-s-vscode-light span.cm-qualifier {
  color: #daa520;
}
.cm-s-vscode-light span.cm-bracket {
  color: #0000ff;
}

.cm-s-vscode-light.CodeMirror {
  background: #ffffff;
  color: #000000;
}
.cm-s-vscode-light .CodeMirror-cursor {
  border-left: 1px solid #000000;
}
.CodeMirror-activeline-background {
  background: #f7f7f7;
}

.cm-s-vscode-light .CodeMirror-merge-r-chunk {
  background-color: #f28d88;
}
.cm-s-vscode-light .CodeMirror-merge-r-deleted {
  background-color: #a6d3c4;
}
.cm-s-vscode-light .CodeMirror-merge-l-deleted {
  background-color: #73c0c0;
}
.cm-s-vscode-light .CodeMirror-merge-l-chunk {
  background: #73c0c0;
}

/* .difContainerlight .codeMirrorContainer.CodeMirror-merge-pane
  .CodeMirror-merge-right
  .CodeMirror-merge-pane-rightmost {
  background: #73c0c0;
} */
.cm-s-vscode-light .CodeMirror-merge-r-deleted {
  background-color: #73c0c0;
}
.cm-s-vscode-light .CodeMirror-merge-r-inserted,
.CodeMirror-merge-l-inserted {
  background-color: #f28d88;
  background-image: none;
}
.cm-s-vscode-light .CodeMirror-merge-r-deleted,
.CodeMirror-merge-l-deleted {
  background-image: none;
  background-position: bottom left;
  background-repeat: repeat-x;
}

.cm-s-vscode-light .CodeMirror-merge-pane-rightmost {
  background: #73c0c0;
}
.cm-s-vscode-light div.CodeMirror-selected {
  background: #add6ff;
}
.cm-s-vscode-light .CodeMirror-gutters {
  background: #f0f0f0;
  border-right: 1px solid #ddd;
  color: #000;
}
.cm-s-vscode-light span.cm-builtin {
  color: #569cd6;
}
.cm-s-vscode-light {
  font-family: Consolas, "Courier New", monospace, serif;
}
.cm-s-vscode-light .CodeMirror-matchingbracket {
  background-color: #d0d0d0;
  color: #000000 !important;
}

.CodeMirror-hints.vscode-light {
  font-family: Consolas, "Courier New", monospace;
  color: #000000;
  background-color: #f5f5f5 !important;
}

.CodeMirror-hints.vscode-light .CodeMirror-hint-active {
  background-color: #e0e0e0 !important;
  color: #000000 !important;
}
