.dashboard-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100vh;
  .toggle-sidebar {
    position: absolute;
    padding: 0;
    width: 4px;
    height: 250px;
    right: 0;
    top: 41%;
    border: none;
    border-radius: 50px;
    opacity: 0.5px;
    background-color: #909090;
    z-index: 99;
  }

  // .advanced-search-container {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 20px;
  //   min-width: 387px;
  //   padding: 27px 16px;
  //   font-family: Hanken Grotesk;
  //   font-size: 14px;
  //   font-weight: 500;
  //   line-height: 18.24px;
  //   text-align: left;
  //   color: #39354a;
  //   .advanced-search-title {
  //     font-weight: 500;
  //   }
  //   .advanced-search-button-container {
  //     display: flex;
  //     flex-wrap: wrap;
  //     gap: 10px;

  //     .advanced-search-button {
  //       background-color: #e2d8fa;
  //       font-weight: 400;
  //       padding: 3px 10px 3px 10px;
  //       border-radius: 66px;
  //     }
  //     .advanced-search-button.inactive {
  //       opacity: 0.2;
  //     }
  //   }
  //   .advanced-search-options {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 20px;
  //   }
  // }

  // .monitor-Dashboard {
  //   display: grid;
  //   position: relative;
  //   grid-template-rows: min-content min-content min-content 1fr min-content;
  //   background: var(--monitorBg2);
  //   border-left: 1px solid var(--StatusBarBorder);
  //   height: calc(100vh - 3rem);
  //   flex-grow: 1;

  //   .search-table {
  //     width: 100%;
  //     padding: 0.563rem 0.625rem;
  //     display: flex;
  //     gap: 0.625rem;
  //     background: var(--MonitorSearchBgColor);
  //     border-bottom: 1px solid var(--StatusBarBorder);
  //     .advanced-filter {
  //       background-color: transparent;
  //       border: none;
  //     }
  //     input {
  //       width: 100%;
  //       border: none;
  //       justify-self: center;
  //       background: var(--MonitorSearchBgColor);
  //       color: var(--MonitorSearchColor);
  //       font-family: Hanken Grotesk;
  //       font-size: 14px;
  //       font-style: normal;
  //       font-weight: 400;
  //       line-height: normal;
  //     }
  //   }

  //   .monitor-table {
  //     color: var(--monitorText);
  //     font-family: Hanken Grotesk;
  //     font-size: 1rem;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: normal;
  //     display: grid;
  //     overflow-x: hidden;
  //     border-top: 1px solid var(--monitorBorder);
  //     background: var(--TableOdd);
  //   }

  // .table-pagination {
  //   background: var(--PaginationContainerBG);
  //   display: flex;
  //   gap: 0.5rem;
  //   justify-content: flex-start;
  //   padding-top: 0.594rem;
  //   padding-bottom: 0.563rem;
  //   grid-row: 5 / 6;s
  //   padding-left: 1rem;
  // }
}
