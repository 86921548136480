.dropdown {
  position: relative;
  display: inline-block;
  .dropdown-header {
    display: flex;
    align-items: center;
    cursor: url(../../views/Designer/components/ComponentsLibrary/Assets/Pointer.svg),
      auto;
    color: black;
  }
  .dropdown-options {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9999;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0.25rem;

    background-color: var(--header);
    border: 1px solid #eee;
    border-radius: 2px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    width: max-content;

    li {
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 14px;
      border: 0.5px solid #eee;
      color: var(--text);

      cursor: pointer;
      .opIcon {
        display: flex;
        padding-right: 10px;
      }
      .opLabel {
        display: flex;
        padding-right: 5px;
      }

      &:hover {
        background-color: var(--SupportButtonBg);
        color: #ffffff;
      }
    }
  }

  &.open .dropdown-options {
    display: flex;
  }
}
