.IMXLogger-main-right {
  width: 52%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .logFiles-container {
    display: flex;
    flex-direction: column;
    gap: 0.938rem;
    border-radius: 2px;
    max-width: 100%;

    .logFiles-title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.375rem 0;

      .logFiles-title {
        font-family: Hanken Grotesk;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--LoggerTitleColor);
      }
      .log-reload {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
      }
    }

    .logFiles-content-flex {
      display: flex;
      flex-direction: row;
      gap: 0.625rem;
      flex-wrap: wrap;
      .no-logs-loaded {
        flex: 1;
        padding: 1.594rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--loggerNoFileBackground);
        color: var(--loggerNoFileColor);
      }
    }
  }

  .horizontal-separator {
    width: 100%;
    height: 1px;
    border-radius: 2px;
    background-color: var(--monitorBorder);
  }

  .logger-config-container {
    display: flex;
    flex-direction: column;
    gap: 0.938rem;

    .logger-config-header-container {
      padding: 0.313rem 0;
      display: flex;
      justify-content: space-between;
      gap: 0.938rem;

      .logger-config-title-container {
        display: flex;
        flex-direction: column;
        gap: 0.313rem;

        .logger-config-title {
          font-family: Hanken Grotesk;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--LoggerTitleColor);
        }

        .logger-config-subtitle {
          font-family: Hanken Grotesk;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--monitorText);
          opacity: 0.5;
        }
      }
      .save-reload-icons {
        display: flex;
        align-self: baseline;
        gap: 1rem;

        .save-file {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
        }
        .reload-file {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
        }
      }
    }

    .loggers-input-container {
      display: flex;
      flex-direction: column;
      gap: 0.938rem;
    }
  }
}
