.lookupModal-container {
  display: flex;
  flex-direction: row;
  height: 45rem;
  min-height: 45rem;
  max-height: 45rem;

  gap: 1.5rem;
  .lookupContainer {
    display: flex;
    flex-direction: column;
    width: 23.75rem;
    min-width: 23.75rem;
    max-width: 23.75rem;
    background-color: var(--header);
    border-radius: 2px;
    .lcontent-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 3.25rem;
      max-height: 3.25rem;
      min-height: 3.25rem;
      border-bottom: 1px solid #ececec;
      padding: 0 0.75rem;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #4c36a5;
    }
    .lcontent-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      height: 41.75rem;
      max-height: 41.75rem;
      min-height: 41.75rem;
      padding: 0.75rem;
      .lookupBody {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        width: 100%;
        min-height: 50px;
        font-size: 14px;
        color: #6a549e;
        .lookupTitleContainer {
          display: flex;
          width: 100%;
          justify-content: space-between;
          background-color: #eeeeee80;
          padding: 0.813rem 1rem;
          .lookupTitle {
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-weight: 500;
            line-height: 18.24px;
            color: #4c36a5;
          }
          .lookupTitle.active {
            color: #ffffff;
          }
          .lookupReference {
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.24px;
            color: #39354a;
            opacity: 0.5;
          }
          .lookupReference.active {
            color: #ffffff;
            opacity: 0.5;
          }
        }

        .lookupTitleContainer.active {
          background-color: #4c36a5;
          color: #ffffff;
        }
        .lookupAddElm {
          display: flex;

          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid #eeeeee;
          gap: 0.5rem;
          width: 100%;
          height: 50px;
          max-height: 50px;
          min-height: 50px;
          .dropdownTitle {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: transparent;
            gap: 0.5rem;
          }
          .dropdownItem {
            padding: 0.5rem 1rem;
            border-top: 0.7px solid #eee;
            border-bottom: 0.7px solid #eee;
          }
          .addElmLabel {
            font-size: 14px;
          }
        }
        .whereclauses-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          min-height: 50px;
          color: black;

          .new-Lookup-element-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 1rem 1rem 0.813rem 1rem;
            .new-Lookup-element {
              display: flex;
              gap: 0.625rem;
            }
            .add-Whereclause {
              background-color: transparent;
              border: none;
            }
          }
          .whereclause-element {
            display: flex;
            flex-direction: column;
            width: 100%;

            .whereclause {
              display: flex;
              width: 100%;
              padding: 1rem 0;
              padding-left: 3rem;
              padding-right: 1rem;
              background: #fafafa;
              justify-content: space-between;
            }
            .whereclause.active {
              background-color: #4c36a5;
              color: #ffffff;
            }
            .whereclause-name-label {
              display: flex;
              gap: 0.625rem;
              .whereclause-name {
                font-family: Hanken Grotesk;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.24px;
              }
              .whereclause-label {
                font-family: Hanken Grotesk;
                font-size: 12px;
                font-weight: 400;
                color: #39354a;
                opacity: 0.5;
              }
            }
            .whereclause-closeBtn {
              background-color: transparent;
              border: none;
              margin-right: 4px;
            }
          }
        }
        .attribute-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          min-height: 50px;
          color: black;
          .attribute-element {
            display: flex;
            flex-direction: column;
            width: 100%;
            .attribute {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 1rem 0;
              padding-left: 3rem;
              padding-right: 1rem;
              background: #fafafa;
              border-bottom: 1px solid #eeeeee;
              .attribute-title-detail {
                display: flex;
                gap: 0.625rem;
                .attTitle {
                  font-family: Hanken Grotesk;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18.24px;
                }
                .attDetail {
                  font-family: Hanken Grotesk;
                  font-size: 12px;
                  font-weight: 400;
                  color: #39354a;
                  opacity: 0.5;
                  align-self: center;
                }
              }
              .attribute-closeBtn {
                background-color: transparent;
                border: none;
                margin-right: 4px;
              }
            }
            .attribute.active {
              background-color: #4c36a5;
              color: #ffffff;
            }
          }
        }

        .tags-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          min-height: 50px;
          color: black;
          .tag-element {
            display: flex;
            flex-direction: column;
            width: 100%;
            .tag {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 1rem 0;
              padding-left: 3rem;
              padding-right: 1rem;
              background: #fafafa;
              border-bottom: 1px solid #eeeeee;
              .tag-title-detail {
                display: flex;
                gap: 0.625rem;
                .tagTitle {
                  font-family: Hanken Grotesk;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18.24px;
                }
                .tagDetail {
                  font-family: Hanken Grotesk;
                  font-size: 12px;
                  font-weight: 400;
                  color: #39354a;
                  opacity: 0.5;
                }
              }
              .tag-closeBtn {
                background-color: transparent;
                border: none;
                margin-right: 4px;
              }
            }
            .tag.active {
              background-color: #4c36a5;
              color: #ffffff;
            }
          }
        }
      }
    }
    .lcontent-body::-webkit-scrollbar {
      width: 4px;
      border-radius: 10px;
    }
    .lcontent-body::-webkit-scrollbar-thumb {
      background-color: #a7a7a7;
      border-radius: 10px;
    }
    .lcontent-body::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  .LookupProperties {
    display: flex;
    flex-direction: column;
    width: 43.625rem;
    background-color: var(--header);
    border-radius: 2px;
    padding: 1rem;
    .lprop-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 1px solid #ececec;
      align-items: center;
      justify-content: space-between;
      .lookup-component {
        display: flex;
        gap: 5px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0.625rem;
        background: #4c36a533;
        border-left: 1px solid #4c36a54d;
        border-top: 1px solid #4c36a54d;
        border-right: 1px solid #4c36a54d;
        .lookup-component-title {
          color: #4c36a5b2;
        }
        .lookup-component-title span {
          color: #4c36a5;
          margin-left: 5px;
        }
      }
    }
    .lprop-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0.75rem;
      .itemTag {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 4rem;
        max-height: 4rem;
        min-height: 4rem;
        padding-top: 0.5rem;
        .SelectedItem {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          .SelectedItem-label {
            font-size: small;
            font-weight: 400;
            padding: 0px;
          }
          .SelectedItem-value {
            font-size: large;
            font-weight: 600;
            padding: 0px;
          }
        }
        .DeleteBtn {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 1rem;
        }
      }
      .HeaderProperties {
        display: grid;
        grid-template-columns: 20% 78%;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        height: 3rem;
        max-height: 3rem;
        min-height: 3rem;
        font-weight: 600;
      }
      .properties {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 0.5rem;
        padding-right: 0.5rem;
        .propertie {
          display: grid;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          padding-bottom: 1rem;
          gap: 2%;
        }
        .ConditionsProperties {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
          gap: 1rem 2%;
          padding-bottom: 0.5rem;
        }
      }
      .properties::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
      }
      .properties::-webkit-scrollbar-thumb {
        background-color: #a7a7a7;
        border-radius: 10px;
      }
      .properties::-webkit-scrollbar-track {
        border-radius: 10px;
      }
    }
    .lprop-readOnly {
      height: 41.75rem;
      max-height: 41.75rem;
      min-height: 41.75rem;
    }
    .lprop-footer {
      display: grid;
      margin-top: auto;
      grid-template-columns: 48.5% 48.5%;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 4rem;
      max-height: 4rem;
      min-height: 4rem;
      padding: 0 0.75rem;
    }
  }
}
