.path-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .drop-attributes-zone {
    display: flex;
    flex-direction: row;
  
    height: fit-content;
  }
}
