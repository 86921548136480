.drop-notifications {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 6px;
  .notifications-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    padding: 6px 0;
  }
  .notifications-container::-webkit-scrollbar {
    display: none;
  }
  .NoNotif {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
