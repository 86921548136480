.body-header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
  row-gap: 5px;
  column-gap: 5px;
  .body-header-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    font-size: 12px;
  }
}

