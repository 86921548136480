.field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: small;
  .field-label {
    font-size: small;
    color: var(---textColor);
  }
  .field-value {
    font-size: small;
  }
  .simpleInput {
    resize: none;
    width: 100%;
    padding: 2%;
    padding-bottom: 4%;
    padding-top: 4%;
    align-items: center;
    border-radius: 9px;
    background-color: transparent;
    border: 1px solid var(---borderFormInput);
    text &:focus {
      outline: none;
      border: 1px solid var(---primary);
    }
  }

  .filed-lookupPicker-not-readOnly {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid var(---cancelButton); // a revenir
    padding: 4%;
    padding-left: 2%;
    padding-right: 3%;
    border-radius: 9px;

    .field-lookup-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: small;
      .field-lookup-label {
      }
    }
    .field-lookup-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .filed-lookup-not-readOnly {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100%;
    border: 1px solid var(---cancelButton);
    padding: 1.5%;
    padding-left: 2%;
    padding-right: 3%;
    border-radius: 9px;

    &:focus {
      outline: none;
      border: 1px solid var(---primary);
    }
    .field-lookup-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .field-lookup-label {
        font-size: xx-small;
      }
      .field-lookup-value {
        font-size: small;
      }
    }
    .field-lookup-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .field-lookup {
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 42px;
    background-color: var(---cancelButton);
    border-radius: 9px;
    padding: 7px;

    .field-left {
      display: flex;
      color: var(---primary);
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }
  .field-expendedoptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    .values-expendedoptions {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 3%;

      .expendedOptions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2%;
        padding-left: 3%;
        padding-right: 3%;
        border: 1px solid var(---primary);
        border-radius: 9px;
        margin-top: 1.5%;
        margin-bottom: 1.5%;
        background-color: var(---listBg);
        color: var(---secondary);
      }
    }
  }
}
