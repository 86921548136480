.filter-form-container {
  height: 100%;
  align-self: flex-end;
  width: 100%;
  background-color: #fff;
  bottom: 0px;
  border-radius: 6px 6px 30px 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  .filter-form-content {
    min-height: 82%;
    max-height: 82%;
    width: 100%;
    min-width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    .list-item-content {
      min-height: 50px;
      width: 100%;
      background-color: #fff;
      margin: 3px;
      border-width: 1px;
      border-style: solid;
      border-color: #F3F3F3;
      border-radius: 9px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .label-icon-container{
         width: 80%;
         min-width: 80%;
         height: 100%;
         display: flex;
         padding: 5px;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
      }
      .drop-down-btn{
        width: 20%;
        min-width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .filter-form-footer {
    min-height: 18%;
    width: 100%;
    min-width: 100%;
    background-color: #f5f5f5;
    border-radius: 0px 0px 50px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    box-shadow: 0px -5px 25px 0px #22222226;

    .btn-cancel {
      height: 40%;
      min-height: 40%;
      width: 45%;
      min-width: 45%;
      background-color: #fff;
      color: #000;
      border-width: 0px;
      border-radius: 9px;
    }
    .btn-submit {
      height: 40%;
      min-height: 40%;
      width: 45%;
      min-width: 45%;
      background-color: #6a549e;
      color: #fff;
      border-width: 0px;

      border-radius: 9px;
    }
  }
}

.filter-form-content::-webkit-scrollbar {
  display: none;
}
