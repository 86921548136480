.SettingsScreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(---listBg);
  border-radius: 0 0 36px 36px;

  .headerSettings {
    display: flex;
    flex-direction: row;
    height: 6%;
    max-height: 6%;
    min-height: 6%;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    color: var(---textColor);
    .goBack {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7%;
      height: 100%;
    }
    .tabsList {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 93%;
      height: 100%;
      max-width: 90%;
      overflow-x: auto;
      .tabButtom {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-left: 8%;
        padding-right: 8%;
        font-size: small;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .tabsList::-webkit-scrollbar {
      height: 2px;
    }
  }
  // .contntSettings {
  //   display: flex;
  //   height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  //   max-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  //   min-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  //   width: 100%;
  //   justify-content: center;
  //   align-items: center;
  // }
}
