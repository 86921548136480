.ComponentsLibrary {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: calc(100vh - 6rem);
  max-height: calc(100vh - 6rem);
  background-color: var(--header);
  border-left: 1px solid var(--BorderStroke);
  .titleContainer {
    width: 100%;
    height: 3.5rem;
    min-height: 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
    color: var(--TitleContainer);
    border-bottom: 1px solid var(--BorderStroke);
    padding-left: 7px;
    .headerLabel {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .libraries {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 0.5rem;
    height: calc(100vh - 10.5rem);
    min-height: calc(100vh - 10.5rem);
    max-height: calc(100vh - 10.5rem);
    padding: 0 7px;
    .noMenu {
      display: flex;
      align-items: center;
      padding: 4% 0;
      border-bottom: 1px solid #eee;
      margin-bottom: 3%;

      .noComponent-desc {
        display: flex;
        flex-direction: row;
        color: #939298;
        font-size: 15px;
        padding-left: 4px;
      }
    }
    .menu {
      .menuHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: url(../ComponentsLibrary/Assets/Pointer.svg), auto;
        margin-bottom: 0.5rem;
        .css-yvetkp-MuiButtonBase-root-MuiIconButton-root {
          cursor: url(./Assets/Pointer.svg), auto;
        }
      }
      .componentList {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
      }
    }
  }
  .libraries::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
    border-radius: 10px;
  }

  .libraries::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 10px;
  }

  .libraries::-webkit-scrollbar-track {
    // background-color: #0ff; /* color of the track */
    border-radius: 10px;
  }
}
