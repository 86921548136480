.gauge-h3 {
  color: var(---textColor);
}
.gauge {
  display: flex;
  flex-direction: column;
  width: calc(((100vh - 10.5rem) * 0.47 * 0.425) - 12px);
  height: calc(((100vh - 12.5rem) * 0.47 * 0.425) - 12px);
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  color: var(---textColor);
  .gaugeGraph {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.gauge-large {
}
