.vertical-line {
  width: calc(100% - 5.5px);
  height: 1px;
  background-color: #eeeeee;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.publishedItem {
  display: flex;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  border: 2px solid transparent;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  height: 5.2rem;
  max-height: 5.2rem;
  min-height: 5.2rem;
  cursor: pointer;
  background-color: var(--publishedItem);
  margin-bottom: 10px;
  color: var(--Title);

  .published-content {
    display: flex;
    width: 92%;
    flex-direction: column;
    .published-section {
      display: flex;
      flex-direction: row;
      .published-state {
        color: var(--TitleContainer);
        font-family: "Hanken Grotesk";
        font-weight: 300;
        font-size: 16px;
        margin-right: 4px;
      }
      .published-InProd {
        color: #9693A1;
        font-family: "Hanken Grotesk";
        font-weight: 300;
        font-size: 16px;
        opacity: 50%;
      }
    }
    .published-name {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .release-datetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
 
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;
    .moreOptions-btn {
      color: #394a35;
      border-radius: 0px;
      width: 20px;
    }
  }
}
.publishedItem.selected{
  border: 2px solid var(--TitleContainer);
}
.revisionItem-selected {
  display: flex;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  // border: 2px solid var(--SupportButtonBg);
  color: var(--text);
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  cursor: pointer;
  .published-content {
    display: flex;
    width: 92%;
    flex-direction: column;
    .published-name {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .release-datetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;
    .moreOptions-btn {
      color: var(--text);
      border-radius: 0px;
      width: 20px;
    }
  }
}

.revisionItem-edit {
  display: flex;
  padding: 0.5rem 0.5625rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  margin-bottom: 10px;
  background-color: #4c36a5;
  color: white;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  cursor: pointer;
  .revisionContent {
    display: flex;
    width: 92%;
    flex-direction: column;
    .revisionName {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .releaseDatetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;

    .moreOptions-btn {
      color: white;
      border-radius: 0px;
      width: 20px;
    }
  }
}

.deleteContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
  gap: 8px;
  .deleteMsg {
    font-size: 16px;
    font-weight: 400;
  }
  .deleteFileName {
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .deleteFileDate {
    font-size: 16px;
    font-weight: 600;
  }
}

.renameContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
  .renameMsg {
    font-size: 16px;
    font-weight: 400;
  }
}
