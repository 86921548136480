.DropTargetModule {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  .module-card {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 0px;
    background-color: var(---listBg);
    border: 1px solid var(---cancelButton);

    .app-icon {
      display: flex;
      flex: 20%;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
    }
    .app-icon.open {
      color: var(---listBg);
    }
    .open-icon.openTimer {
      color: var(--timerText);
    }
    .open-icon.closeTimer {
      color: var(--timerText);
    }
    .app-icon.close {
      color: var(---iconAppItem);
    }

    .app-title {
      min-width: calc(100% - 80px);
      max-width: calc(100% - 80px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .app-title.open {
      color: var(---listBg);
    }
    .app-title.close {
      color: var(---AppText);
    }
    .app-title.openTimer {
      color: var(--timerText);
    }
    .app-title.closeTimer {
      color: var(--timerText);
    }
    .icon-chevron {
      display: flex;
      flex: 15%;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 50px;
    }
  }
  .icon-chevron.open {
    color: var(---listBg);
  }

  .icon-chevron.close {
    color: var(---primary);
  }
  .icon-chevron.closeTimer {
    color: var(---AppText);
  }
  .icon-chevron.openTimer {
    color: var(--timerText);
  }
  .openModule {
    background-color: var(---primary);
    border-bottom-right-radius: 0px;
  }

  .selectedCloseModule {
    width: calc(100% - 4px);
    margin: 2px;
  }
  .selectedOpenModule {
    width: calc(100% - 4px);
    margin: 2px;
    margin-bottom: 0px;
  }
  .apps-module {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-width: 1px;

    .drop-target-timer {
      border-radius: 5px;
      margin-top: 10px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      border: 1px solid #eee;
      .empty-attribute {
        display: flex;
        width: 90%;
        height: 50px;
        border-radius: 9px;
        border: 1px solid #aaaaaa;
        justify-content: center;
        align-items: center;
        color: #aaa;
      }
    }
    .drag-apps-module {
      display: flex;
      width: 83%;
      max-width: 83%;
      min-width: 83%;
      height: 50px;
      min-height: 50px;
      max-height: 50px;
      justify-content: center;
      align-items: center;
      margin-top: 1px;
      background-color: white;
      .app-module {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100;
        justify-content: flex-start;
        align-items: center;
        padding: 6px;
        color: var(---AppText);
        

        .icon-app-module {
          display: flex;
          width: 50px;
          height: 50px;
          justify-content: center;
          align-items: center;
          .notoperr {
            color: var(---iconAppItem);       
          }
        }
        .title-app-moduel {
          max-width: calc(100% - 50px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .lastAppModule {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    .drop-apps-empty-module {
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: center;
      align-items: center;
      .drag-app-empty-module {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: #00000080;
      }
    }
  }
  .selectedAppsModule {
    width: calc(100% - 4px);
    margin: 2px;
    margin-top: 0px;
  }
  .hovered {
    &:hover {
      // background-color: #89e90c30;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
  }
}
.DropTargetModule-matrix {
  display: flex;
  width: 100%;
  height: 100%;

  .module-card-matrix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(---primary);
    border-radius: 5px;
    padding: 6px 12px;
    color: white;
    .app-icon {
      display: flex;
      flex: 20%;
      width: 100%;
      max-width: 100%;
      height: 80%;
      max-height: 80%;
      min-height: 80%;
      justify-content: center;
      align-items: center;
    }
    .app-title {
      max-width: 100%;
      height: 20%;
      max-height: 20%;
      min-height: 20%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      font-size: 12px;
    }
  }
}
.DropTargetModule-empty {
  // background-color: red;
  border-radius: 5px;
}
.DropTargetModule-empty-matrix {
  background-color: #4c36a5;
  border-radius: 5px;
}
