.high-content-mark {
  height: auto;
  min-height: 18%;
  width: 96%;
  z-index: 2;
  position: absolute;
  top: 30%;
  bottom: 30%;
  right: 2%;
  left: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000080;

  padding: 10px;

  .marker-drop-container {
    height: auto;
    min-height: 50%;
    width: 86%;
    background-color: #fff;
    z-index: 2;
    position: absolute;
    top: 30%;
    bottom: 30%;
    right: 7%;
    left: 7%;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000080;
    border-width: 1px;
    border-color: #aaaaaa;
    border-style: solid;
    flex-direction: row;
    overflow-y: auto;

    padding: 3px;
    .img-content {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .data-content {
      width: 70%;
      max-height: 90%;
      overflow-y: auto;
    }

    .body-drop-container {
      height: auto;
      padding: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 60%;
      overflow-y: auto;

      border-radius: 9px;
      background-color: #f4f4f4;
    }
  }
}

.marker-drop-attribute-container {
  height: 100%;
  width: 100%;
  background-color: #aaaaaa;
}

.marker-drop-container:hover {
  background-color: #9db6cb;
}
.body-drop-container:hover {
  background-color: #9db6cb;
}

.dragAttributeContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.drop-target-body-marker {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.drop-target-body-marker::-webkit-scrollbar {
  display: none;
}

.body-drop-container::-webkit-scrollbar {
  display: none;
}
.marker-drop-container::-webkit-scrollbar {
  display: none;
}

.data-content::-webkit-scrollbar {
  display: none;
}
