.inspection-content-left-open {
  width: 50%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .home-tab-inspection {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-evenly;

    align-items: center;
    background: #e6d6f9;
    flex-direction: row;
  }
  .home-tab-inspection-clicked {
    width: 100%;
    min-height: 50px;

    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #6a549e;
    flex-direction: row;
  }
  .question-tab-inspection {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    .bull-num-q {
      padding: 8px;
      width: 30px;
      color: #fff;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      background-color: #569d59;
    }
    .number-container-large {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #569d59;
      border-radius: 500px;
      padding: 5px;

      .num-bull {
        height: 100%;
        width: 50%;
        min-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .number-container {
      display: flex;
      height: 100%;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      .number-container-txt {
        width: 25%;
        height: 90%;
        display: flex;
        justify-content: center;
        border-radius: 500px;
        align-items: center;
        background-color: #569d59;
      }

      .num-bull {
        height: 100%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .question-tab-inspection-open {
    width: 100%;
    min-height: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    .bull-num-q {
      padding: 8px;
      width: 30px;
      color: #fff;

      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      background-color: #569d59;
    }
    .number-container-large {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #569d59;
      border-radius: 500px;
      padding: 5px;

      .num-bull {
        height: 100%;
        width: 50%;
        min-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .number-container {
      display: flex;
      height: 100%;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      .number-container-txt {
        width: 25%;
        height: 90%;
        display: flex;
        justify-content: center;
        border-radius: 500px;
        align-items: center;
        background-color: #569d59;
      }

      .num-bull {
        height: 100%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.inspection-content-left-close {
  width: 20%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .home-tab-inspection {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #e6d6f9;
  }
  .home-tab-inspection-clicked {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6a549e;
    flex-direction: row;
  }
  .question-tab-inspection {
    width: 100%;
    min-height: 50px;
    padding: 5px;

    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    .bull-num-q {
      width: 20px;
      height: 20px;
      color: #fff;

      border-radius: 500px;
      background-color: #569d59;
      justify-content: center;
      display: flex;
    }

    .number-container-large {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      align-content: center;
      background-color: #569d59;
      border-radius: 500px;
      width: 90%;
      .num-bull {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: burlywood;
      }
    }
    .number-container {
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #569d59;
      border-radius: 500px;

      .num-bull {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: burlywood;
      }
    }
  }
  .question-tab-inspection-open {
    width: 100%;
    min-height: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    .bull-num-q {
      padding: 8px;
      width: 30px;
      color: #fff;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      background-color: #569d59;
    }
    .number-container-large {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #569d59;
      border-radius: 500px;
      padding: 5px;

      .num-bull {
        height: 100%;
        width: 50%;
        min-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .number-container {
      display: flex;
      height: 100%;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      .number-container-txt {
        width: 25%;
        height: 90%;
        display: flex;
        justify-content: center;
        border-radius: 500px;
        align-items: center;
        background-color: #569d59;
      }

      .num-bull {
        height: 100%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
