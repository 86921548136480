.new-Lookup-element-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem 0.813rem 1rem;
  color: #39354a;
  border-bottom: 1px solid #eeeeee;
  .new-Lookup-element {
    display: flex;
    gap: 0.625rem;
    .new-Lookup-element-title {
      font-family: Hanken Grotesk;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.24px;
    }
  }
  .add-lookup-element {
    background-color: transparent;
    border: none;
  }
}

.new-Lookup-element-container.disable {
  opacity: 0.2;
}
