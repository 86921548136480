.CodeMirror-merge-copy {
  position: absolute;
  cursor: pointer;
  color: transparent;

  display: block;
  width: 14px;
  height: 14px;
  background-image: url("../../../../../assets/icons/Arrow_to_left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.diffContainer {
  display: flex;
  flex-direction: column;
  width: 98.4vw;
  height: calc(100vh - 7rem);
}
.CodeMirror-merge {
  position: relative;
  /* border: 1px solid #ddd; */
  white-space: pre;
}
.CodeMirror-merge,
.CodeMirror-merge .CodeMirror {
  border-color: white;
}
.CodeMirror-merge-copy-reverse {
  position: absolute;
  cursor: pointer;
  color: transparent;

  display: block;
  width: 14px;
  height: 14px;
  background-image: url("../../../../../assets/icons/Arrow_to_right.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.CodeMirror {
  background-color: #1f1f1f; /* Dark background */
  color: #133f91; /* Light text */
  height: 100%;
}
.CodeMirror.cm-s-vscode-light {
  border-radius: 0px 0px 3px 3px;
}
.CodeMirror.cm-s-vscode-dark {
  border-radius: 0px 0px 3px 3px;
}

.CodeMirror-gutter,
.CodeMirror-merge-gutter {
  background-color: #1f1f1f;
  /* Matching gutter background */
}

.CodeMirror-merge-scrolllock.CodeMirror-merge-scrolllock-enabled:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../../../../assets/icons/scroll-Locked.svg");
  background-size: contain;
  background-repeat: no-repeat; /* Unicode for leftwards arrow and rightwards arrow */
}
.CodeMirror-merge-scrolllock:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../../../../assets/icons/scroll-Unlocked.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.CodeMirror-merge-2pane .CodeMirror-merge-pane {
  width: calc((100% - 50px) / 2);
}
.CodeMirror-merge-2pane .CodeMirror-merge-gap {
  width: 50px;
}
.cm-s-vscode-light .CodeMirror-merge-spacer {
  background-color: #ffffff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAChJREFUGFdjZEADZ86c+c+ILAYSMDExYYQLwgRAisCCyAJgQXQBkCAA3KgTafHBEkYAAAAASUVORK5CYII=);
}
.cm-s-vscode-dark .CodeMirror-merge-spacer {
  background-color: #1e1e1e;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAChJREFUGFdjZEADDx8+NGZEFgMJyMvLn4ULwgRAisCCyAJgQXQBkCAApYUS3B4aqCYAAAAASUVORK5CYII=);
}
.cm-s-vscode-hc-black .CodeMirror-merge-spacer {
  background: repeating-linear-gradient(
    47deg,
    #e3e9f94d,
    #1d1c1c 1px,
    #1e1e1e 4px
  );
}
.CodeMirror-merge,
.CodeMirror-merge .CodeMirror {
  height: 350px;
}
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: #ffffff00;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  height: 8px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-merge-gap {
  z-index: 2;
  display: inline-block;
  height: calc(100vh - 13rem);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  border-left: 0px solid #ec5c55;
  border-right: 0px solid #36a5a5;
  position: relative;
  background: #fbfbfb;
  margin-top: -2px;
}
.CodeMirror-merge .CodeMirror-merge-2pane {
  height: 519px;
}
.CodeMirror-merge-pane {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  background-color: #42272c;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  width: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-merge-r-chunk-start {
  border-top: 0px solid #dbd7ed;
}
.CodeMirror-merge-r-chunk-end {
  border-bottom: 0px solid #dbd7ed;
}
.CodeMirror-merge-r-connect {
  fill: #dbd7ed;
  stroke: rgb(76, 54, 165);
  stroke-width: 1px;
}
.difContainer {
  height: calc(100vh - 50rem);
  width: 100%;
}
.CodeMirror-merge-pane
  .CodeMirror-merge-right
  .CodeMirror-merge-pane-rightmost
  .CodeMirror
  .cm-s-material-ocean {
  height: 100%;
}

.CodeMirror .cm-s-material-ocean {
  height: 100%;
}
.CodeMirror-merge-l-chunk-start {
  border-top: 0px solid #88e;
}
.CodeMirror-merge-l-chunk-end {
  border-bottom: 0px solid #88e;
}
.CodeMirror-merge-l-connect {
  fill: #eef;
  stroke: #88e;
  stroke-width: 1px;
}
.CodeMirror-merge-pane-rightmost .cm-s-vscode-dark .CodeMirror-merge-r-chunk {
  background: #033625;
}
.CodeMirror-merge-pane-rightmost
  .cm-s-vscode-hc-black
  .CodeMirror-merge-r-chunk {
  background: #033625;
}
.CodeMirror-merge-pane-rightmost .cm-s-vscode-light .CodeMirror-merge-r-chunk {
  background: #73c0c0;
}
.CodeMirror-merge-r-deleted,
.CodeMirror-merge-l-deleted {
  background-image: none;
  background-position: bottom left;
  background-repeat: repeat-x;
}
.CodeMirror-merge-collapsed-line .CodeMirror-gutter-elt {
  display: block;
}
.LeftContainer {
  width: calc((100% - 50px) / 2);
  margin-left: 1px;
}
.RightContainer {
  width: calc((100% - 50px) / 2);
  margin-right: 1px;
}
.CodeMirror-merge-pane {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  margin-top: -1px;
  border-top: 1px solid #eeeeee;
  border-right: 2px solid #ec5c55;
  border-left: 2px solid #ec5c55;
  border-bottom: 2px solid #ec5c55;
  border-radius: 0px 0px 6px 6px;
}
.CodeMirror-merge-pane-rightmost {
  position: absolute;
  right: 0px;
  margin-top: -2px;
  border-top: 1px solid #eeeeee;
  border-right: 2px solid #36a5a5;
  border-left: 2px solid #36a5a5;
  border-bottom: 2px solid #36a5a5;
  border-radius: 0px 0px 6px 6px;
}
.compareContainer {
  display: flex;
  width: 100%;
  height: 4rem;
  max-height: 4rem;
  justify-content: space-between;
  align-items: center;
}

.editorContainerDraft {
  border-top: solid 2px #4c36a5;
  border-right: solid 2px #4c36a5;
  border-left: solid 2px #4c36a5;
  border-radius: 6px 6px 0px 0px;
}
.editorHeaderDraft {
  background-color: #fbfbfb;
  padding: 1rem 15px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  border-radius: 3px 3px 0px 0px;
  align-items: center;
  height: 4rem;
}
.editorName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 1%;
}
.statutDraft {
  background-color: #4c36a5;
  color: white;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.editorPropretyDraft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.changesDraft {
  color: #4c36a5b3;
}
.iconEditor {
  display: flex;
  cursor: pointer;
}
.separatorCompareDraft {
  display: flex;
  width: 3px;
  min-width: 3px;
  border-radius: 50px;

  background-color: var(--separator);
  height: 24px;
  min-height: 24px;
}
.editorPropList {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
  width: 20px;
}
/* .editorPropList .moreOptions-btn {
  color: #255a19;
  border-radius: 0px;
  width: 20px;
}
.editorPropList .moreOptions-btn:hover {
  color: #ffffff;
  border-radius: 0px;
  width: 20px;
} */

.editorContainerLeft {
  border-top: solid 2px #ec5c55;
  border-right: solid 2px #ec5c55;
  border-left: solid 2px #ec5c55;
  border-radius: 6px 6px 0px 0px;
}
.editorHeaderLeft {
  display: flex;
  background-color: #fbfbfb;
  padding: 1rem 15px;
  width: 100%;
  justify-content: space-between;
  border-radius: 3px 3px 0px 0px;
  align-items: center;
  height: 4rem;
}
.editorName {
  display: flex;
  align-items: center;
  padding: 1%;
}
.statutLeft {
  background-color: #ec5c55;
  color: white;
  border-radius: 18px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: margin-right 0.2s;
}
.statutLeft .hoveredContext {
  display: none;
}
.statutLeft:hover .originalContext {
  display: none;
}
.iconClose {
  margin-right: -3.1px;
  margin-top: 1px;
}
.statutLeft:hover .hoveredContext {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 18px;
}

.editorProprety {
  display: flex;
  align-items: center;
  gap: 15px;
}
.editorPropretyIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.changesLeft {
  color: #ec5c55;
}
.iconEditor {
  display: flex;
  cursor: pointer;
}

.editorContainernewRight {
  border-top: solid 2px #36a5a5;
  border-right: solid 2px #36a5a5;
  border-left: solid 2px #36a5a5;
  border-radius: 6px 6px 0px 0px;
}
.editorHeaderRight {
  display: flex;
  background-color: #fbfbfb;
  padding: 1rem 15px;
  width: 100%;
  justify-content: space-between;
  border-radius: 3px 3px 0px 0px;
  align-items: center;
  height: 4rem;
}
.headerName {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1%;
}
.rightStatut {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #36a5a5;
  color: white;
  border-radius: 18px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rightStatut .hoveredContext {
  display: none;
}
.rightStatut:hover .originalContext {
  display: none;
}
.rightStatut:hover .hoveredContext {
  display: flex;
  cursor: pointer;
  border-radius: 18px;
  align-items: center;
}

.editorProprety {
  display: flex;
  align-items: center;
  gap: 15px;
}
.changesRight {
  color: #36a5a5b3;
}
.iconEditor {
  display: flex;
  cursor: pointer;
}

.editorValue {
  border: solid 1px #36a5a5;
  display: contents;
}
.CollapseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 4rem;
}
