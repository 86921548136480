.whereclause-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2px;
  padding: 2px;
  .whereclause-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(---primary);
    .whereclause-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 20px);
    }
    .whereclause-icon {
      display: flex;
      height: 100%;
      width: 20px;
      justify-content: center;
      align-items: center;
    }
  }
  .whereclause-desc {
    background-color: var(---primaryLight);
    font-size: small;
    line-height: 130%;
    padding: 2px;
    width: 100%;
  }
}
