.sidebar-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 0 0 36px 36px;

  .void {
    display: flex;
    width: 30%;
    max-width: 30%;
    min-width: 30%;
    height: 100%;
    background-color: #0000;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    max-width: 70%;
    min-width: 70%;
    height: 100%;
    background-color: var(---listBg);
    padding: 4% 2%;
    border-bottom-right-radius: 36px;

    .whereclauses {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      gap: 8px;
      padding-bottom: 10px;
      .whereclause-container {
        display: flex;
        width: 100%;
        &:hover {
          background-color: #e2e2e240;
        }
      }
    }
    .sideBar-maximomedia {
      height: 100%;
    }
    .whereclauses::-webkit-scrollbar {
      display: none;
    }
    .views {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      min-height: 17%;
      height: 17%;
      border-top: 3px solid var(---primary);
      padding-top: 3%;
      padding-bottom: 3%;
      // background-color: red;

      .ListView,
      .CalendarView,
      .MapView {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;

        align-items: center;
        width: 100%;
        padding-left: 3%;
        padding-right: 3%;
        justify-content: flex-start;
        min-height: calc((100vh - 10.5rem) * 0.9 * 0.817 * 0.055);
        max-height: calc((100vh - 10.5rem) * 0.9 * 0.817 * 0.055);

        .iconView {
          display: flex;
          padding-right: 8%;
          justify-content: center;
          align-items: center;
        }
        .viewTitle {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .inactive {
        background-color: var(---listBg);
        color: var(---primary);
      }
      .notfound {
        color: var(---separatorColor);
      }
      .active {
        background-color: var(---primaryLight);
        color: var(---primary);
      }
    }
  }
}
