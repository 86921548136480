.input-custom-container {
  min-height: 45px;
  width: 100%;
  border-radius: 9px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  // border: 1px solid red;
  width: 100%;
  // border: 1px solid #eee;
  // background: rgba(255, 255, 255, 0.9);
  background-color: var(---bgLoginInput);

  .input-field-rtl {
    direction: rtl;
    text-align: right;
    width: 100%;
    padding-right: 5px;
    background-color: var(---bgLoginInput);
  }

  .input-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    .input-custom-style {
      height: 100%;
      width: 100%;
      border-width: 0px;
      border-radius: 9px;
      outline: 0px;
      background-color: var(---bgLoginInput);
      color: var(---loginText);
      cursor: url(../../../../../../../ComponentsLibrary/Assets/Input.svg), auto;
      .input-custom-style ::placeholder {
        color: #e2e2e2;
      }
    }
  }
}
