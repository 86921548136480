.mainContainer-icon {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: var(--header);

  .upload-mainContainer {
    display: flex;
    width: 601px;
    height: 334px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-image: repeating-linear-gradient(
        0deg,
        var(--BorderDashed),
        var(--BorderDashed) 10px,
        transparent 10px,
        transparent 18px,
        var(--BorderDashed) 18px
      ),
      repeating-linear-gradient(
        90deg,
        var(--BorderDashed),
        var(--BorderDashed) 10px,
        transparent 10px,
        transparent 18px,
        var(--BorderDashed) 18px
      ),
      repeating-linear-gradient(
        180deg,
        var(--BorderDashed),
        var(--BorderDashed) 10px,
        transparent 10px,
        transparent 18px,
        var(--BorderDashed) 18px
      ),
      repeating-linear-gradient(
        270deg,
        var(--BorderDashed),
        var(--BorderDashed) 10px,
        transparent 10px,
        transparent 18px,
        var(--BorderDashed) 18px
      );
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;

    #vector {
      width: 85px;
      height: 85px;
      flex-shrink: 0;
    }
    p {
      font-family: "Hanken Grotesk", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--no-color, #b9b8bd);
      text-align: center;
      margin: 0;
    }
    #text-L {
      font-size: 24px;
      font-weight: normal;
    }

    .upload-instructions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;

      #text-M {
        font-size: 16px;
        font-weight: normal;
      }

      .uploadButton {
        width: 194px;
        display: flex;
        padding: 13px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        background: var(--SupportButtonBg);
        font-family: "Hanken Grotesk", sans-serif;
        color: #eee;
        // border-color: #4c36a5;
        appearance: none;
        border: none;
        outline: none;
        margin: 3px 0px 3px 0px;
      }

      #text-S {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
