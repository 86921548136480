.ModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  width: calc((100vh - 10.5rem) * 0.47 * 0.905) !important;
  min-width: calc((100vh - 10.5rem) * 0.47 * 0.905);
  max-width: calc((100vh - 10.5rem) * 0.47 * 0.905);
  height: calc((100vh - 12.5rem) * 0.94);
  max-height: calc((100vh - 12.5rem) * 0.94);
  min-height: calc((100vh - 12.5rem) * 0.94);
  border-radius: 0 0 36px 36px;
  background-color: var(---primaryLight);
}
.Model-mainContainer {
  background: var(---primaryLight);
  display: flex;
  position: absolute;
  width: 100%;
  height: calc((100vh - 12.5rem) * 0.94) !important;
  max-height: calc((100vh - 12.5rem) * 0.94) !important;
  min-height: calc((100vh - 12.5rem) * 0.94) !important;
  top: 0;
  z-index: 999;
  .ModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    width: calc((100vh - 10.5rem) * 0.47 * 0.905) !important;
    min-width: calc((100vh - 10.5rem) * 0.47 * 0.905);
    max-width: calc((100vh - 10.5rem) * 0.47 * 0.905);
    height: 95%;
    max-height: 100%;

    border-radius: 0 0 36px 36px;
    background-color: transparent;
  }
}

.ModalContainerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: calc((100vh - 10.5rem) * 0.47 * 0.902) !important;
  min-width: calc((100vh - 10.5rem) * 0.47 * 0.902);
  max-width: calc((100vh - 10.5rem) * 0.47 * 0.902);
  height: calc((103vh - 12.5rem) * 0.94) !important;
  max-height: calc((103vh - 12.5rem) * 0.94) !important;
  min-height: calc((103vh - 12.5rem) * 0.94) !important;
  z-index: 999;
  width: calc((103.5vh - 10.5rem) * 0.47 * 0.902) !important;
  min-width: calc((103.5vh - 10.5rem) * 0.47 * 0.902);
  max-width: calc((103.5vh - 10.5rem) * 0.47 * 0.902);
  height: calc((105vh - 12.5rem) * 0.94) !important;
  max-height: calc((105vh - 12.5rem) * 0.94) !important;
  min-height: calc((105vh - 12.5rem) * 0.94) !important;
  background-color: aliceblue;
  border-radius: 0px 0px 50px 50px;
}
