.phone-container {
  display: flex;
  border-width: 3px;
  border-color: #bab1e8;
  border-style: solid;
  background: linear-gradient(
    70.38deg,
    rgba(99, 99, 99, 0) 58.55%,
    hsl(0, 0%, 0%) 95.37%
  );
  border-radius: 42px;
  .phone-frame {
    display: flex;
    flex-direction: column;
    width: calc((100vh - 10.5rem) * 0.438) !important;
    min-width: calc((100vh - 10.5rem) * 0.438);
    max-width: calc((100vh - 10.5rem) * 0.438);
    height: calc(100vh - 12.5rem) !important;
    max-height: calc(100vh - 12.5rem) !important;
    min-height: calc(100vh - 12.5rem) !important;
    border-radius: 40px;
    box-shadow: 0 0 25px rgba(72, 72, 72, 0.3);
    overflow: hidden;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transform-style: preserve-3d;
    perspective: 1000px;
    background-color: #1b1b1b;
    padding: 1.5% 1.4%;
    .device-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: 100%;
      max-height: 100%;
      min-height: 100%;
      background-color: var(---primary);
      border-radius: 36px;
      .frame-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 4.6%;
        max-height: 4.6%;
        min-height: 4.6%;
        padding: 1.2% 0;
        border-radius: 36px 36px 0 0;
        .header-splite {
          display: flex;
          width: 28%;
          min-width: 28%;
          max-width: 28%;
          height: 100%;
          justify-content: flex-end;
          align-items: center;
          background-color: #1b1b1b;
          border-radius: 15px;
          .frame-camera {
            display: flex;
            height: 55%;
            width: 14%;
            background-color: #474747;
            border-radius: 50%;
            margin-right: 5%;
            border: 1px solid #717171;
          }
        }
        .frame-time {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 36%;
          max-width: 36%;
          min-width: 36%;
          font-weight: 800;
          font-size: smaller;
          color: white;
        }
        .frame-network {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 7%;
          height: 100%;
          width: 36%;
          max-width: 36%;
          min-width: 36%;
          color: white;
          .btnRuby {
            background-color: var(---loader);
          }
        }
      }
      .phone-frame-body {
        display: flex;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 95.4%;
        min-height: 95.4%;
        max-height: 95.4%;
        border-radius: 0 0 36px 36px;
        overflow: hidden;
      }
      .home-button {
        display: flex;
        position: absolute;
        height: 0.6%;
        width: 40%;
        left: 30%;
        bottom: 1.5%;
        z-index: 999;
        background-color: #a1a1a1;
        border-radius: 20px;
      }
      .empty-device {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
