.map-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2%;

  &:hover {
    background-color: #3663d633;
  }
}
