.map {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
  width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
  min-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
  max-width: calc((100vh - 10.5rem) * 0.47 * 0.9) !important;
  height: calc((100vh - 12.5rem) * 0.865) !important;
  max-height: calc((100vh - 12.5rem) * 0.865) !important;
  min-height: calc((100vh - 12.5rem) * 0.865) !important;
  border-radius: 36px;
}

.map-in-form {
  height: 200px;
  width: 100%;
}
.onlymap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc((100vh - 10.5rem) * 0.47 * 0.902);
  height: 250px;
}

.list-extended-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: center;
  text-align: center;
  z-index: 2;
  top: calc((47.5vh - 10.5rem) * 0.9 * 0.82) !important;
  right: calc((34vh - 10.5rem) * 0.9 * 0.47 * 0.9);
}

.btn-horizontale-list-content {
  height: 26.5px;
  width: 26.5px;
  border-radius: 500px;
  margin: 0px 3px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  margin-bottom: 3px;
}

.header-with-pullup {
  height: 40px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
  top: 0px;
  z-index: 9;
}
