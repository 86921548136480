.no-data-container {
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  margin-top: 1.875rem;
  align-items: center;
  .text-container {
    font-family: Hanken Grotesk;
    font-size: 24px;
    font-weight: 500;
    line-height: 31.27px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--no-color, #b9b8bd);
  }
}
