.sidebar-details {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 17.5rem;
    align-self: stretch;
    border-radius: 2px !important;
    border: 3px solid var(--SupportButtonBg);
    .iconPreview {
      display: flex;
      width: 100%;
      height: 14.5rem;
      align-items: center;
      justify-content: center;
      background-image: repeating-linear-gradient(
          45deg,
          var(--Condition) 25%,
          transparent 25%,
          transparent 75%,
          var(--Condition) 75%,
          var(--Condition)
        ),
        repeating-linear-gradient(
          45deg,
          var(--Condition) 25%,
          var(--ConditionUI) 25%,
          var(--ConditionUI) 75%,
          var(--Condition) 75%,
          var(--Condition)
        );
      background-position: 0 0, 8px 8px;
      background-size: 16px 16px;
      justify-content: center;
      img {
        width: 85%;
        max-height: 85%;
        object-fit: contain;
      }
    }
    .namePreview {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      background: #4c36a5;
      width: 100%;

      .NameInput {
        padding: 10px 10px;
        padding-right: 35px;
        border-bottom: none;
        border-left: none;
        border-right: none;
        border-top: 3px solid #4c36a5;
        width: 100%;
        height: 3rem;
        background-color: var(--CardBg);
        color: var(--text);
      }
    }
    .IconNameBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 3rem;
      background: var(--SupportButtonBg);
      padding: 5px 10px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;

      .renameIcon {
        color: #fff;
      }
      .Copyicon {
        color: #fff;
      }
    }

    .IconNameBar p {
      color: #fff;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4%;
      color: #ddd;
      margin-left: 4%;
      cursor: pointer;

      .custom-tooltip {
        & .MuiTooltip-tooltip {
          background-color: #16a34ae6;
          color: white;
          font-size: 16px;
          border-radius: 0%;
        }
      }
    }

    .input-container {
      position: relative;
      width: 100%;
    }

    .input-container input {
      padding-right: 20%;
    }

    .closeButton {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      color: #c4c3c9;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .icons-dtl {
    background-color: var(--CardBg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    // height: calc(100vh - 27rem);
    border-radius: 2px;
    border: 1px solid var(--BorderStroke);
    font-family: "Hanken Grotesk", sans-serif;
    .dtl-headline {
      display: flex;
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0.8rem 1rem 0rem 1rem;

      color: var(--TitleContainer);
      font-size: 16px;
      font-weight: 500;
    }
    .dtl-list {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      height: calc(100vh - 33.5rem);
      overflow-y: auto;
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0%;

      .dtl-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        height: 2.8rem;
        color: var(--info_title);
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid var(--BorderStroke);

        #dtl-item-value {
          color: var(--TitleContainer);
          align-self: center;
        }
      }
    }
  }
}
