.input-group {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
  width: 100%;
  .auth-input {
    width: 100%;
    background-color: transparent;
    .MuiOutlinedInput-root {
      border-radius: 2px;
      font-size: 14px;
      // min-height: 40px;
      color: var(--text);
      border-color: var(--inputBorder);
    }
    .MuiInputBase-root {
      border-radius: 2px;
      font-size: 14px;
      // min-height: 40px;
      color: var(--text);
      border-color: var(--inputBorder);
    }
    .MuiOutlinedInput-root {
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--inputBorder);
      }
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--inputBorder);
      border-width: 2px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(
        --InputStroke
      ); // Border color when not focused or active
    }
    .MuiIconButton-root {
      color: var(--inputBorder);
    }

    .MuiInputLabel-root {
      display: flex;
      color: #aaa !important;
      font-family: "Hanken Grotesk", sans-serif;
      align-items: center;
      align-self: center;
      justify-content: center;
      &.Mui-focused {
        color: var(--inputBorder) !important;
        font-family: "Hanken Grotesk", sans-serif;
      }
    }

    .MuiOutlinedInput-input {
      &::placeholder {
        color: #aaa !important;
        font-family: "Hanken Grotesk", sans-serif;
      }
    }
  }
  .errorMessage {
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 12px;
    color: #df4759;
  }
}
