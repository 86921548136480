$shadowOffsetWidth: 0px;
$shadowOffsetHeight: 2px;
$shadowOpacity: 0.25px;
$shadowRadius: 3.84px;

.list-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 36px 36px;
  padding: 0 2px;
  gap: 6px;

  .list-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .list-no-body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: white;
    width: calc(100% - 12px);
    border-radius: 5px;
    color: #00000080;
    font-weight: 500;

    border: 1px solid #aaaaaa;
  }
  .detail-style-container {
    min-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    .detail-list-container {
      min-height: 100%;
      height: 100%;

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 7px;
    }
    .detail-container {
      min-height: 100%;
      width: 100%;
      display: flex;
      height: auto;

      align-items: center;
      justify-content: center;
      padding: 7px;
    }
  }
}
.list-display-cards {
  gap: 10px;
  padding: 0 8px;
}
.list-wrapper-hasImage {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 4px 10px;
  gap: 12px;
  .card {
    width: calc(50% - 6px);
    max-width: calc(50% - 6px);
    min-width: calc(50% - 6px);
    min-height: calc((100vh - 10.5rem) * 0.9 * 0.185);
    border: 0px solid #ccc;
    border-radius: 5px;
    box-shadow: $shadowOffsetWidth $shadowOffsetHeight $shadowRadius
      rgba(0, 0, 0, 0.1);
    //   $shadowColor;
    opacity: $shadowOpacity;
  }
}
.list-wrapper.gridLayout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (height < 700px) {
  .list-wrapper.gridLayout {
    display: flex !important; /* Override the grid display */
    grid-template-columns: none !important; /* Override the repetition */
  }
}
.list-wrapper::-webkit-scrollbar {
  display: none;
}
