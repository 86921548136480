.LoaderConatiner {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .installationHeader {
    .installationHeader-title {
      max-width: 85%;
      h1 {
        color: var(--TitleContainer);
        font-family: "Hanken Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .installationFooter {
    display: flex;
    text-align: right;
    align-items: center;
    border-top: 1px solid #eee;
    justify-content: end;
    padding-top: 2%;
    .installation-next-button {
      margin-left: 2%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--SupportButtonBg);
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 16px;
      height: 44px;
    }
    .grey-out {
      background-color: var(--NextButtonBg);
      color: var(--NextButtonColor);
      border: 1px solid var(--BorderStroke);

      cursor: not-allowed;
    }
  }
}
