.mediaBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc((100vh - 10.5rem) * 0.045);
  min-height: calc((100vh - 10.5rem) * 0.045);
  max-height: calc((100vh - 10.5rem) * 0.045);
  width: calc((100vh - 10.5rem) * 0.045);
  min-width: calc((100vh - 10.5rem) * 0.045);
  max-width: calc((100vh - 10.5rem) * 0.045);
  margin-left: calc((100vh - 10.5rem) * 0.006);
  margin-right: calc((100vh - 10.5rem) * 0.006);
  border-radius: 50%;
  background-color: var(---listBg);
  color: var(---primary);
}
