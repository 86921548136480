.debugger-container{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .search-table {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 50px;
      min-height: 50px;
        width: 100%;
        // padding: 17px;
        display: flex;
        background: var(--MonitorSearchBgColor);
        border-bottom: 1px solid var(--StatusBarBorder);
      }
      
        .dashboard-search-input {
          height: 30px;
          max-height: 30px;
            width: 100%;
            border: none;
            justify-self: center;
            background: var(--MonitorSearchBgColor);
            color: var(--MonitorSearchColor);
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
         
}