.setupVerificationContainer {
  display: flex;
  background-color: var(--header);
  min-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .VerificationBody {
    display: flex;
    min-width: 100%;
    flex-direction: column;
    align-items: center;
    .VerificationTitle {
      color: var(--TitleContainer);
      font-family: "Hanken Grotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 10%;
    }
  }
  .VerificationFooter {
    background-color: blue;
  }
  .Loader {
    color: var(--SupportButtonBg);
  }
}
