.high {
  width: 100vw;
  height: calc(100vh - 3rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-size: cover;
  color: white;
  padding: 5rem;
  overflow: auto;
  .leftSide {
    width: 25%;
  }

  .RightSide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .Button {
      display: flex;
      align-self: flex-end;
      width: 100%;

      .HomeButton1 {
        display: flex;
        height: 3.75rem;
        padding: 0.625rem 1rem;
        justify-content: center;
        width: 26.875rem;
        align-self: center;
        align-items: center;
        gap: 0.625rem;
        background-color: var(--CardBg);
        color: var(--ColorButton);
        border: none;
        border-radius: 0.125rem;
        cursor: pointer;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:hover {
          color: #7d6dbe;
        }

        .Fleche {
          height: calc(100vh * 0.03);
          flex-shrink: 0;
        }
      }
      .iconButton {
        background-color: var(--CardBg);
        margin-right: 3%;
        height: 3.75rem;
        border-radius: 0.125rem;
        display: flex;
        padding: 0.375rem 0.25rem;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
      }
    }
  }

  .CentreSide {
    display: flex;
    justify-content: center;
    width: 50%;
    // height: calc(100vh - 10rem);
    // height: 100%;
    .Card {
      display: flex;
      width: 70%;
      height: 100%;
      flex-direction: column;
      padding: 1.5625rem;
      background-color: var(--CardBg);
      color: var(--Title);
      border: 1px solid var(--BorderColor);
      justify-content: center;
      align-self: center;
      gap: 1.5rem;
      border-radius: 0.125rem;
      box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
        0px 4px 6px -1px rgba(0, 0, 0, 0.1);

      .submitButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 500;
        background-color: var(--LoginButton);
        color: #ffff;
        cursor: pointer;
        width: 100%;
        height: 40px;
        &:active {
          background-color: #7d6dbe;
        }
      }

      .title {
        color: var(--Title);
        text-align: center;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0%;
        margin-top: 0%;
      }
      .subtitle {
        color: #aaa;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-top: 0%;
      }
    }
  }
}

.login-error {
  font-weight: 500;
  color: #df4759;
  font-size: 18px;
}

.invalid-feedback {
  color: #df4759;
}

.loading-page {
  margin-top: 18%;
  .close-alert-error {
    display: flex;
    justify-content: center;
    .close-btn {
      background-color: rgba(235, 60, 60, 0.7);
      outline: none;
      padding: 5px 45px 5px 45px;
      border: none;
      border-radius: 50px;
      color: #fff;
    }
  }
}
