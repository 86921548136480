.floating-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  width: auto;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  // border-radius: 50px;
  .floatingBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    border-radius: 50%;
  }
  .floatingBtn-open {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    width: auto;
    background-color: #4c36a5;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    cursor: url(../ComponentsLibrary/Assets/Pointer.svg), auto;

    .floatingBtn-label {
      display: flex;
      padding-right: 15px;
      align-items: center;
      height: 100%;
      width: auto;
      color: white;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      background-color: #4c36a5;
      cursor: url(../ComponentsLibrary/Assets/Pointer.svg), auto;
    }
    .floatingBtn-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      min-width: 45px;
      max-width: 45px;
      height: 45px;
      min-height: 45px;
      max-height: 45px;
      width: auto;
      background-color: #4c36a5;
      cursor: pointer;
      // border-radius: 50px;
    }
    .floatingBtn-icon:hover {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .close {
    border-radius: 50%;
    background-color: #4c36a5;
  }
}
