.input-container {
  width: 100%;
  position: relative;
  .label-hidden {
    visibility: hidden;
  }

  .label-visible {
    visibility: visible;
  }
}

.input-container input {
  border-radius: 2px;
  border: 1px solid var(--emailInputBorder);
  width: 100%;
  height: 2.875rem;
  font-family: 'Hanken Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.938rem 0.625rem;
  background: none;
  color: var(--Globe);
  &:focus {
    border: 1px solid var(--inputBorder);
    outline: none;
    background: none;
  }
}

.input-container input::placeholder {
  color: var(--emailPlaceholderColor);
}

.input-container label {
  color: var(--TitleContainer);
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-left: 2px;
  border-right: 6px;
  margin-left: 0.5rem;
  position: absolute;
  top: -7px;
  background: var(--emailFormBackground);
  padding-left: 3px;
  padding-right: 7px;
}
