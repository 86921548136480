.section-container {
  display: flex;
  height: auto;
  width: 100%;
  gap: 10px;
  padding: 0 2px;
  min-height: 60px;
  .drop-section-container {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    gap: 5px;
    padding: 7px 5px;
    justify-content: center;
  }
  .section-empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
  }
}
