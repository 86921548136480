.modal-lang-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  .close-modal-lang {
    display: flex;
    width: 100%;
    height: 60%;
    justify-content: center;
    align-items: flex-end;
  }
  .modal-lang-content {
    display: flex;
    flex-direction: column;
    width: 99.8%;
    height: 40%;
    max-height: 40%;
    min-height: 40%;
    background-color: white;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 5px 0px 5px;
    .lang-txt-container {
      min-height: 49px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 5px 0px 5px;
      align-items: center;
      border-width: 0px 0px 1px 0px;
      border-color: #e1d3f3;
      border-style: solid;

      .lang-txt {
        font-size: 16px;
        font-weight: 600;
      }
      .abrev-txt {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .lang-txt-container-selected {
      min-height: 49px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(---primaryLight);
      padding: 0px 5px 0px 5px;
      align-items: center;
      .lang-txt {
        font-size: 16px;
        font-weight: 600;
      }
      .abrev-txt {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
