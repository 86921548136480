.conditionsUI-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--Header);
  .conBody {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
    grid-gap: 0.5rem;
    padding: 0.75rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .card {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 9.5rem;
      max-height: 9.5rem;
      min-height: 9.5rem;
      background-color: var(--Header);
      color: var(--text);
      border: 1px solid var(--BorderStroke);
      border-radius: 2px;
      .cardColor {
        width: 100%;
        height: 40%;
        min-height: 46%;
        max-height: 46%;
      }
      .gridBg {
        background-size: 12px 12px;
        background-image: linear-gradient(
            45deg,
            var(--Condition) 25%,
            transparent 25%,
            transparent 75%,
            var(--Condition) 75%,
            var(--Condition)
          ),
          linear-gradient(
            45deg,
            var(--Condition) 25%,
            var(--ConditionUI) 25%,
            var(--ConditionUI) 75%,
            var(--Condition) 75%,
            var(--Condition)
          );
        background-position: 0 0, 6px 6px;
      }

      .cardRef {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.2rem 0.4rem;
        height: 60%;
        min-height: 54%;
        max-height: 54%;
        .refCard {
          font-size: 14px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .valuesProp {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 10px;
          color: var(--Header);
          line-height: 12px;
        }
      }
    }
    .selected {
      border: 1.5px solid #4c36a5;
    }
    .New {
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 0px;
      background-color: transparent;
      filter: drop-shadow(-1px 1px 2px rgba(22, 22, 22, 0.1))
        drop-shadow(-3px 4px 4px rgba(22, 22, 22, 0.09))
        drop-shadow(-6px 8px 6px rgba(22, 22, 22, 0.05))
        drop-shadow(-10px 14px 7px rgba(22, 22, 22, 0.01))
        drop-shadow(-16px 22px 8px rgba(22, 22, 22, 0));
    }
  }
  .noItem {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
