.advanced-search-monitor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .advanced-search-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 387px;
    max-width: 387px;
    padding: 27px 16px;
    font-family: Hanken Grotesk;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.24px;
    text-align: left;
    color: #39354a;
    flex-grow: 1;
    overflow-y: auto;
    height: 600px;

    .advanced-search-title {
      font-weight: 500;
    }
    .advanced-search-button-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .advanced-search-button {
        background-color: #e2d8fa;
        font-weight: 400;
        padding: 3px 10px 3px 10px;
        border-radius: 66px;
      }

      .advanced-search-button.inactive {
        opacity: 0.2;
      }
    }
    .advanced-search-options {
      display: flex;
      flex-direction: column;
      gap: 20px;
      // overflow-y: auto;
      height: calc(100vh - 22.95rem);
    }
  }
  .buttons-search-container {
    display: flex;
    gap: 10px;
    position: absolute;
    padding: 1rem;
    background-color: #f5f5f5;
    width: 100%;
    justify-content: flex-end;
    bottom: 6px;
    .reset-search-button,
    .submit-search-button {
      border-radius: 0px;
      color: rgb(76, 54, 165);
      background-color: rgb(255, 255, 255);
      padding: 0.7rem 1.25rem;
      gap: 0rem;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(238, 238, 238);
      font-size: 16px;
      margin-left: 0rem;
      margin-right: 0rem;
      cursor: pointer;
      line-height: 20.85px;
      align-self: center;
    }

    .submit-search-button {
      color: rgb(255, 255, 255);
      background-color: rgb(76, 54, 165);
    }

    .reset-search-button:hover,
    .submit-search-button:hover {
      background-color: #7d6dbe;
      color: white;
    }

    .reset-search-button:active,
    .submit-search-button:active {
      background-color: #7d6dbe;
      color: white;
    }
  }
}
