.pullup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  overflow-y: auto;
  .horizontale-line {
    width: 20%;
    height: 3px;
    background-color: var(---primary);
    margin-top: 10px;
  }
}
