.input-group-logger {
  display: flex;
  justify-content: space-between;

  .input-label {
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    align-self: center;
  }

  .input-field {
    width: 12.5rem;
    padding: 0.594rem 0.625rem;
    border-radius: 2px;
    border: 1px solid var(--monitorBorder);
    gap: 10px;
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    background: none;
    color: var(--monitorText);
  }

  .input-field:focus-within {
    border: 1px solid var(--ButtonSelected);
  }
}
