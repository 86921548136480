.ConfirmDialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
  .confirm-dialog {
    z-index: 1000000000000111;
    padding: 30px;
    background-color: white;
    width: 400px;
    height: 190px;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    .confirm-dialog-header {
      .confirm-message {
        font-weight: 500;
      }
      .item-name {
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    .confirm-dialog-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      .cancel-btn {
        background-color: rgb(219, 219, 219);
        outline: none;
        padding: 5px 45px 5px 45px;
        border: none;
        margin: 0 5px;
        border-radius: 50px;
      }
      .confirm-btn {
        background-color: rgba(209, 14, 14, 0.7);
        outline: none;
        padding: 5px 45px 5px 45px;
        border: none;
        color: #fff;
        border-radius: 50px;
        margin: 0 10px;
      }
    }
  }
}
