.iconcard {
  display: flex;
  flex-direction: column;
  width: calc(((100vh - 10.5rem) * 0.47 * 0.204) - 12px);
  height: calc(((100vh - 10.5rem) * 0.47 * 0.204) - 12px);
  max-height: calc(((100vh - 10.5rem) * 0.47 * 0.204) - 12px);
  min-height: calc(((100vh - 10.5rem) * 0.47 * 0.204) - 12px);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 6px;
  padding: 2px;
  .iconcard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 12px);
    max-height: calc(100% - 12px);
    padding: 1px;
    .iconcard-icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 50%;
    }
    .iconcard-value {
      display: flex;
      height: 25%;
      font-size: 9px;
      font-weight: 900;
      color: var(---primary);
    }
    .iconcard-label {
      height: 25%;
      font-size: 9px;
      max-width: calc(100% - 12px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: var(---textColor);
    }
  }
}
.iconcard-large {
  width: calc(((100vh - 10.5rem) * 0.47 * 0.425) - 12px);
  height: calc(((100vh - 12.5rem) * 0.47 * 0.425) - 12px);
  min-height: calc(((100vh - 12.5rem) * 0.47 * 0.425) - 12px);
  max-height: calc(((100vh - 12.5rem) * 0.47 * 0.425) - 12px);
  .iconcard-container {
    .iconcard-value {
      font-size: 14px;
    }
    .iconcard-label {
      font-size: 14px;
    }
  }
}
