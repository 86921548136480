.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3.375rem;
  max-height: 3.375rem;
  min-height: 3.375rem;
  position: static;
  background-color: var(--header);
  border-bottom: 1px solid var(--BorderStroke);
  box-shadow: none;
  padding: 0 1rem;
  .navbar-left {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--header);
    gap: 1rem;
    .logo-plateform {
      cursor: pointer;
    }
    .page-name {
      color: var(--logo);
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .navbar-middle {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 40%;
    align-items: center;
    justify-content: center;
    background-color: var(--header);
    gap: 0.5rem;
  }
  .navbar-right {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--header);
    gap: 1.2rem;

    .settings-btn {
      display: flex;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .settings-dropdown {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 3.2rem;
      background-color: var(--header);
      border: 1px solid #dddddd;
      border-radius: 2px;
      z-index: 9999;
      .settings-item {
        display: flex;
        flex-direction: row;
        height: 100%;
        color: var(--text);
        justify-content: space-between;
        align-items: center;
        min-width: 190px;
        height: 45px;
        padding: 12px 16px;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
        .TransactionServer {
          display: flex;
          flex-direction: initial;
          gap: 11px;
          align-items: center;
        }
        &:hover {
          background-color: var(--primary);
          color: white;
        }
      }
      .enableMaintenance {
        background-color: var(--primary);
        color: white;
      }
      .disableMaintenance {
        background-color: var(--header);
        color: var(--text);
      }
    }
  }
}

.discardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .discardMsg {
    font-size: 16px;
    font-weight: 400;
  }
  .discardFileName {
    font-size: 16px;
    font-weight: 500;
  }
}

.HomeLogo {
  cursor: pointer;
}
