.swipe-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 36px 36px;
  gap: 2%;

  .close-button {
    display: flex;
    width: calc((100vh - 10.5rem) * 0.47 * 0.94 * 0.1);
    height: calc((100vh - 12.5rem) * 0.47 * 0.94 * 0.1);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: white;

    .timesIcon {
      color: var(---primary);
    }
  }

  .swipe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 75%;
    min-height: 17%;
    border-radius: 9px 9px 36px 36px; /* Set border radius here */
    padding: 15px;
    background-color: white;

    .theme-option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      width: 100%;
      // background-color: yellow;
      // padding: 5px;

      .ThemeContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 10px;
        padding: 5px;
      }

      .theme-name {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: var(--text);
        border-radius: none;

      }

      .theme-colors {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 45px;
        gap: 3px;
        border-radius: none;

        .color-box1,
        .color-box2,
        .color-box3,
        .color-box4 {
          width: 20px;
          height: 20px;
          border-radius: 2px;
        }
      }
    }
  }
}
