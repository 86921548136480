.ScrollGuide-modal-container {
  width: 95%;
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 11px 11px 15px 11px;
  .ScrollGuide-modal-header {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .headerIcon {
      height: 50%;
    }
  }
  .ScrollGuide-modal-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .text-ScrollGuide-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px 0px;
      .text-ScrollGuide-style {
        font-size: 16px;
        font-weight: 400;
        color: #3663d6;
        text-align: center;
        .TextBold {
          font-weight: 600;
        }
      }
    }
  }
  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin-bottom: 10px;
  }
  .ScrollGuide-modal-footer {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 6px;
    .checkBox-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      gap: 7px;
      align-items: center;
      .checkBoxLabel {
        color: #707070;
        font-family: "Hanken Grotesk";
        font-size: 0.75rem;
      }

      .checkBoxInput {
        height: 16px;
        width: 16px;
        border-color: #c8c8c8;
        border-radius: 3px;
      }
    }
    .btn-confirm-ScrollGuide {
      height: 95%;
      min-height: 70%;

      padding: 5px 16px 5px 16px;
      background-color: #3764d6;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      border-width: 0px;
      border-radius: 4px;
    }
  }
}
