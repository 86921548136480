.progressring-graph {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .progressring-graph-label {
    max-width: 100%;
    max-width: 100%;
    height: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
  }
  .progressring-graph-chart {
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
  }
}
.progressring-larg {
}
