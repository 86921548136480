.lookups-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 0rem;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--Header);
  .lBody {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    grid-gap: 0.5rem;
    padding: 0.75rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .card {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 5.5rem;
      padding: 0.625rem;
      justify-content: space-between;
      background-color: var(--Header);
      color: var(--text);
      border: 1px solid #eeeeee;
      text-overflow: ellipsis;
      overflow: hidden;

      .lookup-card-name {
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.24px;
      }
      .lookup-icons {
        display: flex;
        gap: 0.625rem;
      }
    }
    .selected {
      border: 1.5px solid #4c36a5;
    }
    .New {
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 0px;
      background-color: transparent;
      filter: drop-shadow(-1px 1px 2px rgba(22, 22, 22, 0.1))
        drop-shadow(-3px 4px 4px rgba(22, 22, 22, 0.09))
        drop-shadow(-6px 8px 6px rgba(22, 22, 22, 0.05))
        drop-shadow(-10px 14px 7px rgba(22, 22, 22, 0.01))
        drop-shadow(-16px 22px 8px rgba(22, 22, 22, 0));
    }
  }
  .noItem {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
