.FctModal-container {
  display: flex;
  flex-direction: column;
  background-color: var(--header);
  align-items: center;
  justify-content: space-between;
  width: 700px;
  max-width: 700px;
  min-width: 700px;
  max-height: 720px;
  min-height: 600px;
  border-radius: 2px;
  .FctModal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-height: calc(720px - 4rem);
    min-height: calc(600px - 4rem);
    .FctModal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 3.25rem;
      max-height: 3.25rem;
      min-height: 3.25rem;
      border-bottom: 1px solid #ececec;
      padding: 11px 16px;
      .modalTitle {
        color: var(--SupportButtonBg);
        font-weight: 500;
        font-size: 16px;
      }
      .headerActions {
      }
    }
    .Function-content {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1rem 0.75rem;
      row-gap: 1rem;
      // justify-content: flex-start;
      width: 100%;
      padding-bottom: 1rem;
      .Function-line {
        display: grid;

        gap: 2%;
        width: 100%;
      }
    }
  }
  .FctModal-readOnly {
    max-height: 720px;
    min-height: 600px;
  }
  .modalFooter {
    display: grid;
    grid-template-columns: 48.5% 48.5%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    max-height: 4rem;
    min-height: 4rem;
    padding: 0 0.75rem;
    .custom-toast {
      z-index: 10;
    }
  }
}
