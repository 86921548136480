.installer-MaximoLogin {
  display: flex;
  margin: auto;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #eee;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-color: var(--header);
  border: 1px solid var(--BorderStroke);
  width: 35%;
  max-height: 80%;

  .login-bloc-tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    .tabs {
      min-height: 45px;
      cursor: pointer;
      min-width: 33.3%;
      color: var(--ColorButton);
      font-weight: 400;
      padding: 0rem 1.5rem 0rem 1.5rem;
      font-family: "Hanken Grotesk", "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 1rem;
      background-color: var(--CardBg);
    }
    .active-tabs {
      background-color: var(--SupportButtonBg);
      color: white;
      border-bottom: 1px solid transparent;
      font-family: "Hanken Grotesk", sans-serif;
      border: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }
  .installer-login-form-body {
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-height: 75vh;
    max-width: 100%;
    overflow-y: scroll;

    .installer-login-form-title {
      color: var(--Globe);
      text-align: center;
      font-family: "Hanken Grotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    .installer-content-tabs {
      min-width: 100%;
      max-height: 100%;
    }
  }
}
.installer-form {
  color: var(--cool-black, #39354a);
  font-family: "Hanken Grotesk";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  margin: 1em 0 1em 0;
  background-color: var(--Header);
  min-width: 100%;
  box-shadow: none;
  padding: 0;
  align-items: start;
  .invalid-feedback {
    min-width: 100%;
    font-family: "Hanken Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 5px;
    color: #dc2626;
  }
  .secureToggle {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 32%;
    justify-content: space-between;
    color: var(--Globe);
  }
  .inputGroup {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    min-width: 100%;

    .passwordInputContainer {
      display: flex;
      position: relative;
      min-width: 100%;
      padding: 10px 10px 10px 10px;
      box-sizing: border-box;
      border: 1px solid #eee;
      margin-top: 2%;
      margin-bottom: 0%;
      background-color: var(--Header);
      outline: none;
      &:hover {
        border: 1px solid #4c36a5;
      }

      &:focus {
        border-color: #4c36a5;
      }

      &:valid {
        border-color: #5b5151;
      }
    }
  }
  .togglePasswordVisibility {
    position: absolute;
    right: 10px;
    top: 65%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #4c36a5;
  }

  .Simpleinput {
    border-radius: 2px;
    min-width: 100%;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 0%;
    background-color: var(--Header);
    outline: none;
    color: var(--Globe);
    &:hover {
      border: 2px solid #4c36a5;
      background-color: var(--Header);
    }
    &:focus {
      border: 2px solid #4c36a5;
      background-color: var(--Header);
    }
  }

  label {
    position: absolute;
    padding: 0px 5px;
    max-height: 30%;
    left: 12px;
    top: 15px;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 12px;
    font-weight: 400;
    font-family: "Hanken Grotesk";
    background-color: var(--Header);
    color: #aaaaaa;
  }
  .error-input {
    border: 1px solid #dc2626;
  }
  .error-input + label {
    color: #dc2626;
    top: -10px;
    left: 10px;
    font-size: 14px;
    transform: translateY(-20%) scale(1);
    background-color: var(--CardBg);
  }

  .passwordInputContainer:focus + label,
  .Simpleinput:focus + label,
  .passwordInputContainer:not(:placeholder-shown) + label,
  .Simpleinput:not(:placeholder-shown) + label {
    top: -10px;
    left: 10px;
    font-size: 14px;
    color: #4c36a5;
    transform: translateY(-20%) scale(1);
    background-color: var(--CardBg);
  }
}

.login-footer {
  padding: 5% 0%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  .login-cached-icon {
    color: var(--SupportButtonBg);
    margin-right: 2%;
    font-size: 30px;
    cursor: pointer;
  }
  .Cancel-button {
    background-color: #ffffff;
    color: #4c36a5;
    // border: 1px solid #eeeeee;
    border: 1px solid #eeeeee;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 2px;
    font-size: 16px;
    height: 44px;
    margin-right: 10px;
    font-weight: 500;
  }
}

.messagesContainer {
  min-width: 100%;

  .verifiedUrlContainer {
    display: flex;
    align-items: center;
    min-height: 27px;
    max-width: fit-content;
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    .icon-url {
      display: flex;
      justify-content: center;
      margin-left: 20px;
    }
    .show-url {
      color: #16a34a;
      font-size: 14px;
    }
    .show-login-message {
      color: #ce2728;
      font-size: 14px;
    }
  }
}

.ForwardButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px;
  background: var(--SupportButtonBg);
  border: none;
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #ffff;
  padding: 8px 20px 8px 20px;
  .forwardbutton-text {
    margin-right: 15px;
  }
  &:hover {
    background: #4c36a5;
  }
}

button {
  cursor: pointer;
  border: 1px solid var(--BorderStroke);
}
.installer-login-form-body::-webkit-scrollbar {
  display: none;
}
