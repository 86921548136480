.server-control-panel {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--monitorBorder);
  padding-bottom: 0.875rem;

  .server-name-container {
    display: flex;
    gap: 10px;
    .ServerName {
      padding: 0.313rem 0.625rem;
      border-radius: 50px;
      color: var(--loggerServerNameColor);
      background: var(--loggerServerNameBackground);
      font-family: Hanken Grotesk;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      align-self: center;
    }

    .server-name-form {
      display: flex;
      gap: 0.813rem;

      .server-name-input {
        padding: 0.69rem 0.625rem;
        border: 1px solid var(--monitorBorder);
        border-radius: 2px;
        line-height: 20.85px;
        align-self: center;
        background: none;
        color: var(--monitorText);
      }
      .server-name-input:focus-within {
        border: 1px solid var(--ButtonSelected);
      }
    }
  }

  .connect-disconnect-container {
    display: flex;
    gap: 10px;
  }
}
