.nav-drop-zone {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 100%;
  border-radius: 0 0 36px 36px;
  background-color: white;
  .Nav-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: var(---listBg);
    align-items: center;
    overflow-x: auto;
    box-shadow: 0 -4px 5px rgba(39, 39, 39, 0.12);
    gap: 25px;
    padding: 5px 20px 5px 25px;
    border-radius: 0 0 36px 36px;
    padding-bottom: 3%;
    cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    .pageNav-drop-zone {
      display: flex;
      padding: 2px;
      height: calc(100% - 10px);
      width: auto;
      justify-content: center;
      align-items: center;
      color: var(---separatorColor);
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      .page-nav {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 100%;
        min-width: 28%;
        padding: 0 4px;
        justify-content: center;
        align-items: center;
        .page-title {
          display: flex;
          font-size: 11px;
          white-space: nowrap;
        }
      }
    }
    .gis-btn {
      display: flex;
      flex-direction: row;
      padding: 2px;
      height: calc(100% - 10px);
      width: auto;
      justify-content: center;
      align-items: center;
      cursor: url(../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      color: var(---separatorColor);
      .page-nav {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 100%;
        padding: 0 4px;
        justify-content: center;
        align-items: center;
        .page-title {
          display: flex;
          font-size: 11px;
          white-space: nowrap;
        }
      }
    }
    .selectedPage {
      color: var(---primary);
      font-weight: 700;
    }
  }
  .Nav-container::-webkit-scrollbar {
    display: none;
  }
}
