.prop-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 6em) !important;
  min-height: calc(100vh - 6px);
  max-height: calc(100vh - 6em);
  background-color: var(--LoginBG);
  border-left: 1px solid var(--BorderStroke);
  .PropertiesContainer {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    height: 9.5rem !important;
    max-height: 9.5rem;
    min-height: 9.5rem;
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 3.5rem;
      padding: 0 7px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--BorderStroke);
      .titleContainer {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        color: var(--TitleContainer);
      }
    }
    .total-container {
      display: flex;
      flex-direction: column;
      height: 6rem;
      max-height: 6%;
      min-height: 6rem;
      padding: 0 7px;

      .propertiesBody {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        .itemTag {
          display: flex;
          // flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: 3.5rem;
          max-height: 3.5rem;
          min-height: 3.5rem;
          padding-top: 0.7rem;
          border-bottom: 1px solid var(--BorderStroke); // background-color: red;
          .actions {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            // align-self: center;

            .DeleteBtn {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
            .copyPaste {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .separator {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3px;
              min-width: 3px;
              border-radius: 50px;
              margin: 0 20px;
              background-color: var(--separator);
              height: 24px;
              min-height: 24px;
            }
          }
          .SelectedItem {
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: var(--Globe);
            border-radius: 4px 4px 0 0;
            border-color: #4c36a54d;
            border: 1px solid #4c36a54d;
            border-bottom-width: 0px;
            background-color: var(--tagBg);
            padding: 10px;
            gap: 7px;
            .tag-prop-label {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              .SelectedItem-label {
                font-size: 16px;
                font-weight: 700;
                color: var(--tagComp);
              }
              .SelectedItem-value {
                font-size: 16px;
                font-weight: 700;
                color: var(--tag);
              }
            }
          }
          .PropBtns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            .actionBtn {
              display: flex;
            }
            .separator {
              display: flex;
              width: 3px;
              min-width: 3px;
              max-width: 3px;
              border-radius: 50px;
              margin: 0 20px;
              background-color: var(--separator);
              height: 24px;
              min-height: 24px;
            }
          }
        }
        .HeaderProperties {
          display: grid;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          padding-bottom: 0.5rem;
          height: 3rem;
          max-height: 3rem;
          min-height: 3rem;
          font-weight: 600;
          color: var(--Globe);

          .RightBtns {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .PropertiesContainer-max {
    height: 3.5rem !important;
    min-height: 3.5rem;
    max-height: 3.5rem;
  }
  .properties {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 17rem);
    max-height: calc(100vh - 17rem);
    min-height: calc(100vh - 17rem);
    padding: 0 0.75rem;
    .propertie {
      display: grid;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 1rem;
      gap: 6px;
    }
    .ConditionsProperties {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(49.5%, 1fr));
      gap: 1rem 1%;
      padding-bottom: 0.5rem;
    }
  }
  .expend-properties {
    display: flex;
    height: calc((100vh - 18rem) * 0.35);
    max-height: calc((100vh - 18rem) * 0.35);
    min-height: calc((100vh - 18rem) * 0.35);
    overflow-y: auto;
  }
  .maximize-properties {
    display: flex;
    height: 0rem;
    max-height: 0rem;
    min-height: 0rem;
    overflow-y: auto;
  }
  .expend-properties::-webkit-scrollbar {
    display: none;
  }
  .maximize-properties::-webkit-scrollbar {
    display: none;
  }
  .properties::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  .properties::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 10px;
  }
  .properties::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .XmlUtilites {
    padding: 0 0.75rem;
  }
}
