.DropDownCompare-container {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  position: relative;
  .Compare-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    gap: 10px;
    position: relative;
    .label-search {
      display: flex;
      width: 100%;
      color: #aaaaaa;
      font-size: 12px;
    }
    .Compare-search {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 40px;
      position: relative;
      border: solid 1px #eeeeee;
      border-radius: 2px;

      .search-icon {
        display: flex;
        height: 40px;
        width: 40px;
        cursor: url(../../../ComponentsLibrary/Assets/Pointer.svg), auto;
        justify-content: center;
        align-items: center;
      }
      .search-input {
        display: flex;
        width: calc(100% - 40px);
        height: 100%;
        border: #ececec;
        background-color: white;
        padding-left: 2%;
      }
    }
    .Compare-search:focus-within {
      border: 1px solid #4c36a5; /* Adjust border as needed */
    }
    .Compare-search.text-entered {
      border: 1px solid #4c36a5;
    }
    .search-input::placeholder {
      opacity: 0.5;
      color: #aaaaaa;
    }
    .dnd-container {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23AAAAAAFF' stroke-width='1' stroke-dasharray='12%2c 15%2c 14%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 10px;
      width: 100%;
      height: 250px;
    }

    .versionList {
      display: none;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 50px);
      overflow-y: auto;
      justify-content: flex-start;
      align-items: start;
      padding-left: 4%;
      padding-top: 2%;
      padding-right: 4%;
      background-color: white;
      box-shadow: #22222225 0px 7px 20px 0px;
      height: auto;
    }
    .versionList-active {
      display: block; /* Show the dropdown */
      position: absolute;
      top: 131%;
      box-shadow: 0px 7px 26px -7px rgba(34, 34, 34, 0.25);
      max-height: 800%;
      z-index: 100; /* Ensure this is higher than the dnd-container */
      background: white;
      padding: 16px; /* Assuming a white background */
    }
    @media (max-height: 700px) {
      .versionList-active {
        top: 120%; /* Smaller value since the screen is smaller */
        /* Adjust any other properties as needed */
      }
    }
    @media (max-height: 1000px) {
      .versionList-active {
        top: 120.3%; /* Smaller value since the screen is smaller */
        /* Adjust any other properties as needed */
      }
    }
    .revisionContent:hover {
      background-color: #4c36a5;
      color: white;
    }
    .revisionContent {
      width: 100%;
      padding: 8px 10px 8px 10px;
      margin-bottom: 9px;
      justify-content: space-between;
      display: flex;
      border-radius: 2px;
      .xmlAlias {
        font-size: 16px;
        font-weight: 500;
      }
      .releaseDatetime {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
