.NotFoundContainer {
  width: 100vw;
  height: calc(100vh - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  color: white;
  padding: 4rem;
  padding-left: 7%;

  .TopLeftContent {
    align-self: flex-start;
    width: calc(100vw * 0.3);
    height: calc(100vh * 0.3);

    .Oops {
      color: #fff;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: calc(100vw * 0.035);
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: calc(100% * 0.04);
      width: calc(100vw * 0.5);
      height: calc(100vh * 0.18);
    }
    .Message {
      color: #fff;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      margin-bottom: 0%;
      height: calc(100vh * 0.05);
    }
    .Error {
      margin-top: calc(100vh * 0.03);
      width: calc(100vw * 0.35);
    }
  }

  .Buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-height: 100%;

    .HomeButton {
      display: flex;
      width: 20%;
      height: 100%;
      max-height: 100%;
      padding: 0.625rem 1rem;
      justify-content: center;
      align-items: center;
      color: var(--ColorButton);
      background-color: var(--CardBg);
      border: 2px solid var(--BorderColor);
      gap: 0.625rem;
      border-radius: 0.125rem;
      margin-left: 2%;
      cursor: pointer;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .Support {
      display: flex;
      width: 15%;
      height: 3.75rem;
      padding: 0.625rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      font-family: "Hanken Grotesk", sans-serif;
      background-color: var(--SupportButtonBg);
      border: 1px solid var(--BorderButtonSupport);
      flex-shrink: 0;
      color: #fff;
      border-radius: 0.125rem;
      font-weight: 600;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: #7d6dbe;
      }
    }
    .Fleche {
      width: 1.50006rem;
      height: 1.5rem;
      flex-shrink: 0;
    }
  }
}
