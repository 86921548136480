.logFiles-content-container {
  display: flex;
  min-width: 16rem;
  width: 48%;
  border: 1px solid var(--monitorBorder);
  padding: 0.625rem 0.938rem 0.625rem 0.938rem;
  justify-content: space-between;
  background: var(--monitorInput);

  .file-info {
    display: flex;
    gap: 0.625rem;
    .file-details {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
      .file-name {
        font-family: Hanken Grotesk;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
      .file-name {
        font-family: Hanken Grotesk;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }

      .file-line,
      .file-size {
        font-family: Hanken Grotesk;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #707070;
      }
    }
  }

  .file-actions {
    display: flex;
    gap: 8px;
    align-items: center;

    .clear-icon,
    .download-icon {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
    }

    .vertical-bar {
      height: 20px;
      width: 2px;
      border-radius: 2px;
      background-color: var(--loggerSeparator);
      display: flex;
      margin-left: 4px;
    }
  }
}
