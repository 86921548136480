.performance-cpu,
.performance-ram,
.performance-hdd {
  display: flex;
  flex-direction: column;
  padding: 0.625rem 1rem;
  border-radius: 0.125rem;
  border: 1px solid var(--SpBoxStroke);
  background: var(--SpBox);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  gap: 0.31rem;
  h2 {
    color: var(--PerformanceText);
    font-family: Hanken Grotesk;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .performance-icon-stats {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.62rem;
    align-items: center;

    .performance-stats-main,
    .performance-stats-unit {
      font-family: Hanken Grotesk;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      align-self: flex-end;
    }

    .performance-stats-unit {
      font-size: 0.875rem;
    }
  }
}
