.default-sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 9rem);
  align-self: stretch;
  gap: 0.625rem;
  padding: 1.25rem;
  border-radius: 2px;
  border: 1px solid var(--BorderStroke);
  background-color: var(--CardBg);
  .default-actions {
    display: flex;
    flex-direction: column;
    height: 19.813rem;
    align-items: center;
    align-self: stretch;
    gap: 0.625rem;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 14px;
    font-weight: 400;
    .search-bar {
      display: flex;
      padding: 0.563rem 0.625rem;
      align-items: center;
      align-self: stretch;
      border-radius: 2px;
      background: var(--serachBar);
      border: 1px solid var(--BorderStroke);
      height: 40px;

      .uSearch-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #cccc;
        background: var(--serachBar);
      }

      .search-input {
        color: var(--text);
      }
      ::-webkit-input-placeholder {
        font-family: "Hanken Grotesk", sans-serif;
        color: #aaaaaa;
      }
    }
    .icons-info {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      height: 5rem;
      gap: 0.625rem;
      .info {
        display: flex;
        align-self: stretch;
        justify-content: space-between;
        border-bottom: 1px solid var(--BorderStroke);
        padding-bottom: 10px;
        .info-title {
          color: var(--info_title);
        }
        .info-value {
          color: var(--TitleContainer);
        }
      }
    }
    .drop-icons {
      display: flex;
      height: 11.563rem;
      padding: 1.563rem 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.313rem;
      align-self: stretch;
      background-image: repeating-linear-gradient(
          0deg,
          var(--BorderDashed),
          var(--BorderDashed) 30px,
          transparent 30px,
          transparent 52px,
          var(--BorderDashed) 52px
        ),
        repeating-linear-gradient(
          90deg,
          var(--BorderDashed),
          var(--BorderDashed) 30px,
          transparent 30px,
          transparent 52px,
          var(--BorderDashed) 52px
        ),
        repeating-linear-gradient(
          180deg,
          var(--BorderDashed),
          var(--BorderDashed) 30px,
          transparent 30px,
          transparent 52px,
          var(--BorderDashed) 52px
        ),
        repeating-linear-gradient(
          270deg,
          var(--BorderDashed),
          var(--BorderDashed) 30px,
          transparent 30px,
          transparent 52px,
          var(--BorderDashed) 52px
        );
      background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
      #vector {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
      }
      p {
        color: var(--no-color, #b9b8bd);
        text-align: center;
        margin: 0;
      }
      .upload-instructions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        #text-S {
          font-size: 10px;
        }
      }
    }
  }
}
