.inspection-content-right-open {
  min-width: 50%;
  max-width: 50%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 50%;

}

.inspection-content-right-close {
  min-width: 80%;
  max-width: 80%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
  width: 50%;
 
}
