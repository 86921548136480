.graphKpi {
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  justify-content: center;
  align-items: center;

  .graph-container {
    display: flex;
    width: calc(((100vh - 10.5rem) * 0.47 * 0.425) - 12px);
    height: calc(((100vh - 12.5rem) * 0.47 * 0.425) - 12px);
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 9px;
    margin: 6px 0;
  }
  .graph-large {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(((100vh - 10.5rem) * 0.47 * 0.868) - 12px);
    height: calc(((100vh - 12.5rem) * 0.47 * 0.65) - 12px);
  }
}
.graphKpi-page {
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  justify-content: center;
  align-items: center;
  .graph-container-page {
    display: flex;
    width: calc((100vh - 10.5rem) * 0.47 * 0.421);
    height: calc((100vh - 12.5rem) * 0.47 * 0.38);
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 9px;
  }
  .graph-large-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100vh - 10.5rem) * 0.47 * 0.863);
    height: calc((100vh - 12.5rem) * 0.47 * 0.65);
  }
}

.sampleKpi {
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  justify-content: center;
  align-items: center;
}
