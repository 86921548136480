.privacyPolicy-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-top: 6%;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  .title-pp {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    font-size: small;
    font-weight: 600;
    color: #6f53a3;
  }
  .body-pp {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: left;
    font-size: x-small;
    font-weight: 300;
    padding-bottom: 2%;
    padding-top: 2%;
  }
  .btn-pp {
    display: flex;
    width: 100%;
    border-radius: 9px;
    background-color: #6f53a3;
    border: 0px;
    margin-top: 2%;
    justify-content: center;
    align-items: center;
    padding: 4%;
    color: white;
    font-size: small;
  }
}
