.bodies {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 100%;
  padding-bottom: 1%;
  padding-top: 1%;
}
.ListBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 100%;

  .bbb {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
  }
}
.ListBody::-webkit-scrollbar {
  display: none;
  min-height: 100%;
}
