.layer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;


  .right-btns-map-container {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((22.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      width: 40px;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        border-radius: 25px;
        border: 1px, 0px, 0px, 0px;
        gap: 10px;
        overflow: auto;
        max-height: 220px;
        opacity: 0.9;
        transition: opacity 0.3s;

        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          padding: 7px 7px;
          border-radius: 500px;
          margin: 3px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;

        }
      }
    }
  }
  .right-btns-map-container-up {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((56.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      width: 40px;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        border-radius: 25px;
        border: 1px, 0px, 0px, 0px;
        gap: 10px;
        overflow: auto;
        max-height: 220px;
        opacity: 0.9;
        transition: opacity 0.3s;

        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          padding: 7px 7px;
          border-radius: 500px;
          margin: 3px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;

        }
      }
    }
  }
  .right-btns-map-container-without-retry {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((22.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 100px;
        max-width: 100px;
        overflow: auto;
        width: 40px;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;

        }
      }
    }
  }
  .right-btns-map-container-without-retry-up {
    min-height: 50px;
    min-width: 60px;
    height: 40px;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: calc((56.5vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-end;
    .btns-line-wrapper {
      width: auto;
      display: flex;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;

      .btn-style {
        height: 34px;
        width: 34px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btns-line-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 100px;
        max-width: 100px;
        overflow: auto;
        width: 40px;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 52px;
          width: 52px;
        }
      }
      .btns-line-content-map-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .btn-horizontale-wrapper {
          height: 32px;
          width: 32px;
          border-radius: 500px;
          margin: 0px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }
        .btn-horizontale-wrapper-map-style {
          height: 32px;
          width: 32px;

        }
      }
    }
  }
  .right-top-btns-map-container {
    min-height: 60px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((19vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .btn-back-wrapper {
      height: 34px;
      width: 34px;
      border-radius: 500px;
      background-color: #fff;
    }
  }
  .left-bottom-btns-map-container {
    min-height: 50px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((18.5vh - 10.5rem) * 0.9 * 0.82) !important;
    right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .btns-line-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .btn-style {
        height: 35px;
        width: 35px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
      }
      .btn-style-target {
        height: 40px;
        width: 40px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        align-self: "center";
        text-align: center;
      }
    }
  }
  .left-bottom-btns-map-container-pullup {
    min-height: 50px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((23.5vh - 10.5rem) * 0.9 * 0.82) !important;
    right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .btns-line-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .btn-style {
        height: 35px;
        width: 35px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
      }
      .btn-style-target {
        height: 40px;
        width: 40px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        align-self: "center";
        text-align: center;
      }
    }
  }
  .right-top-btns-map-container-pullup {
    min-height: 60px;
    min-width: 60px;
    height: auto;
    width: auto;
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc((24vh - 10.5rem) * 0.9 * 0.82) !important;
    left: calc((20vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .btn-back-wrapper {
      height: 34px;
      width: 34px;
      border-radius: 500px;
      background-color: #fff;
    }
  }
  .right-top-plus-btns-map-container {
    min-height: 50px;
    min-width: 42px;
    height: auto;
    width: 42px;
    position: absolute;
    z-index: 2;
    top: calc((30vh - 10.5rem) * 0.9 * 0.82) !important;
    right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    .btns-line-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn-style {
        height: 35px;
        width: 35px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
      }
      .btn-style-target {
        height: 40px;
        width: 40px !important;
        background-color: #fff;
        border-radius: 500px;
        margin: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        align-self: "center";
        text-align: center;
      }
    }
  }
}
.map-in-form {
  height: 200px;
  width: 100%;
}
.onlymap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.902);
  height: 250px;
}

.list-extended-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  text-align: start;
 
  z-index: 2;
  top: calc((47.5vh - 10.5rem) * 0.9 * 0.82) !important;
  right: calc((34vh - 10.5rem) * 0.9 * 0.47 * 0.9);
}

.btn-horizontale-list-content {
  height: 26.5px;
  width: 26.5px;
  border-radius: 500px;
  margin: 0px 3px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  margin-bottom: 3px;
}

.layer-content
  .right-btns-map-container
  .btns-line-content
  ::-webkit-scrollbar {
  display: none; 
}
.layer-content
  .right-btns-map-container-without-retry-up
  .btns-line-content
  ::-webkit-scrollbar {
  display: none; 
}

.layer-content
  .right-btns-map-container-up
  .btns-line-wrapper
  .btns-line-content::-webkit-scrollbar {
  display: none; 
}
.layer-content
  .right-btns-map-container
  .btns-line-wrapper
  .btns-line-content::-webkit-scrollbar {
  display: none; 
}

.active-serach-container {
  min-height: 50px;
  min-width: 100%;
  height: auto;
  width: auto;
  // background-color: red;
  display: flex;
  position: absolute;
  z-index: 2;
  top: calc((18.5vh - 10.5rem) * 0.9 * 0.82) !important;
  right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 5px;
  .active-serach-record-container {
    width: 90%;
    min-height: 40px;
    background-color: #fff;
    padding: 0px, 10px, 0px, 10px;
    border-radius: 9px;
    gap: 9px;
    box-shadow: 0px 6px 14px 4px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-serach-btn {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .serach-bar-input {
      width: 70%;
      background-color: #fff;
      border-width: 0px;
      outline: 0px;
    }
    .open-filter-btn {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .active-serach-location-container {
    width: 90%;
    min-height: 40px;
    background-color: #fff;
    padding: 0px, 10px, 0px, 10px;
    border-radius: 9px;
    gap: 9px;
    box-shadow: 0px 6px 14px 4px #00000040;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-location {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .serach-location-input {
      width: 85%;
      background-color: #fff;
      border-width: 0px;
      outline: 0px;
    }
    .close-location-btn {
      border-width: 0px;
      background-color: #aaaaaa;
      padding: 3px;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/**********************/
.active-serach-container-pullup {
  min-height: 50px;
  min-width: 100%;
  height: auto;
  width: auto;
  display: flex;
  position: absolute;
  z-index: 2;
  top: calc((23.5vh - 10.5rem) * 0.9 * 0.82) !important;
  right: calc((18vh - 10.5rem) * 0.9 * 0.47 * 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 5px;
  .active-serach-record-container {
    width: 90%;
    min-height: 40px;
    background-color: #fff;
    padding: 0px, 10px, 0px, 10px;
    border-radius: 9px;
    gap: 9px;
    box-shadow: 0px 6px 14px 4px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-serach-btn {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .serach-bar-input {
      width: 70%;
      background-color: #fff;
      border-width: 0px;
      outline: 0px;
    }
    .open-filter-btn {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .active-serach-location-container {
    width: 90%;
    min-height: 40px;
    background-color: #fff;
    padding: 0px, 10px, 0px, 10px;
    border-radius: 9px;
    gap: 9px;
    box-shadow: 0px 6px 14px 4px #00000040;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-location {
      border-width: 0px;
      background-color: #fff;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .serach-location-input {
      width: 85%;
      background-color: #fff;
      border-width: 0px;
      outline: 0px;
    }
    .close-location-btn {
      border-width: 0px;
      background-color: #aaaaaa;
      padding: 3px;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

