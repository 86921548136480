.appBar {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.563rem;
  max-height: 2.563rem;
  min-height: 2.563rem;
  background-color: var(--BodyBg);
  border-bottom: 1px solid var(--BorderStroke);

  .leftButtons {
    display: flex;
    align-items: center;
    width: 30%;
  }
  .middleButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 4px 10px 4px 10px;
    width: 40%;
    
    .revision-selected {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: 100%;
      padding: 10px 16px;
      font-weight: 500;
      gap: 10px;
      .revision-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: var(--fileIcon);

        .revision-type-label {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .revision-label {
        display: flex;
        max-width: 165px;
        align-items: center;
        align-self: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 16px;
        line-height: 21px;
        // text-align: left;
        color: var(--TitleContainer);
      }
      .revision-status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        height: 28px;
        gap: 5px;
        border-radius: 50px;
        font-size: 14px;
        line-height: 18px;
        color: var(--Globe);
        .revision-status-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: url(../ComponentsLibrary/Assets/Pointer.svg), auto;
        }
      }
    }
  }
  .rightButtons {
    display: flex;
    // width: 30%;
    justify-content: end;
    
  }
}

.renameContent {
  display: flex;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  border-radius: 0;
}

.buttonText {
  font-size: 14px;
}

.publishButton {
  background-color: #008000;
}
