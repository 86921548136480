.sidebar-btn {
  display: flex;
  height: 4rem;
  width: 100%;
  padding: 1rem;
  align-items: end;
  background-color: var(--CardBg);
  border-top: 1px solid var(--BorderStroke);
  .deleteButton {
    display: flex;
    color: var(--text);
    width: 100%;
    padding: 10px 16px;
    align-self: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    font-family: "Hanken Grotesk", sans-serif;
    font-weight: 500;
    border: none;
    outline: none;
    background-color: var(--CardBg);
    cursor: pointer;
    border: 1px solid var(--BorderStroke);
  }
}
