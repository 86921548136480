.splashScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: #fff;
  border-radius: 0 0 36px 36px;
  .logoSplash {
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
}
