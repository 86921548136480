.header-home {
  display: flex;
  height: 12%;
  min-height: 12%;
  max-height: 12%;
  width: 100%;
  color: var(---headerText);
  background: linear-gradient(
    var(---headerPrimary) 50%,
    var(---headerDark) 50%
  );
  .header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .Logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
      height: 100%;
      border-top-left-radius: 16px;
      padding-left: 4%;
      .logo-img {
        width: 90%;
        border: 1px solid white;
        // padding: 10%;
        background-color: white;
        border-radius: 100%;
        box-shadow: 0 0 8px rgba(72, 72, 72, 0.3);
      }
    }
    .Date-weather {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      font-size: 11px;
      color: black;
      .weather {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .weather-icon {
          width: 25px;
        }
      }
      .date {
        max-width: 100%;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // color: red;
      }
    }
    .offlineTrans {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 25%;
      height: 100%;
      border-top-right-radius: 16px;
      padding-right: 4%;
      color: var(---primary);
    }
  }
}
.greetingDisplay {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  height: 5%;
  align-items: center;
  padding-right: 3%;
  padding-left: 3%;
  font-size: small;
  gap: 1%;
  color: var(---textColor);
  .userName {
    font-weight: 600;
  }
}

.notifications-content {
  display: flex;
  width: 100%;
  height: 14%;
  max-height: 14%;
  min-height: 14%;
}
