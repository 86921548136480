.DeviceView {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  overflow-y: hidden;
  overflow-x: hidden;
  .mobileDevice {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 36px 36px;
    background-color: white;
    .selectedComponent {
      background-image: repeating-linear-gradient(
          0deg,
          #3663d69c,
          #3663d69c 9px,
          transparent 9px,
          transparent 19px,
          #3663d69c 19px
        ),
        repeating-linear-gradient(
          90deg,
          #3663d69c,
          #3663d69c 9px,
          transparent 9px,
          transparent 19px,
          #3663d69c 19px
        ),
        repeating-linear-gradient(
          180deg,
          #3663d69c,
          #3663d69c 9px,
          transparent 9px,
          transparent 19px,
          #3663d69c 19px
        ),
        repeating-linear-gradient(
          270deg,
          #3663d69c,
          #3663d69c 9px,
          transparent 9px,
          transparent 19px,
          #3663d69c 19px
        );
      background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
    }
    .unSelectedComponent {
      background-image: repeating-linear-gradient(
          0deg,
          #dcdcdc,
          #dcdcdc 9px,
          transparent 9px,
          transparent 19px,
          #dcdcdc 19px
        ),
        repeating-linear-gradient(
          90deg,
          #dcdcdc,
          #dcdcdc 9px,
          transparent 9px,
          transparent 19px,
          #dcdcdc 19px
        ),
        repeating-linear-gradient(
          180deg,
          #dcdcdc,
          #dcdcdc 9px,
          transparent 9px,
          transparent 19px,
          #dcdcdc 19px
        ),
        repeating-linear-gradient(
          270deg,
          #dcdcdc,
          #dcdcdc 9px,
          transparent 9px,
          transparent 19px,
          #dcdcdc 19px
        );
      background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
    }
    .hoverComp {
      &:hover {
        background-color: #3663d65f;
      }
    }
  }
}
