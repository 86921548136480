.Environment-grid {
  display: grid;
  grid-template-columns: 200px 1fr;

  p {
    color: var(--monitorText);
    padding: 0.938rem 1rem;
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid var(--monitorBorder);
    background: var(--TableOdd);
  }

  .Environment-label {
    background: var(--TableEven);
  }
}
