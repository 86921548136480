.sideBar {
  padding-bottom: 0.75rem;
  height: calc(100vh - 7rem) !important;
  max-height: calc(100vh - 7rem) !important;
  display: flex;
  flex-direction: column;

  .treeContainer {
    overflow: auto;
    height: calc(100vh - 10.5rem) !important;
    max-height: calc(100vh - 10.5rem) !important;

    .layerListContainer {
      margin-left: 10px;
      border-left: 2px solid #bbb;
    }
  }

  .treeContainer::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  .treeContainer::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 10px;
  }

  .treeContainer::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
