.pins-container {
  min-height: 30px;
  width: 100%;
  //   background-color: red;
  position: absolute;
  z-index: 1;
  top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  overflow-x: auto;
}

.pins-container-pullup {
  min-height: 30px;
  width: 100%;
  //   background-color: red;
  position: absolute;
  z-index: 1;
  top: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  overflow-x: auto;
}
.pin-wrapper {
  min-height: 20px;
  width: auto;
  min-width: 20px;
  max-width: fit-content;
  background-color: #fff;
  border-radius: 9px;
  padding: 5px 5px 5px 5px;
  cursor: pointer;

}
.pin-attribute-wrapper {
  width: auto;
  background-color: #fff;
  border-radius: 9px;
  min-height: 20px;
  height: 20px;
  line-height: 24px;
  cursor: pointer;

}
.pins-container::-webkit-scrollbar {
  display: none;
}
.pins-container-pullup::-webkit-scrollbar {
  display: none;
}

.pin-wrapper:hover{
  background-color: #A0B9CE;
}
