.Explorer {
  .bar-title {
    padding-top: 9px;
    padding-bottom: 2px;
    padding-left: 20px;
    border-bottom: 1px solid #a2a2a3;
    .compTitle {
      color: #6f53a3;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
.explorer-navigator {
  overflow-y: scroll;
  height: 88vh;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4ecf7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f53a3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f53a3;
}
