@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Hanken Grotesk", sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
* {
  letter-spacing: normal !important;
}
html,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Hanken Grotesk", sans-serif;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
