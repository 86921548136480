.threshold-form-container {
  display: flex;
  position: absolute;
  bottom: 1rem !important;
  left: -1rem !important;
  z-index: 2;
  flex-direction: column;
  grid-gap: 0.625rem;
  grid-gap: 0.625rem;
  gap: 1rem;
  width: 34.438rem;
  padding: 1rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
  background: var(--emailFormBackground);
  box-shadow: 0px 2px 16px 0px rgba(2, 2, 2, 0.15);
  border: 1px solid #eee;
  input[type="number"] {
    -moz-appearance: textfield; /* For Firefox */
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* For WebKit browsers */
    margin: 0;
  }
  .form-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .input-container {
      border-radius: 2px;
    }

    .submit-btn {
      border-radius: 2px !important;
      background: #ffffff;
      color: var(--cool-black, #39354a);
      font-family: Hanken Grotesk;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0.625rem 1rem;
      text-align: center;
      border: none !important;
      cursor: pointer;
    }
    .cancel-btn {
      border-radius: 2px !important;
      background: #ffffff;
      color: var(--cool-black, #39354a);
      font-family: Hanken Grotesk;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0.625rem 1rem;
      text-align: center;
      border: none;
      cursor: pointer;
    }
    .submit-btn:hover,
    .cancel-btn:hover {
      background: #7d6dbe;
      color: #ffffff;
    }

    .submit-btn {
      width: 22.063rem;
    }
    .cancel-btn {
      width: 9.438rem;
    }
  }
}
