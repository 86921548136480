.unknown-sample-kpi {
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #00000080;
}
.unknown-sample-kpi-page {
  width: calc((100vh - 10.5rem) * 0.47 * 0.421);
}
