.home-settings-high-container {
  height: 100%;
  width: 100%;
  .home-settings-container {
    width: 100%;
    height: 90%;
    padding: 2% 4%;
    overflow-y: auto;
    .filters-container {
      background-color: #e0e0e0e0;
      height: 20%;
      border-radius: 6px;
    }
    .opt-questions-container {
      background-color: #e0e0e0e0;
      height: 10%;
      border-radius: 6px;
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      .btn {
        display: flex;
        justify-content: flex-start;
        border-radius: 9px;
        border: 1px solid #eee;

        background: #fff;
        margin-top: 5px;
        padding: 5px 5px;
      }
    }
    .opt-qte-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
    .total-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
    .requires-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
    .body-content {
      height: auto;
      width: 100%;
      padding: 3px;
      min-height: 45px;

      .name-container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        color: #6a549e;
        font-weight: 700;

        .name-value-txt {
          color: #000;
          font-family: Hanken Grotesk;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .created-date-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
      .assets-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .foot {
    bottom: 0px;
    background-color: #eee;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    box-shadow: 0 0 25px 0 #22222226;
    .btn-start {
      width: 100%;
      height: 100%;
      border-radius: 9px;
      border: 1px solid #aaa;
    }
  }
}
