.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc((92vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  max-height: calc((92vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  min-height: calc((92vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  .profile-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 7%;
    max-height: 7%;
    min-height: 7%;
    padding-left: 2%;
    padding-right: 2%;
    gap: 2%;
  }
  .profile-body {
    display: flex;
    width: 100%;
    height: 93%;
  }
}
