.modalView {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(2, 2, 2, 0);
  backdrop-filter: blur(6px);
  font-family: "Hanken Grotesk", sans-serif;
  color: var(--text);
  .modalContainer {
    background-color: var(--header);
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 1rem;
    gap: 16px;
    border-radius: 2px;
    .modalHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 2px solid #eee;
      .void {
        display: flex;
        width: 22px;
        height: 100%;
      }
      .modalTitle {
        display: flex;
        width: calc(100% - 44px);
        gap: 5px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        .modalIcon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .closeButton {
        display: flex;
        width: 22px;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .modalContent {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }
    .modalActions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
      padding-bottom: 1px;
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 10px;
        .primaryAction {
          display: flex;
          width: 100%;
        }
        .secondaryAction {
          display: flex;
          width: 100%;
        }
      }
      .additionalInformation {
        font-size: 12px;
        font-weight: 300;
        color: #39354a;
      }
    }
  }
}
