.many-details {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 9rem);
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid var(--BorderStroke);
  background-color: var(--CardBg);
  .selected-icons {
    display: flex;
    height: 1.625rem;
    padding: 5px 20px;
    align-items: center;
    align-self: stretch;
    margin-top: 0.5rem;
    color: #bfbfbf;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid var(--BorderStroke);
  }

  .icons-display {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow-y: auto;

    .icon-display {
      display: flex;
      padding: 10px 15px;
      flex-direction: row;
      align-self: stretch;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--BorderStroke);
      .display-dtl {
        display: flex;
        width: 10.531rem;
        gap: 15px;

        img {
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          object-fit: contain;
        }
        .icon-name {
          // display: flex;
          justify-content: center;
          display: block;
          align-items: center;
          color: var(--text);
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 16px;
          font-weight: 400;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .delete-cross {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        color: #ddd;
        cursor: pointer;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
