.SyncAutomationScriptsContent {
  border-radius: 2px;
  min-height: 23%;
  padding: 20px 40px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  margin-bottom: 3%;
  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .Automation-Scripts-Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;

    .AutomationScriptsList {
      display: flex;
      flex-direction: column;
      margin-top: 1%;
      border-top: 1px solid var(--monitorBorder);
      padding-top: 10px;
      .Automation-Script-Expand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .AutomationScriptsTitle {
          color: var(--Globe);
          font-family: "Hanken Grotesk";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-bottom: 1%;
        }
      }
      .Automation-Script-Footer {
        display: flex;
        min-height: 10%;
        justify-content: space-between;
        flex-direction: row;
        .Installation-Status {
          display: flex;
          flex-direction: row;
          margin-bottom: 1%;
          min-width: 50%;
          .Installed-state {
            color: var(--Green);

            font-family: "Hanken Grotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .Failed-state {
            padding-left: 5%;
            color: var(--Red);

            font-family: "Hanken Grotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .Installation-Status-icons {
          display: flex;
          flex-direction: row;
          margin-bottom: 1%;
          min-width: 50%;
          justify-content: end;
          align-items: center;
          .error-icon {
            display: flex;
            color: var(--Red);
            justify-content: space-between;
            min-width: 28px;
            align-items: center;
            font-family: "Hanken Grotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 2px;
          }
          .warning-icon {
            display: flex;
            color: var(--Orange);
            justify-content: space-between;
            min-width: 28px;
            align-items: center;

            font-family: "Hanken Grotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--Orange);
            margin-left: 10px;
          }
        }
      }
    }
  }
  .Automation-Scripts-Body {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 2%;
    padding-top: 1%;
    border-top: 1px solid var(--BorderButtons);
    border-bottom: 1px solid var(--BorderButtons);

    .Sync-step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      .Sync-step-name {
        display: flex;
        justify-content: center;
        color: var(--InstallerScript);
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .icon-hover {
          color: #aaa;
          &:hover {
            color: #4c36a5;
          }
        }
      }
      .check-installed {
        color: var(--Green);
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .check-failed {
        color: var(--Red);
        font-family: "Hanken Grotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
