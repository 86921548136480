.drag-tag-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px;
  border-radius: 3px;
  .tag-content {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: x-small;
    padding: 2px 7px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }
}
