.SyncConfigContainer {
  display: flex;
  min-width: 100%;
  min-height: 100%;
  flex-direction: column;
  align-items: center;

  .SyncConfig-body {
    display: flex;
    max-width: 100%;
    min-width: 100%;
    min-height: 90%;
    flex-direction: column;
    justify-content: center;

    .Sync-ipAdress {
      color: var(--TitleContainer);
      font-family: "Hanken Grotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-wrap: break-word;
    }
    .Sync-error {
      color: var(--Red);
      font-family: "Hanken Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 24px 0px;
      word-wrap: break-word;
      overflow: auto;
    }
    .SyncInput {
      background-color: var(--CardBg);
      display: flex;
      padding: 12px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 2px;
      border: 1px solid #eeeeee;
      height: 40px;
      margin-bottom: 20px;
      color: var(--Globe);
    }
  }
  .SyncInput:focus {
    border: 1px solid var(--ButtonSelected);
  }

  .SyncConfig-footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    min-height: 7%;
    .SyncConfig-submit-button {
      background-color: var(--ButtonSelected);
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 16px;
      margin-left: 2%;
      height: 44px;
    }
    .SyncConfig-skip-button {
      background-color: #ffffff;
      color: var(--ButtonSelected);
      border: 1px solid var(--ButtonSelected);
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 16px;
      height: 44px;
    }
  }
}
