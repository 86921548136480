.event-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: var(---primary);
}
