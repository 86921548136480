.email-input-container {
  padding: 0rem 0.625rem;
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--emailInputBorder);
  //padding: 0.5rem;
  width: 100%;
  gap: 0.625rem;
  flex-wrap: wrap;
}

.email-input-container input {
  color: var(--Globe);
  border: none;
  background: none;
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 50%;
  height: 2.875rem;
}
.email-input-container input::placeholder {
  color: var(--emailPlaceholderColor);
}

.email-pill {
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 72px;
  background: var(--emailBackground);
  color: var(--emailButtonBackground);
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.85px;

  .delete-btn {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-align: left;
    cursor: pointer;
    margin-bottom: -4px;
  }
}

.email-input-container .clear-icon {
  color: white;
  cursor: pointer;
}

.email-input-container .clear-icon:hover {
  color: #ddd;
}
