.media-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: auto;
  border-radius: 15px;
  padding: 2.3%;
  background-color: var(---welcomeBg);
  min-width: 85%;
}
.media-bar::-webkit-scrollbar {
  display: none;
}

.geoposition {
  display: flex;
  height: 100%;
  width: 15%;
  justify-content: center;
  align-items: center;
}

.media-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
