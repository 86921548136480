.user-stat {
  display: flex;
  gap: 0.9375rem;
  padding: 0.625rem 1rem;
  background-color: var(--SpBox);
  border-radius: 0.125rem;
  border: 1px solid var(--SpBoxStroke);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  h1 {
    color: var(--UsersNumber);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 2.625rem;
    font-weight: 600;
  }

  p {
    color: var(--UsersText);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    align-self: center;
  }
}
