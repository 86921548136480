.drop-row-zone {
  display: flex;
  width: 100%;
  .row-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding: 0%;
    .DragItemApp {
      display: flex;
      flex-direction: row;
      border-radius: 9px;
      width: 96%;
      margin-left: 2%;
      height: 50px;
      background-color: white;
      .appRow-container {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 0;
        .icon {
          display: flex;
          width: 50px;
          height: 50px;
          justify-content: center;
          align-items: center;
        }
        .app-title {
          width: calc(100% - 50px);
          max-width: calc(100% - 50px);
          padding-right: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .kpi-container {
      display: flex;
      flex-direction: row;
      width: 96%;
      margin-left: 2%;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 2%;
      .Kpi-Drag {
        display: flex;
        width: auto;
        .Kpi-DragItemApp {
          display: flex;
          width: auto;
          background-color: white;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          padding: 0 1px;
          min-height: 50px;
          margin: 2px;
        }
      }
    }
    .kpi-container::-webkit-scrollbar {
      //   display: none;
      height: 2px;
    }
    .Drag-empty-row {
    }
  }
  .matrix-row-body {
    flex-direction: row;
    width: 100%;
    overflow: auto;
    gap: 1%;
    .matrix-app {
      display: flex;
      flex-direction: row;
      margin-left: 1%;
      min-width: calc((100vh - 10.5rem) * 0.47 * 0.423);
      max-width: calc((100vh - 10.5rem) * 0.47 * 0.423);
      width: calc((100vh - 10.5rem) * 0.47 * 0.423);
      height: calc((100vh - 12.5rem) * 0.47 * 0.423);
      min-height: calc((100vh - 12.5rem) * 0.47 * 0.423);
      max-height: calc((100vh - 12.5rem) * 0.47 * 0.423);
      .appRow-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 5px;
      }
    }
  }
  .matrix-row-body::-webkit-scrollbar {
    //   display: none;
    height: 2px;
  }
  .edit {
    padding: 8px 0;
    background-image: repeating-linear-gradient(
        0deg,
        #aaaaaa,
        #aaaaaa 9px,
        transparent 9px,
        transparent 19px,
        #aaaaaa 19px
      ),
      repeating-linear-gradient(
        90deg,
        #aaaaaa,
        #aaaaaa 9px,
        transparent 9px,
        transparent 19px,
        #aaaaaa 19px
      ),
      repeating-linear-gradient(
        180deg,
        #aaaaaa,
        #aaaaaa 9px,
        transparent 9px,
        transparent 19px,
        #aaaaaa 19px
      ),
      repeating-linear-gradient(
        270deg,
        #aaaaaa,
        #aaaaaa 9px,
        transparent 9px,
        transparent 19px,
        #aaaaaa 19px
      );
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  }
  .hovered {
    &:hover {
      background-color: #0c8ce950;
    }
  }
  .void-row {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    color: #00000080;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
}
