.drop-page-zone {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 8px;
  padding: 8px 0;
  .drag-row-zone {
    display: flex;
    width: 100%;
    height: auto;
  }
  .drop-kpi-zone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 6px;
    width: 100%;
    gap: 8px;
    .drag-kpi-page {
      display: flex;
      background-color: var(---listBg);
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      .kpi-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0;
      }
    }
  }
  .drop-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 6px;
    gap: 8px;

    .drag-module-zone {
      display: flex;
      width: 100%;
      max-width: 100%;
      height: auto;
      align-items: center;
    }
    .drag-app-zone {
      display: flex;
      width: 100%;
      height: 50px;
      min-height: 50px;
      max-height: 50px;
      justify-content: center;
      align-items: center;
      .Application-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        background-color: var(---listBg);
        border-radius: 5px;
        padding: 6px 0;
        border: 1px solid var(---cancelButton);
        .app-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 90%;

          .app-icon {
            display: flex;
            flex: 20%;
            width: 50px;
            max-width: 50px;
            height: 50px;
            max-height: 50px;
            justify-content: center;
            align-items: center;
            color: var(---iconAppItem);
          }
          .app-title {
            width: calc(100% - 50px);
            max-width: calc(100% - 50px);
            color: var(---AppText);
          }
        }
      }
      .selectedApp {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }
  }
  .drop-page-matrix {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 6px;
    gap: 8px;
    .drag-module-zone-matrix {
      display: flex;
      width: calc(50% - 4px);
      min-width: calc(50% - 4px);
      max-width: calc(50% - 4px);
      height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      min-height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      max-height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      align-items: center;
      justify-content: center;
    }
    .drag-app-zone-matrix {
      display: flex;
      width: calc(50% - 4px);
      min-width: calc(50% - 4px);
      max-width: calc(50% - 4px);
      height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      min-height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      max-height: calc((100vh - 10.5rem) * 0.9 * 0.47 * 0.3);
      align-items: center;
      justify-content: center;
      .Application-container-matrix {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        background-color: var(---listBg);
        border-radius: 5px;
        padding: 6px 12px;
        .app-icon {
          display: flex;
          flex: 20%;
          width: 100%;
          max-width: 100%;
          height: 80%;
          max-height: 80%;
          min-height: 80%;
          justify-content: center;
          align-items: center;
          color: var(---iconAppItem);
        }
        .app-title {
          max-width: 100%;
          height: 20%;
          max-height: 20%;
          min-height: 20%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
          font-size: 12px;
          color: var(---AppText);
        }
        .event-content-app {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          max-width: 10%;
          min-width: 10%;
          height: calc(((100vh - 12.5rem) * 0.47 * 0.935 * 0.17) - 12px);
          max-height: calc(((100vh - 12.5rem) * 0.47 * 0.935 * 0.17) - 12px);
          min-height: calc(((100vh - 12.5rem) * 0.47 * 0.935 * 0.17) - 12px);
          padding: 1px;
          .event-container {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #6f53a3;
          }
        }
        .hovered {
          &:hover {
            background-color: #3663d633;
          }
        }
      }
      .selectedApp {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }
  }
}
.drop-page-zone-edit {
  padding-bottom: 60px;
}

.drop-page-zone::-webkit-scrollbar {
  display: none;
}
