.splash-screen-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 36px 36px;
  .splash-content {
    display: flex;
    width: 80%;
    height: 38%;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
    align-items: center;
    margin-bottom: 20%;

    .btns-splash-content {
      display: flex;
      width: 80%;
      height: 14%;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
      border-radius: 29px;

      .btn-En {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0px;
        background-color: var(---languageContainerLeft);
        color: var(---submitLeftConnect);
        padding: 7px 7px 7px 15px;
        border-radius: 29px 0px 0px 29px;
        font-weight: 600;
      }
      .btn-Ar {
        width: 50%;
        font-weight: 600;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-width: 0px;
        background-color: var(---languageContainerRight);
        color: var(---submitRightConnect);
        padding: 7px 15px 7px 7px;
        border-radius: 0px 29px 29px 0px;
      }
    }
  }
}
