.settingModule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(---CardViewBgColor);
  border-radius: 9px;
  height: auto;
  .settingModuleTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(---primary);
    font-size: 16px;
    font-weight: 700;
    min-height: 40px;
    padding: 0 4%;
  }
  .settingModulkeBody {
    display: flex;
    flex-direction: column;
    width: 94%;
    min-height: 30px;
    padding: 0 2%;
    border-radius: 9px;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
    margin-bottom: 10px;
  }
}
