.MapView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .map-wrapper {
    height: 100% !important;
    max-height: 100% !important;
    
    background-color: antiquewhite;
    display: flex;
    contain: content;
    align-items: center;
    
  }
  .map-wrapper-edit{
    height: 100% !important;
    max-height: 100% !important;
    
    display: flex;
    contain: content;
    align-items: center;
    padding: 8px;
  }
  .pullup-container {
    z-index: 0;
    height: 30%;
    width: 100%;
  }
  .pullup-container-edit{
    z-index: 0;
    height: 30%;
    width: 100%;
    padding : 8px;
  }
}
