*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}
*:focus {
  outline: none;
}
html,
body {
  height: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

.monitor-container {
  display: flex;
  position: relative;

  // grid-template-columns: 18.6rem 1fr;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 3rem);
  max-height: calc(100vh - 3rem);
}
