.SyncContainer {
  align-items: center;
  margin: auto;
  display: block;
  flex-direction: column;
  max-width: 50%;
  min-width: 50%;
  max-height: 90%;
  overflow-y: scroll;
}
.SyncContainer::-webkit-scrollbar {
  display: none;
}
.DisconnectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: 80%;
  min-width: 80%;
  max-height: 80%;
  max-width: 80%;
  margin: auto;
  .Disconnect-action-card {
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    min-height: 100%;
    max-height: 100%;
    width: 50%;
    padding: 40px;
    background-color: var(--CardBg);
    border: 1px solid var(--BorderButtons);
    display: flex;
  }
  .Disconnect-step-card {
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: start;
    display: flex;
    flex-direction: column;
  }
}
