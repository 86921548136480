.DetailScreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: #f2f0f6;
  border-radius: 0 0 36px 36px;
  .headerDetail {
    display: flex;
    flex-direction: row;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #eeeeee;
    background-color: white;
    z-index: 1;
    .goBack {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 100%;

      .chevron-left-Details {
        color: var(---primary);
        cursor: pointer;
      }
    }
    .drop-tabsList-zone {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: calc(100% - 32px);
      max-width: calc(100% - 32px);
      min-width: calc(100% - 32px);
      font-size: 12px;
      .tabsList {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        align-items: center;
        overflow-x: auto;
        gap: 20px;
        background-color: white;
        // color: var(---primary);

        .tabsList-empty {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
        .tabButtom {
          display: flex;
          height: 100%;
          // min-width: 0px;
          // max-width: 80px;
          width: auto;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
          .tabButtom-label {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .tabButtom-edit {
          display: flex;
          height: 100%;
          min-width: 30px;
          align-items: center;
          justify-content: center;
          font-size: small;
          font-weight: 300;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tabsList::-webkit-scrollbar {
        display: none;
      }
    }

    .hovered {
      &:hover {
        background-color: #3663d633;
      }
    }
  }
  .tab-content {
    display: flex;
    width: 100%;
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    min-height: calc(100% - 40px);
    border-radius: 0 0 36px 36px;
    background-color: white;

    .drop-empty-list-zone {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 6px;

      .empty-list {
        display: flex;
        width: 90%;
        height: 50px;
        border-radius: 9px;
        border: 1px solid #aaaaaa;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
