.headerClassification {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  .event-classification {
    display: flex;
    height: 70%;
    width: 70%;
    justify-content: center;
    align-items: center;
    &:hover {
      padding: 4%;
    }
  }
  .empty-header {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 400;
    text-align: center;
  }
  // &:hover {
  //   background-color: #0c8ce930;
  // }
}
