.ViewContainer {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  height: calc(100vh - 6rem) !important;
  max-height: calc(100vh - 6rem) !important;
  background-color: var(--LoginBG);
  .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 3.5rem;
    min-height: 3.5rem;
    max-height: 3.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    .OptionsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      height: 100%;
      .languageBtn {
        display: flex;
        cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;

        .LanguageIcon {
          color: var(--Globe);
        }
      }
      .LanguageDropDown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 281px;
        height: auto;
        top: 1.5rem;
        border-radius: 2px;
        border: 1px solid var(--BorderDashed);
        box-shadow: 0 4px 22px rgba(2, 2, 2, 0.25);
        margin-top: 1.8rem;
        z-index: 9999;
        background-color: var(--header);
        &::after {
          content: "";
          position: absolute;
          top: -11px;
          left: 2px;
          width: 0;
          height: 0;
          border-left: 11.3px solid transparent;
          border-right: 11.3px solid transparent;
          border-bottom: 11.3px solid var(--borderBottom);
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          top: -10px;
          left: 3px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid var(--header);
          z-index: 3;
        }
        .LanguageDropdownHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--TitleContainer);
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid var(--borderBottom);
          font-weight: 500;
          padding: 11px 16px;
          .closeDropDown {
            cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
            color: #aaaaaa;
          }
        }
        .langItems {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 15px;
          gap: 10px;

          .lang-item {
            display: flex;
            align-items: center;
            height: 41px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            background-color: var(--CardBg);
            padding: 10px 15px 10px 15px;
            color: var(--text);
            cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
            border: 1px solid var(--BorderDashed);
            border-radius: 2px;
          }
          .active-lang {
            background-color: var(--SupportButtonBg);
            color: white;
          }
        }
      }
      .LanguageDropDown-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 281px;
        height: auto;
        top: 1.5rem;
        border-radius: 2px;
        border: 1px solid var(--BorderDashed);
        box-shadow: 0px 9px 28px 8px #0000000d;
        box-shadow: 0px 6px 16px 0px #00000014;
        box-shadow: 0px 3px 6px -4px #0000001f;
        margin-top: 1.8rem;
        z-index: 9999;
        background-color: var(--header);
        &::after {
          content: "";
          position: absolute;
          top: -11px;
          right: 2px;
          width: 0;
          height: 0;
          border-left: 11.3px solid transparent;
          border-right: 11.3px solid transparent;
          border-bottom: 11.3px solid var(--borderBottom);
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          top: -10px;
          right: 3px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid var(--header);
          z-index: 3;
        }
        .LanguageDropdownHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--TitleContainer);
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid var(--borderBottom);
          font-weight: 500;
          padding: 11px 16px;
          .closeDropDown {
            cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
            color: #aaaaaa;
          }
        }
        .langItems {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 15px;
          gap: 10px;

          .lang-item {
            display: flex;
            align-items: center;
            height: 41px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            background-color: var(--CardBg);
            padding: 10px 15px 10px 15px;
            color: var(--text);
            cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
            border: 1px solid var(--BorderDashed);
            border-radius: 2px;
          }
          .active-lang {
            background-color: var(--SupportButtonBg);
            color: white;
          }
        }
      }
      .theme-btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
        .ThemeButton {
          color: var(--Globe);
        }
        .ThemeDropdown {
          display: flex;
          top: 3.4rem;
          flex-direction: column;
          position: absolute;
          width: auto;
          border-radius: 3px;
          border: 1px solid var(--BorderDashed);
          z-index: 3333;
          background-color: var(--header);
          box-shadow: 0 4px 22px rgba(2, 2, 2, 0.25);

          &::after {
            content: "";
            position: absolute;
            top: -11px;
            left: 2px;
            width: 0;
            height: 0;
            border-left: 11.3px solid transparent;
            border-right: 11.3px solid transparent;
            border-bottom: 11.3px solid var(--borderBottom);
            z-index: 2;
          }

          &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 3px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid var(--header);
            z-index: 3;
          }
          .DropdownHeader {
            display: flex;
            color: var(--TitleContainer);
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--borderBottom);
            font-weight: 500;
            padding: 11px 16px;
            .closeDropDown {
              cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
              color: #aaaaaa;
            }
          }
          .DropDownItems {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 16px;

            .themeColors {
              display: flex;
              flex-direction: row;
              height: 70px;
              box-shadow: 0 1px 1px rgba(2, 2, 2, 0.15);
              background-color: #fff;
              border-radius: 3px;
              cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;

              .Color1 {
                width: 50px;
                border-radius: 1px 0 0 1px;
              }
              .Color2 {
                width: 30px;
              }
              .Color3 {
                width: 20px;
              }
              .Color4 {
                width: 30px;
              }
              .Color5 {
                width: 20px;
              }

              .ColorName {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                background-color: var(--CardBg);
                color: var(--text);
                width: 110px;
              }
            }
          }
        }
        .ThemeDropdown-left {
          display: flex;
          top: 3.4rem;
          flex-direction: column;
          position: absolute;
          width: auto;
          border-radius: 3px;
          border: 1px solid var(--BorderDashed);
          z-index: 3333;
          background-color: var(--header);
          box-shadow: 0 4px 22px rgba(2, 2, 2, 0.25);

          &::after {
            content: "";
            position: absolute;
            top: -11px;
            right: 2px;
            width: 0;
            height: 0;
            border-left: 11.3px solid transparent;
            border-right: 11.3px solid transparent;
            border-bottom: 11.3px solid var(--borderBottom);
            z-index: 2;
          }

          &::before {
            content: "";
            position: absolute;
            top: -10px;
            right: 3px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid var(--header);
            z-index: 3;
          }
          .DropdownHeader {
            display: flex;
            color: var(--TitleContainer);
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--borderBottom);
            font-weight: 500;
            padding: 11px 16px;
            .closeDropDown {
              cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
              color: #aaaaaa;
            }
          }
          .DropDownItems {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 16px;

            .themeColors {
              display: flex;
              flex-direction: row;
              height: 70px;
              box-shadow: 0 1px 1px rgba(2, 2, 2, 0.15);
              background-color: #fff;
              border-radius: 3px;
              cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;

              .Color1 {
                width: 50px;
                border-radius: 1px 0 0 1px;
              }
              .Color2 {
                width: 30px;
              }
              .Color3 {
                width: 20px;
              }
              .Color4 {
                width: 30px;
              }
              .Color5 {
                width: 20px;
              }

              .ColorName {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                background-color: var(--CardBg);
                color: var(--text);
                width: 110px;
              }
            }
          }
        }
      }
    }
    .tabContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      .tabTitle {
        font-size: 16px;
        font-weight: 400;
        font-family: "Hanken Grotesk", sans-serif;
        text-transform: none;
        white-space: nowrap;
        padding-right: 12px;
        text-decoration: none;
        cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
      }
      .selectedTab {
        font-size: 16px;
        font-weight: 500;
        /*   text-decoration: underline;
        text-underline-offset: 8px; */
      }
    }
  }
  .bodyContainer {
    overflow-y: hidden;
    overflow-x: hidden;
    height: calc(100vh - 9.5rem) !important;
    max-height: calc(100vh - 9.5rem) !important;
  }

  .separator1 {
    display: flex;
    width: 3px;
    min-width: 3px;
    border-radius: 50px;
    margin: 0 10px;
    background-color: var(--separator);
    height: 24px;
    min-height: 24px;
  }
}
