.drop-kpi-content {
  display: flex;
  height: 50px;
  width: calc((100vh - 10.5rem) * 0.47 * 0.863);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  .drop-kpi-known {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #00000080;
  }
}
