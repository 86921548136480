.installation-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 3%;
  border-bottom: 3px solid var(--BorderStroke);
}

.installation-progress-bar {
  display: flex;
  background-color: var(--ProgressionBar);
  border-radius: 4px;
  height: 13px;
  width: 100%;
  .installation-progress {
    background-color: var(--SupportButtonBg);
    border-radius: 4px;
    border-right: 1px solid #4c36a5;
    box-shadow: 5px 0px 4px -2px rgba(72, 68, 82, 0.53);
  }
}

.percentageStyles {
  justify-content: end;
  margin-left: 1%;
  display: flex;
  flex-direction: end;
  color: var(--Globe);
  font-size: 16px;
  margin-left: 10px;
}
