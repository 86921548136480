.utility-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1.5rem;
  min-height: 1.5rem;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.75);
  .utility-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    height: 1.5rem;
    max-height: 1.5rem;
    min-height: 1.5rem;
    padding-right: 0.75rem;
    width: 100%;
    background-color: var(--TitleContainer);
    gap: 0.75rem;
    padding: 0.15rem 12px;
    .expend-icon {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: var(--LoginBG);
    }
    .maximize-icon {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: var(--LoginBG);
    }
    .restBar {
      display: flex;
      height: 100%;
      width: 100%;
      color: white;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .utility-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0rem;
    max-height: 0rem;
    min-height: 0rem;
    .utility-tabs {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 2.8rem;
      max-height: 2.8rem;
      min-height: 2.8rem;
      justify-content: flex-start;
      align-items: center;
      font-weight: 400;
      background-color: #f7f7f7;
      color: #b9b8bd;
      .tabTitle {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        cursor: pointer;
      }
      .activeTab {
        color: var(--TitleContainer);
        font-weight: 700;
        text-decoration-line: underline;
      }

      .utility-tabs-empty {
        border-bottom: 1px solid #ececec;
        flex-grow: 1;
        align-self: end;
      }
    }
    .utab-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 0%;
      height: 0rem;
    }
    .expend-tab {
      height: calc(((100vh - 17rem) * 0.65) - 2.8rem);
      max-height: calc(((100vh - 17rem) * 0.65) - 2.8rem);
      min-height: calc(((100vh - 17rem) * 0.65) - 2.8rem);
    }
    .maximize-tab {
      height: calc((100vh - 7.5rem) - 4.3rem);
      max-height: calc((100vh - 7.5rem) - 4.3rem);
      min-height: calc((100vh - 7.5rem) - 4.3rem);
    }
  }
  .expend-body {
    height: calc((100vh - 19rem) * 0.65);
    max-height: calc((100vh - 19rem) * 0.65);
    min-height: calc((100vh - 19rem) * 0.65);
  }
  .maximize-body {
    height: calc((100vh - 7.5rem) - 1.5rem);
    max-height: calc((100vh - 7.5rem) - 1.5rem);
    min-height: calc((100vh - 7.5rem) - 1.5rem);
  }
}
.expend-utility {
  height: calc(((100vh - 16rem) * 0.65) + 1.5rem);
  max-height: calc(((100vh - 16rem) * 0.65) + 1.5rem);
  min-height: calc(((100vh - 16rem) * 0.65) + 1.5rem);
}
.maximize-utility {
  margin-top: 0.5rem;
  height: calc(100vh - 7.5rem);
  max-height: calc(100vh - 7.5rem);
  min-height: calc(100vh - 7.5rem);
}
