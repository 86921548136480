.toast-container {
  color: var(--text);
  background-color: var(--CardBg);

  &.toast-success {
  }

  &.toast-error {
  }

  &.toast-warn {
  }

  &.toast-info {
  }
}
