.floating-revision {
  position: fixed;
  left: 0;
  top: 7rem;
  z-index: 9;

  .revisionList {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 6rem;
    left: 0;
    width: 20rem;
    height: calc(100vh - 6rem);
    background-color: white;

    // transition: transform 0.55s ease-in-out;
    transform: translateX(-100%); /* Inversed translation */
    .revisionTypesBtn {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .revisionList-empty {
    display: flex;
    // justify-content: center;
    align-items: center;
   .emptyList{
  justify-content: center;
  align-items: center;
height: 100%;
margin-top: 50%;}
  }

  &.show-revisionList {
    .revisionBtn {
      left: 20rem;
    }

    .revisionList {
      transform: translateX(0);
    }
  }
}
