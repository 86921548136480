.SubItem {
  .object-item {
    margin-left: 10px;
    position: relative;
    height: 90%;
    &.level-selected::before {
      content: "";
      position: absolute;
      left: 10px; /* Adjust the value as needed */
      top: 75%; /* Position the line slightly below the center */
      transform: translateY(-50%);
      height: 100%;
      width: 1px;
      background-color: #39354a; // Adjust the color as needed
    }
  }
  .sub-drop-down {
    display: flex;
    .sub-collapse-btn {
      display: flex;
      align-items: center;
      width: 100%;
      background: transparent;
      border: none;
      padding-top: 10px;
      padding-bottom: 15px;
      border: none;
      padding-right: 10px;
      padding-left: 10px;
      text-align: left;
      .object-button {
        display: flex;
        align-items: center;
        .object-name {
          font-size: 16px;
          font-weight: 400;
          font-family: "Hanken Grotesk", sans-serif;
          text-transform: capitalize;
        }
        .sub-item-label {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 3px 7px 3px 7px;
          margin-right: 8px;
          margin-left: 8px;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 500;
          font-family: "Hanken Grotesk", sans-serif;
          text-transform: lowercase;
          color: white;
        }
      }
    }
    .add-new {
      background: transparent;
      padding: 5px 10px 2px 10px;
      border: none;
      float: right;
    }
    .delete-item {
      background: transparent;
      border: none;
      padding: 5px 10px 2px 10px;
      float: right;
    }
    .add-left-border {
      height: 18px;
      width: 1px;
      margin-top: 18px;
      background-color: #6f53a350;
    }
    .selected {
      font-weight: 600;
      color: #6f53a3;
    }
  }
  .sub-selected-drop-down {
    background-color: #eee6ff;
    border-radius: 6px;
    display: flex;
    .sub-collapse-btn {
      display: flex;
      align-items: center;
      width: 100%;
      background: transparent;
      border: none;
      padding-top: 10px;
      padding-bottom: 15px;
      border: none;
      padding-right: 10px;
      padding-left: 10px;
      text-align: left;
      .object-button {
        display: flex;
        align-items: center;
        .object-name {
          font-size: 16px;
          font-weight: 400;
          font-family: "Hanken Grotesk", sans-serif;

          text-transform: capitalize;
        }
        .sub-item-label {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 3px 7px 3px 7px;
          margin-right: 8px;
          margin-left: 8px;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 500;
          font-family: "Hanken Grotesk", sans-serif;
          text-transform: lowercase;
          color: white;
        }
      }
    }
    .add-new {
      background: transparent;
      padding: 5px 10px 2px 10px;
      border: none;
      float: right;
    }
    .delete-item {
      background: transparent;
      border: none;
      padding: 5px 10px 2px 10px;
      float: right;
    }
    .add-left-border {
      height: 18px;
      width: 1px;
      margin-top: 18px;
      background-color: #6f53a350;
    }
    .selected {
      font-weight: 600;
      color: #6f53a3;
    }
  }
}
