.table-header {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-header tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-header th {
  display: table-cell;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-right: 1px solid var(--monitorBorder);
  line-height: 1;
}

tbody {
  display: table-row-group;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tbody td {
  display: table-cell;
  padding: 0.938rem 1rem;
  border-right: 1px solid var(--monitorBorder);
  border-bottom: 1px solid var(--monitorBorder);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-width-row {
  padding: 0;
}

.full-width-row td {
  display: block;
  padding: 0;
  border: none;
}

.full-width-row .react-json-view {
  padding: 0.938rem 1rem;
  border: 1px solid var(--monitorBorder);
  background: var(--TableEven);
  overflow-y: auto;
  height: calc(100vh - 17rem);
  max-height: 490px;
}
