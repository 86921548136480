.inspection-modal-old-container {
  height: 90%;
  width: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;

  .inspection-old-left-open {
    width: 50%;
    background-color: #fff;
    .one-tab-left-container-start {
      height: 10%;
      width: 100%;
      background-color: #fd6;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;
      justify-content: space-evenly;
      display: flex;
    }
    .inpection-tab-container-list {
      height: 90%;
      overflow-y: auto;
      .question-tab-inspection-open {
        width: 100%;
        min-height: 50px;
        padding: 5px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #d9d9d9;
        background-color: #fff;
        .bull-num-q {
          padding: 8px;
          width: 30px;
          color: #fff;

          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 500px;
          background-color: #569d59;
        }
        .number-container-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-color: #569d59;
          border-radius: 500px;
          padding: 5px;

          .num-bull {
            height: 100%;
            width: 50%;
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .number-container {
          display: flex;
          height: 100%;
          justify-content: space-around;
          flex-direction: row;
          align-items: center;
          .number-container-txt {
            width: 25%;
            height: 90%;
            display: flex;
            justify-content: center;
            border-radius: 500px;
            align-items: center;
            background-color: #569d59;
          }

          .num-bull {
            height: 100%;
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .question-tab-inspection {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        border-bottom: 1px solid #d9d9d9;
        background-color: #fff;
        .bull-num-q {
          padding: 8px;
          width: 30px;
          color: #fff;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 500px;
          background-color: #569d59;
        }
        .number-container-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-color: #569d59;
          border-radius: 500px;
          padding: 5px;

          .num-bull {
            height: 100%;
            width: 50%;
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .number-container {
          display: flex;
          height: 100%;
          justify-content: space-around;
          flex-direction: row;
          align-items: center;
          .number-container-txt {
            width: 25%;
            height: 90%;
            display: flex;
            justify-content: center;
            border-radius: 500px;
            align-items: center;
            background-color: #569d59;
          }

          .num-bull {
            height: 100%;
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .inspection-content-left-open {
        width: 50%;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .home-tab-inspection {
          width: 100%;
          min-height: 50px;
          border-bottom: 1px solid #d9d9d9;
          display: flex;
          justify-content: space-evenly;

          align-items: center;
          background: #e6d6f9;
          flex-direction: row;
        }
        .home-tab-inspection-clicked {
          width: 100%;
          min-height: 50px;

          border-bottom: 1px solid #d9d9d9;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          background-color: #6a549e;
          flex-direction: row;
        }
        .question-tab-inspection {
          width: 100%;
          padding: 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          border-bottom: 1px solid #d9d9d9;
          background-color: #fff;
          .bull-num-q {
            padding: 8px;
            width: 30px;
            color: #fff;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 500px;
            background-color: #569d59;
          }
          .number-container-large {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: #569d59;
            border-radius: 500px;
            padding: 5px;

            .num-bull {
              height: 100%;
              width: 50%;
              min-width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .number-container {
            display: flex;
            height: 100%;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            .number-container-txt {
              width: 25%;
              height: 90%;
              display: flex;
              justify-content: center;
              border-radius: 500px;
              align-items: center;
              background-color: #569d59;
            }

            .num-bull {
              height: 100%;
              width: 70%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .question-tab-inspection-open {
          width: 100%;
          min-height: 50px;
          padding: 5px;
          display: flex;
          justify-content: center;
          border-bottom: 1px solid #d9d9d9;
          background-color: #fff;
          .bull-num-q {
            padding: 8px;
            width: 30px;
            color: #fff;

            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 500px;
            background-color: #569d59;
          }
          .number-container-large {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: #569d59;
            border-radius: 500px;
            padding: 5px;

            .num-bull {
              height: 100%;
              width: 50%;
              min-width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .number-container {
            display: flex;
            height: 100%;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            .number-container-txt {
              width: 25%;
              height: 90%;
              display: flex;
              justify-content: center;
              border-radius: 500px;
              align-items: center;
              background-color: #569d59;
            }

            .num-bull {
              height: 100%;
              width: 70%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .question-tab-inspection {
          width: 100%;
          padding: 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          border-bottom: 1px solid #d9d9d9;
          background-color: #fff;
          .bull-num-q {
            padding: 8px;
            width: 30px;
            color: #fff;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 500px;
            background-color: #569d59;
          }
          .number-container-large {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: #569d59;
            border-radius: 500px;
            padding: 5px;

            .num-bull {
              height: 100%;
              width: 50%;
              min-width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .number-container {
            display: flex;
            height: 100%;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            .number-container-txt {
              width: 25%;
              height: 90%;
              display: flex;
              justify-content: center;
              border-radius: 500px;
              align-items: center;
              background-color: #569d59;
            }

            .num-bull {
              height: 100%;
              width: 70%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .one-tab-left-container {
        min-height: 60px;
        width: 100%;
        background-color: aquamarine;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
  .inspection-old-right-open {
    width: 50%;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 0px 0px 35px 0px;

    .inspection-right-header {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .text-header-container {
        .text-header {
          font-size: 12px;
          color: #6a549e;
        }
      }
      .close-container {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #6a549e;
        border-radius: 500px;
      }
    }
  }
}

.inspection-modal-old-container {
  height: 90%;
  width: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  .inspection-old-left {
    width: 30%;
    background-color: antiquewhite;
    .one-tab-left-container-start {
      height: 10%;
      width: 100%;
      background-color: #fd6;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;
      justify-content: space-evenly;
      display: flex;
    }
    .inpection-tab-container-list {
      height: 90%;
      overflow-y: auto;
      .question-tab-inspection {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        border-bottom: 1px solid #d9d9d9;
        background-color: #fff;
        .bull-num-q {
          padding: 8px;
          width: 30px;
          color: #fff;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 500px;
          background-color: #569d59;
        }
        .number-container-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-color: #569d59;
          border-radius: 500px;
          padding: 5px;

          .num-bull {
            height: 100%;
            width: 50%;
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .number-container {
          display: flex;
          height: 100%;
          justify-content: space-around;
          flex-direction: row;
          align-items: center;
          .number-container-txt {
            width: 25%;
            height: 90%;
            display: flex;
            justify-content: center;
            border-radius: 500px;
            align-items: center;
            background-color: #569d59;
          }

          .num-bull {
            height: 100%;
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .question-tab-inspection-open {
        width: 100%;
        min-height: 50px;
        padding: 5px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #d9d9d9;
        background-color: #fff;
        .bull-num-q {
          padding: 8px;
          width: 30px;
          color: #fff;

          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 500px;
          background-color: #569d59;
        }
        .number-container-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-color: #569d59;
          border-radius: 500px;
          padding: 5px;

          .num-bull {
            height: 100%;
            width: 50%;
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .number-container {
          display: flex;
          height: 100%;
          justify-content: space-around;
          flex-direction: row;
          align-items: center;
          .number-container-txt {
            width: 25%;
            height: 90%;
            display: flex;
            justify-content: center;
            border-radius: 500px;
            align-items: center;
            background-color: #569d59;
          }

          .num-bull {
            height: 100%;
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .one-tab-left-container {
        min-height: 60px;
        width: 100%;
        background-color: aquamarine;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
  .inspection-old-right {
    width: 70%;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 0px 0px 35px 0px;

    .inspection-right-header {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .text-header-container {
        .text-header {
          font-size: 12px;
          color: #6a549e;
        }
      }
      .close-container {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #6a549e;
        border-radius: 500px;
      }
    }
  }
}

.home-settings-container {
  width: 100%;
  height: 90%;
  padding: 2% 4%;
  overflow-y: auto;
  .buttons-container {
    display: flex;
    flex-direction: column;
    .btn {
      display: flex;
      justify-content: flex-start;
      border-radius: 9px;
      border: 1px solid #eee;
      font-size: 12px;
      background: #fff;
      margin-top: 3px;
      padding: 3px 3px;
    }
  }
  .opt-qte-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .total-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .requires-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .name-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .name-value-txt {
      color: #000;
      font-family: Hanken Grotesk;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  .created-date-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .assets-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
.foot {
  bottom: 0px;
  background-color: #eee;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  .btn-start {
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 1px solid #aaa;
    box-shadow: 0px 0px 10px 0px rgba(131, 86, 245, 0.25);
  }
}

.inspection-content-left-open {
  width: 50%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .home-tab-inspection {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-evenly;

    align-items: center;
    background: #e6d6f9;
    flex-direction: row;
  }
  .home-tab-inspection-clicked {
    width: 100%;
    min-height: 50px;

    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #6a549e;
    flex-direction: row;
  }
  .question-tab-inspection {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    .bull-num-q {
      padding: 8px;
      width: 30px;
      color: #fff;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      background-color: #569d59;
    }
    .number-container-large {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #569d59;
      border-radius: 500px;
      padding: 5px;

      .num-bull {
        height: 100%;
        width: 50%;
        min-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .number-container {
      display: flex;
      height: 100%;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      .number-container-txt {
        width: 25%;
        height: 90%;
        display: flex;
        justify-content: center;
        border-radius: 500px;
        align-items: center;
        background-color: #569d59;
      }

      .num-bull {
        height: 100%;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

/*******************************************/

.body-content {
  height: auto;
  width: 100%;
  padding: 3px;
  min-height: 45px;

  .name-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: #6a549e;
    font-weight: 700;

    .name-value-txt {
      color: #000;
      font-family: Hanken Grotesk;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .created-date-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .assets-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
