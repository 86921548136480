.inspection-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  // background-color: aquamarine;

  .close-modal {
    display: flex;
    width: 100%;
    height: 8%;
    // background-color: antiquewhite;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    width: 99.8%;
    height: 92%;
    max-height: 92%;
    min-height: 92%;
    background-color: #fff;

    border-radius: 9px 9px 50px 50px;
    justify-content: flex-start;
    align-items: center;
    .modal-inspection-header {
      min-height: 8%;
      height: 8%;
      width: 100%;
      background-color: #fff;
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      .title-txt {
        overflow: hidden;
        color: #222;
        text-overflow: ellipsis;
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
