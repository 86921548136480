.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
  .message {
    font-size: 16px;
    font-weight: 400;
  }
  .lineTwo {
    font-size: 16px;
    font-weight: 500;
  }
}

.DeleteBtn {
  .DeleteBtn-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    height: 2.5rem;
    padding: 0.3rem;
  }
  .disable {
    color: #ccc;
  }
  .DeleteBtn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
