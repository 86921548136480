.input-group-Advanced-search {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  label {
    align-self: start;
    font-family: Hanken Grotesk;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.24px;
    color: #39354a;
  }

  input {
    padding: 8px 10px 8px 10px;
    border-radius: 2px 0px 0px 0px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
  }
}
