.notification-badge-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  width: calc((100vh - 10.5rem) * 0.47 * 0.37);
  min-width: calc((100vh - 10.5rem) * 0.47 * 0.37);
  max-width: calc((100vh - 10.5rem) * 0.47 * 0.37);
  background-color: var(---listBg);
  border-radius: 5px;
  box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.1);
  margin-right: 2.5%;
  padding: 6px;
  color: var(---textColor);
  // background: linear-gradient(
  //   var(---notificationUpColor) 50%,
  //   var(---notificationUpColor) 50%
  // );
  .notif-no-message {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
