.login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  align-items: center;
  .form-card {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 600px;
    max-width: 600px;
    min-width: 600px;
    height: auto;
    background-color: var(--CardBg);
    border: 1px solid var(--LoginBoxBorder);
    padding: 25px;
    margin-right: calc((100vw - 600px) * 0.75);
    margin-left: calc((100vw - 600px) * 0.25);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 33px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 61px 61px 0 rgba(0, 0, 0, 0.09);
    z-index: 2;
    gap: 50px;
    &.rtl-card {
      margin-right: calc((100vw - 600px) * 0.35);
      margin-left: calc((100vw - 600px) * 0.65);
    }
    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 150px;
      .login-logo {
        display: flex;
        height: 150px;
        width: 300px;
        justify-content: center;
        align-items: center;
        .logo {
          display: flex;
          height: 100%;
          width: 100%;
          color: var(--SupportButtonBg);
        }
      }
      .void {
        display: flex;
        width: 150px;
        height: 100%;
      }
      .LanguageBtn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        width: 150px;
        gap: 0.5rem;
        cursor: pointer;
        .LanguageIcon {
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: center;
          height: 1.5rem;
          max-height: 1.5rem;
          min-height: 1.5rem;
          .languageGlobe {
            color: var(--SupportButtonBg);
          }
        }
        .LanguageLabel {
          flex-direction: column;
          height: 100%;
          align-items: center;
          height: 1.5rem;
          max-height: 1.5rem;
          font-weight: bold;
          min-height: 1.5rem;
          color: var(--SupportButtonBg);
        }
        .language-dropdown {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 146px;
          height: auto;
          border-radius: 2px;
          border: 1px solid var(--langBoxBorder);
          box-shadow: 0px 9px 28px 8px #0000000d;
          box-shadow: 0px 6px 16px 0px #00000014;
          box-shadow: 0px 3px 6px -4px #0000001f;
          margin-top: 1.8rem;
          z-index: 2;
          .lang-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 32px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 12px;
            color: var(--text);
          }
          .active-lang {
            background-color: var(--SupportButtonBg);
            color: white;
          }
        }
      }
    }
    .card-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      gap: 1.25rem;
      .serverErrorMessage {
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 14px;
        color: #df4759;
      }
    }
    .submitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 500;
      background-color: var(--LoginButton);
      color: #ffff;
      cursor: pointer;
      width: 100%;
      height: 40px;
      &:active {
        background-color: #7d6dbe;
      }
    }
    .card-footer {
    }
  }
}
