.question-inspec-old-container {
  height: 100%;
  width: auto;

  .question-inspec-old-header {
    height: 10%;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    .btn-x-container {
      height: 20px;
      width: 20px;
      background-color: #6a549e;
      border-radius: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .question-inspec-old-content {
    overflow-y: auto;
    background-color: #fff;
    width: auto;
    height: 75%;
    .question-old-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 15px;
      padding: 0px 5px 0px 5px;
      .text-question {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .input-check-container {
      margin-top: 15px;
      padding: 0px 5px 0px 5px;
      .input-check {
        /* Hide the default radio button */
        display: none;
      }

      .custom-label {
        /* Style for the unchecked state */
        background-color: #fff; /* Default color */
        border-radius: 50%; /* Ensures a circular shape */
        display: inline-block;
        width: 20px; /* Adjust the size as needed */
        height: 20px; /* Adjust the size as needed */
        border-radius: 500px;
        border: 2px solid #222;
      }

      .input-check:checked + .custom-label {
        /* Style for the checked state */
        background-color: #6a549e; /* Change this color to your desired color */
        border-radius: 500px;
        border: 2px solid #222;
      }
    }
  }
  .question-inspec-old-feet {
    height: 15%;
    width: auto;

    .btn-old-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 5px 5px 5px;
      .btn-next-old {
        border-radius: 9px;
        background: #fff;
        box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
        border-width: 0px;
        padding: 5px 5px 5px 5px;
      }
      .btn-back-old {
        border-radius: 9px;
        background: #fff;
        box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
        border-width: 0px;
        padding: 5px 5px 5px 5px;
      }
    }
    .btn-old-complete-save-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 5px 0px 5px;
      .btn-complete {
        border-radius: 9px;
        opacity: 1;
        background: #6a549e;
        border-width: 0px;
        color: #fff;
      }
      .btn-save {
        border-width: 3px;
        border-radius: 5px;
        padding: 3px 3px 3px 3px;
        border-color: #6a549e;
        border-style: solid;
      }
    }
  }
}
