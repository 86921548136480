.SyncComponentConatiner {
  align-items: center;
  margin: auto;
  display: block;
  flex-direction: column;
  max-width: 55%;
  min-width: 55%; 
  max-height: 100%;
  .SyncHeader {
    background-color: var(--CardBg);
    border: 1px solid var(--BorderButtons);
    border-radius: 2px;
    min-height: 25%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
    margin-top: 7%;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    margin-bottom: 3%;

    .SyncHeader-title {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      min-width: 100%;
      padding-bottom: 7px;
      color: var(--ColorButton);
      font-family: "Hanken Grotesk";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-bottom: 1px solid var(--BorderDashed);
      margin-bottom: 1%;
    }
    .ipCopySection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      min-height: 40px;
      .ipSection {
        display: flex;
        justify-content: space-between;
        color: var(--DisconnectLink);
        font-family: "Hanken Grotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
  .SyncAutomationScriptsContent {
    border-radius: 2px;
    background-color: var(--CardBg);
    border: 1px solid var(--BorderButtons);
    min-height: 23%;
    padding: 20px 40px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    margin-bottom: 3%;
    ::-webkit-scrollbar {
      width: 5px;
      height: 402px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(217, 217, 217, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      width: 5px;
      height: 132px;
      border-radius: 81px;
      background: #d9d9d9;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .SyncObjStructure {
    background-color: var(--CardBg);
    border: 1px solid var(--BorderButtons);
    border-radius: 2px;
    min-height: 12%;
    margin-bottom: 3%;
    padding: 20px 40px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  .SyncFooter {
    display: flex;
    justify-content: end;
    margin-top: auto;
    margin-bottom: 7%;
    height: 80px;

    .Sync-disconnect-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: var(--ColorButton);
      border: 1px solid var(--disconnectBtnBorder);
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 16px;
      align-items: center;
      background-color: var(--CardBg);
      height: 45px;
    }
    .Sync-retry-button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border: 1px solid #4c36a5;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 16px;
      align-items: center;
      background-color: var(--SupportButtonBg);
      margin-right: 2%;
      height: 45px;
      font-weight: 500;
    }
  }
}
.popupContainer {
  min-width: 100%;
  .disconnectConfirm {
    display: flex;
    justify-content: center;
    color: var(--Globe);
    font-family: "Hanken Grotesk";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
  }
  .dialog-option {
    display: flex;
    height: 22px;
    margin-bottom: 10px;
    .dialog-content {
      padding-left: 12px;
      color: var(--Globe);
      font-family: "Hanken Grotesk";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
    }
  }
  .title-popup {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    font-family: "Hanken Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .uninstall-progress {
    padding: 10% 0%;
  }
}
.SyncComponentConatiner::-webkit-scrollbar {
  display: none;
}
