.componentButton {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .libItem {
    display: flex;
    height: 55px;
    width: 55px;
    justify-content: center;
    align-items: center;
    color: #9183a0;
    border-radius: 2px;
    .libItem.dragging {
      color: #3d0a74;
    }
    .hovered {
      .iconHovered {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding-bottom: 14px;
      }
      .addIconHovered {
        display: flex;
        margin-right: 41px;
        padding-bottom: 2px;
      }
    }
    .hovered.hover {
      background-color: #9183a0;
    }
  }
  .libItem.hover {
    background-color: #9183a0;
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
    max-width: 3.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon-container {
  }

  .icon-container svg {
  }
}
.componentButton:hover {
  opacity: 1;
}
