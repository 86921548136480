.footer-container {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  gap: 0.3rem;
  font-size: 12px;
  font-weight: 400;
  color: var(--footerText);
  .link {
    font-size: 12px;
    font-weight: 400;
    color: var(--footerText);
    font-family: "Hanken Grotesk", sans-serif;
  }
}
