.pull-up-container {
  height: 67px;
  max-height: 67px;

  width: 96.5%;
  display: flex;
  border-radius: 20px 20px 40px 40px;
  align-self: center;
  z-index: 3;
  position: absolute;
  bottom: calc((18vh - 10.5rem) * 0.9 * 0.82) !important;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  max-height: 10vh;

  align-items: center;
  align-self: flex-start;
  background-color: #fff;
  .horizontale-bar {
    position: relative;
    min-height: 3px;
    width: 33%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // align-self: center;
    height: 3px;
    background-color: #e2e2e2;
    margin-top: 5px;
  }
}
.pull-up-container-up {
  height: calc((60vh - 10.5rem) * 0.9 * 0.84) !important;
  max-height: calc((60vh - 10.5rem) * 0.9 * 0.84) !important;

  width: 96.5%;
  display: flex;
  border-radius: 20px 20px 40px 40px;
  align-self: center;
  z-index: 3;
  overflow: auto;

  background-color: #fff;

  flex-direction: column;

  position: absolute;
  bottom: calc((18vh - 10.5rem) * 0.9 * 0.82) !important;
  // background-color: #fff;

  border-radius: 9px 9px 50px 50px;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  .horizontale-bar {
    position: relative;

    width: 33%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // align-self: center;
    height: 3px;
    background-color: #e2e2e2;
  }
}

.pull-up-content {
  min-height: 3vh;
  height: auto;
  width: 96%;
  background-color: #3663d633;
  // border-radius: 50px 50px 50px 50px;
  padding: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  bottom: calc((18vh - 10.5rem) * 0.9 * 0.82) !important;
}

.pull-up-content::-webkit-scrollbar {
  display: none;
}

.pull-up-container::-webkit-scrollbar {
  display: none;
}
.pull-up-container-up::-webkit-scrollbar {
  display: none;
}
