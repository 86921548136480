.conModal-container {
  display: flex;
  flex-direction: column;
  background-color: var(--header);
  align-items: center;
  justify-content: space-between;
  width: 700px;
  max-width: 700px;
  min-width: 700px;
  max-height: 720px;
  min-height: 600px;
  border-radius: 2px;
  .conModal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-height: calc(720px - 4rem);
    min-height: calc(600px - 4rem);
    .conModal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 3.25rem;
      max-height: 3.25rem;
      min-height: 3.25rem;
      border-bottom: 1px solid #ececec;
      padding: 11px 16px;
      .modalTitle {
        color: var(--SupportButtonBg);
        font-weight: 500;
        font-size: 16px;
      }
      .headerActions {
        .closeButton {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .condition-content {
      display: grid;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 1rem;
      gap: 2%;
      row-gap: 1rem;
      padding: 1rem 0.75rem;
    }
    .break {
      width: 96%;
      height: 1px;
      min-height: 1px;
      background-color: #919191;
    }
    .properties-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      overflow: hidden;
      padding: 0rem 1rem;
      .HeaderProperties {
        display: grid;
        grid-template-columns: 20% 78%;
        gap: 2%;
        height: 3rem;
        min-height: 3rem;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
      }
      .conProperties {
        display: grid;
        gap: 2%;
        overflow-y: auto;
        overflow-x: hidden;
        row-gap: 1rem;
      }
    }
  }
  .conModal-readOnly {
    max-height: 720px;
    min-height: 600px;
  }
  .modalFooter {
    display: grid;
    grid-template-columns: 48.5% 48.5%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    max-height: 4rem;
    min-height: 4rem;
    padding: 0 0.75rem;
  }
}
