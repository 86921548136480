.IMXLogger-main-left {
  height: 500px;
  width: 43%;
  padding: 0 1rem;
  .IMXLogger-main-title {
    font-family: Hanken Grotesk;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--LoggerTitleColor);
    padding: 0.375rem 0;
  }

  .IMXLogger-Action-container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 0.625rem;

    .IMXLogger-Action-flex-1,
    .IMXLogger-Action-flex-2 {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      padding: 0.313rem 0.625rem;
      border-radius: 2px;
      border: 1px solid var(--monitorInputBorder);
      background-color: var(--monitorInput);
    }
  }
}
