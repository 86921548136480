.revisionItem {
  display: flex;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  border: 2px solid transparent;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  cursor: pointer;

  .revision-content {
    display: flex;
    width: 92%;
    flex-direction: column;
    .revision-name {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--Title);
    }
    .release-datetime {
      font-size: 12px;
      font-weight: 400;
      color: var(--dateColor);
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;
    .moreOptions-btn {
      color: #394a35;
      border-radius: 0px;
      width: 20px;
    }
  }
}

.revisionItem-selected {
  display: flex;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  border: 2px solid var(--TitleContainer);
  color: var(--text);
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  cursor: pointer;
  .revision-content {
    display: flex;
    width: 92%;
    flex-direction: column;
    .revision-name {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .release-datetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;
    .moreOptions-btn {
      color: var(--text);
      border-radius: 0px;
      width: 20px;
    }
  }
}
.revisionItem-selected.enable {
  background-color: var(--primary);
  color: var(--DraftActiveText);
}
.revisionItem.enable {
  .revision-name {
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--SearchText);
  }
  .release-datetime {
    font-size: 12px;
    font-weight: 400;
    color: var(--dateColorDisabled);
  }
}
.revisionItem-edit {
  display: flex;
  padding: 0.5rem 0.5625rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  margin-bottom: 10px;
  background-color: #4c36a5;
  color: white;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  cursor: pointer;
  .revisionContent {
    display: flex;
    width: 92%;
    flex-direction: column;
    .revisionName {
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .releaseDatetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;

    .moreOptions-btn {
      color: white;
      border-radius: 0px;
      width: 20px;
    }
  }
}

.DeleteContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
  gap: 8px;
  .DeleteMsg {
    font-size: 18px;
    font-weight: 400;
    // color: red;
  }
  .deleteFileName {
    font-size: 18px;
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // color: yellow;
    
  }
  .deleteFileDate {
    font-size: 14px;
    font-weight: 400;
    color: var(--text);
    // color: blue;
  }
}

.renameContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
  .renameMsg {
    font-size: 16px;
    font-weight: 400;
  }
}
