.data-collection-container {
  display: flex;
  height: 100vh;
  overflow: auto;
}

.monitor-Dashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.search-table {
  width: 100%;
  padding: 0.563rem 0.625rem;
  display: flex;
  gap: 0.625rem;
  background: var(--MonitorSearchBgColor);
  border-bottom: 1px solid var(--StatusBarBorder);
}

.advanced-filter,
.default-search,
.reset-search {
  background-color: transparent;
  border: none;
}

.dashboard-search-input {
  width: 100%;
  border: none;
  justify-self: center;
  background: var(--MonitorSearchBgColor);
  color: var(--MonitorSearchColor);
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -3px;
}

.data-table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto; /* Enable horizontal scrolling */
  border-left: 1px solid var(--monitorBorder);

  /* Custom scrollbar styles for WebKit browsers */
  &::-webkit-scrollbar {
    height: 5.5px; /* Set the height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    // background: #f1f1f1; /* Set the color of the scrollbar track */
  }
}

.tbody-wrapper {
  overflow-y: auto;
  display: block;
}

tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.full-width-row {
  padding: 1rem 0.5rem;
  padding-bottom: 0;
  height: calc(100vh - 17rem);
  border: none;
}

td {
  display: table-cell;
  padding: 0.938rem 1rem;
  border-right: 1px solid var(--monitorBorder);
  border-bottom: 1px solid var(--monitorBorder);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 60px;
}

.table-pagination {
  background: var(--PaginationContainerBG);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0.594rem 1rem;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background: var(--monitorBg2);
  overflow-x: auto;
}

.table-header {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid var(--monitorBorder);
}

tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  text-align: left;
}

th {
  display: table-cell;
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-right: 1px solid var(--monitorBorder);
  line-height: 1;
  min-width: 60px;
}
.row-index-header,
.row-index-cell {
  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 5rem;

  .row-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5; /* Change to your desired color */
}

tbody {
  display: table-row-group;
}

.row:hover .grid-cell.arrow {
  display: block;
}

//   .header-cell {
//     display: flex;
//     align-items: center;
//     background: var(--TableEven);
//     color: var(--monitorText);
//     padding: 0.625rem 1rem;
//     font-size: 0.875rem;
//     font-weight: 500;
//     border-right: 1px solid var(--monitorBorder);
//     line-height: 1;
//     .sign-container {
//       display: flex;
//       align-items: center;
//       gap: 0.625rem;
//     }
//   }
// }

// .grid-container {
//   display: grid;
//   grid-template-columns: 7% 17.5% 12% 10.25% 10.25% 10.25% 12.25% 10.25% 10.25%;
//   color: var(--monitorText);
//   grid-auto-rows: 56px;
//   max-height: calc(100vh - 241px);
//   overflow-y: auto;
//   .row:hover .grid-cell.arrow {
//     display: block;
//   }

//   .full-width-row {
//     grid-column: 1 / -1;
//     overflow-y: auto;
//     height: calc(100vh - 300px);
//   }
//   .row {
//     display: contents;

//     .grid-cell {
//       display: flex;
//       align-items: center;
//       padding: 0.938rem 1rem;
//       border-right: 1px solid var(--monitorBorder);
//       border-bottom: 1px solid var(--monitorBorder);
//       position: relative;

//       .text-truncate {
//         flex: 1;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//       .arrow {
//         display: none;
//         position: absolute;
//         left: 50px;
//         top: 19px;
//       }
//       &:hover .grid-cell .arrow {
//         display: inline;
//       }
//     }

//     &.even .grid-cell {
//       background-color: var(--TableEven);
//     }
//     &.active-row .grid-cell {
//       background-color: var(--RowHover);
//     }

//     .grid-cell.red-cell {
//       color: var(--Processing);
//     }

//     .grid-cell.green-cell {
//       color: var(--StopRefetch);
//     }
//   }

//   .row:hover .arrow {
//     display: block;
//   }
//   .row:hover .grid-cell {
//     background: var(--RowHover);
//   }
// }
// .no-results-container {
//   margin: 3.75rem auto;
//   display: flex;
//   flex-direction: column;
//   gap: 1.25rem;
//   .no-results-found-title {
//     color: #b9b8bd;
//     font-family: Hanken Grotesk;
//     font-size: 24px;
//     font-weight: 500;
//     line-height: 31px;
//     letter-spacing: -0.02em;
//     text-align: center;
//   }
// }
