.searchbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 5px;
  padding-right: 2%;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    .timesIcon2 {
      color: var(---primary);
    }
  }
  .searchInput {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 90%;
    border-radius: 5px;
    border: 1px solid var(---primary);
    padding: 0 2%;
    background-color: var(---welcomeBg);

    .icon-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 1%;
      color: var(---primary);
    }
  }
}
