.login-screen-container {
  display: flex;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex-direction: column;
  border-radius: 0 0 36px 36px;

  .login-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    max-height: 20%;
    min-height: 20%;
    .logo-container {
      display: flex;
      max-height: 80%;
      max-width: 45%;
      .logo-style {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }
  }

  .login-body {
    width: 100%;
    height: 75%;
    max-height: 75%;
    min-height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0 6%;

    .form-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0px;
      box-shadow: none;
      gap: 5%;
      .btn-login {
        border-radius: 9px;
        border-width: 0px;
        background-color: var(---bnConnect);
        color: var(---loginBtnLabel);
        width: 100%;
        height: 45px;
        box-shadow: 0 4px 6px 0 var(---shadowBtnConnect);
        cursor: url(../../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      }
    }
    .option-txt-style {
      color: var(---loginOptions);
    }
  }
  .login-body::-webkit-scrollbar {
    display: none;
  }
  .login-footer {
    width: 100%;
    height: 5%;
    max-height: 5%;
    min-height: 5%;
    display: flex;
    bottom: 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 6%;
    background-color: var(---loginBgFooter);

    .privacy-container {
      display: flex;
      color: var(---loginFooter);
      flex-direction: row;
      .privacy-txt {
        font-size: 12px;
        font-weight: 600;
      }
      .about-txt {
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .language-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40px;
      .LanguageBtn {
        color: var(---languageIcon);
      }
      .language-value-txt {
        font-size: 12px;
        font-weight: 600;
        color: var(---loginFooter);
      }

      .about-txt {
        // margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
