.data-collection-container {
  display: flex;
  height: calc(100vh - 7rem);
  overflow: auto;

  .monitor-Dashboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .search-table {
      width: 100%;
      padding: 0.563rem 0.625rem;
      display: flex;
      gap: 0.5rem;
      background: var(--MonitorSearchBgColor);
      border-bottom: 1px solid var(--StatusBarBorder);

      .advanced-filter,
      .default-search,
      .toggle-dropdown,
      .reset-search {
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
      }
      .search-divider {
        width: 2px;
        height: 21px;
        border-radius: 50px;
        margin-top: 2px;
        background: #d7d7d7;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
      }

      .dashboard-search-input {
        width: 100%;
        border: none;
        justify-self: center;
        background: var(--MonitorSearchBgColor);
        color: var(--MonitorSearchColor);
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -3px;
        color: #39354a;
      }
      .CollectionLength {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .Collection-sum {
          display: flex;
          width: fit-content;
          white-space: nowrap;
        }
        .Collection {
          font-size: smaller;
          padding-left: 3%;
          color: var(--MoniteurCollectionColor) !important;
          font-weight: 400;
          position: relative;
          top: 0.2em;
          font-size: 12px;
        }
      }
      .dropdown-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #ffffff;
        width: 251px;
        right: 16px;
        top: 88px;
        z-index: 9999;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        height: 260px;
        overflow-y: auto;

        .dropdown-dashboard-label {
          display: flex;
          border-bottom: 1px solid #eeeeee;
          padding: 12px 16px;
          gap: 10px;
        }
        .dropdown-input {
          font-family: Hanken Grotesk;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.85px;
          text-align: left;
        }
      }
      .dropdown-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #ffffff;
        width: 251px;
        right: 16px;
        top: 88px;
        z-index: 9999;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        height: 260px;
        overflow-y: auto;

        .dropdown-dashboard-label {
          display: flex;
          border-bottom: 1px solid #eeeeee;
          padding: 12px 16px;
          gap: 10px;
        }
        .dropdown-input {
          font-family: Hanken Grotesk;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.85px;
          text-align: left;
        }
      }
    }

    .data-table-wrapper {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: auto;
      border-left: 1px solid var(--monitorBorder);

      .tbody-wrapper {
        overflow-y: auto;
        display: block;

        tr {
          display: table;
          width: 100%;
          table-layout: fixed;

          .full-width-row {
            padding: 1rem 0.5rem;

            height: auto;
            border: none;
            background-color: white;

            td {
              display: block;
              padding: 0;
              border: none;
            }

            .react-json-view {
              padding: 0 !important;
              border: none !important;
              background: var(--TableEven);
              overflow-y: auto;
              height: auto;
              max-height: 490px;
            }
          }
        }

        td {
          display: table-cell;
          padding: 0.938rem 1rem;
          border-right: 1px solid var(--monitorBorder);
          border-bottom: 1px solid var(--monitorBorder);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 60px;

          &.failed-cell {
            color: #da3741;
            text-decoration: underline;
          }
        }
      }
      .tbody-wrapper:hover {
        cursor: pointer;
      }

      .row:hover .grid-cell.arrow {
        display: block;
      }
    }
  }

  .table-pagination {
    background: var(--PaginationContainerBG);
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    padding: 0.594rem 1rem;
  }
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background: var(--monitorBg2);
  overflow-x: auto;

  .table-header {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid var(--monitorBorder);
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #f5f5f5;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      text-align: left;
    }

    th {
      background: #f5f5f5;
      display: table-cell;
      padding: 0.8rem 1rem;
      font-size: 0.875rem;
      font-weight: 500;
      border-right: 1px solid var(--monitorBorder);
      line-height: 1;
      min-width: 60px;
      width: 12.5rem;
    }
  }

  tbody {
    display: table-row-group;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-bottom: 1px solid var(--monitorBorder);

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      display: table-cell;
      padding: 0.938rem 1rem;
      border-right: 1px solid var(--monitorBorder);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 12.5rem;

      &:last-child {
        border-right: none;
      }

      &.failed-cell {
        color: #da3741;
        text-decoration: underline;
      }
    }
  }

  .collection-id-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .failed-badge {
      display: inline-block;
      padding: 3px 10px;
      margin-right: 0.5rem;
      border: 0.5px solid #da3741;
      border-radius: 72px;
      background-color: #ffd6d8;
      color: #da3741;
      font-size: 14px;
    }

    .data-collection-id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .data-collection-id.failed-cell {
      color: #da3741;
      text-decoration: underline;
      font-family: Hanken Grotesk;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.85px;
      text-align: left;
    }
  }
}

.sync-icon{
  display: flex;
}

//   .header-cell {
//     display: flex;
//     align-items: center;
//     background: var(--TableEven);
//     color: var(--monitorText);
//     padding: 0.625rem 1rem;
//     font-size: 0.875rem;
//     font-weight: 500;
//     border-right: 1px solid var(--monitorBorder);
//     line-height: 1;
//     .sign-container {
//       display: flex;
//       align-items: center;
//       gap: 0.625rem;
//     }
//   }
// }

// .grid-container {
//   display: grid;
//   grid-template-columns: 7% 17.5% 12% 10.25% 10.25% 10.25% 12.25% 10.25% 10.25%;
//   color: var(--monitorText);
//   grid-auto-rows: 56px;
//   max-height: calc(100vh - 241px);
//   overflow-y: auto;
//   .row:hover .grid-cell.arrow {
//     display: block;
//   }

//   .full-width-row {
//     grid-column: 1 / -1;
//     overflow-y: auto;
//     height: calc(100vh - 300px);
//   }
//   .row {
//     display: contents;

//     .grid-cell {
//       display: flex;
//       align-items: center;
//       padding: 0.938rem 1rem;
//       border-right: 1px solid var(--monitorBorder);
//       border-bottom: 1px solid var(--monitorBorder);
//       position: relative;

//       .text-truncate {
//         flex: 1;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//       .arrow {
//         display: none;
//         position: absolute;
//         left: 50px;
//         top: 19px;
//       }
//       &:hover .grid-cell .arrow {
//         display: inline;
//       }
//     }

//     &.even .grid-cell {
//       background-color: var(--TableEven);
//     }
//     &.active-row .grid-cell {
//       background-color: var(--RowHover);
//     }

//     .grid-cell.red-cell {
//       color: var(--Processing);
//     }

//     .grid-cell.green-cell {
//       color: var(--StopRefetch);
//     }
//   }

//   .row:hover .arrow {
//     display: block;
//   }
//   .row:hover .grid-cell {
//     background: var(--RowHover);
//   }
// }
// .no-results-container {
//   margin: 3.75rem auto;
//   display: flex;
//   flex-direction: column;
//   gap: 1.25rem;
//   .no-results-found-title {
//     color: #b9b8bd;
//     font-family: Hanken Grotesk;
//     font-size: 24px;
//     font-weight: 500;
//     line-height: 31px;
//     letter-spacing: -0.02em;
//     text-align: center;
//   }
// }
