.about-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  max-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  min-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  .about-logo {
    padding: 4%;
    display: flex;
    justify-content: center;
  }
  .about-platform {
    padding: 4%;
  }
  .about-version {
    padding: 4%;
  }
  .about-moreInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    .moreInfo-text {
      display: flex;
      font-size: x-small;
      color: #ccc;
      text-align: center;
    }
    .moreInfo-link {
      display: flex;
      font-size: small;
      text-decoration-line: underline;
      color: var(---linkColor);
    }
  }
}
