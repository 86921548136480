.header-with-pullup {
  height: 40px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  border-width:0px 0px 2px 0px;
  border-color: #46969E;
  border-style: solid;

  .header-part-1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-part-2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .header-part-3 {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
