.toolbar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4px 3px;
  gap: 5px;
  background-color: var(---listBg);
  .searchIcon {
    display: flex;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    justify-content: center;
    align-items: center;
    color: var(---primary);
  }
  .other-events {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    padding: 0 4px;
    gap: 5px;
  }
  .other-events::-webkit-scrollbar {
    display: none;
  }
}
.hovered {
  &:hover {
    background-color: #3663d633;

    border-color: #aaaaaa;
  }
}
.hovered-selected {
  background-color: #6f53a340;
  border-width: 1.5px;
  border-style: dashed;
  border-color: #3663d6;
  border-radius: 6px;
}
