.input2 {
  min-height: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  font-size: 14px;
  padding: 0 0.5rem;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: transparent;
  cursor: text;
  font-family: "Hanken Grotesk", sans-serif;
}
