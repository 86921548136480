.iconManagerBar {
  padding: 0rem;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  height: 3rem;
  border-bottom: 1px solid var(--BorderStroke);
  background: var(--ToolBarBg);
}
