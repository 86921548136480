.calendar-container {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;

  .calendar-days-details-container {
    height: 60%;
    width: 100%;

    .header-days-container {
      height: auto;

      display: flex;
      flex-direction: row;
      max-width: 100%;
      .day-container-in-header {
        height: 100%;
        width: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .calendar-days-per-days-details {
      min-height: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .calendar-detail-item {
        min-height: 60px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 50px;

        .day-num-name-container {
          width: 25%;
          height: 100%;
          min-height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        .day-detail-container {
          width: 75%;
          min-height: 80px;
          height: auto;
          border-bottom-width: 1px;
          border-top-width: 0px;
          border-left-width: 0px;
          border-right-width: 0px;
          border-style: solid;
          border-color: #e2e2e2;

          padding: 10px;
          .day-detail-container-card {
            padding: 3px;
            background-color: #fff;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            margin: 3px;
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
  .pullup-container {
    width: 90.5%;
    height: 80px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 50px 50px;
  }
  .calendar-month {
    padding: 5px;
  }
}
.calendar-container {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.calendar-month .react-calendar__navigation {
  display: flex;
  justify-content: center;
  position: relative;
}

.calendar-month .react-calendar__navigation button {
  visibility: hidden;
}

.calendar-month .react-calendar__navigation button[aria-label*="month"],
.calendar-month .react-calendar__navigation button[aria-label*="year"] {
  display: none;
}

.calendar-month .react-calendar__navigation .react-calendar__navigation__label {
  visibility: visible;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  color: #a9abad;
  text-underline-offset: 0px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.calendar-days-per-days-details-x {
  background-color: #f9f8fb;
  overflow-y: auto;
  max-height: 46.2vh;
  border-radius: 0px 0px 50px 50px;
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.calendar-days-per-days-details-x::-webkit-scrollbar {
  display: none;
}

.container-no-pullup {
  max-height: 52vh;
  height: 52vh;
  width: 100%;
  padding: 10;
  overflow: auto;
  border-radius: 0px 0px 50px 50px;
}
.container-no-pullup::-webkit-scrollbar {
  display: none;
}
.days-tab-container{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x : auto;

}
.days-tab-container::-webkit-scrollbar {
  display: none;
}