.timer-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  right: 5%;
  bottom: 3%;
  min-width: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.18);
  height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.18);
  padding: 5px;
  .timer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 2px;
    border-radius: 500px;
    .timer-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.17);
      max-width: calc((100vh - 10.5rem) * 0.47 * 0.935 * 0.9);
      height: calc((100vh - 12.5rem) * 0.47 * 0.935 * 0.17);
      overflow-x: auto;
      gap: 4px;
      padding: 0 1px;
      width: auto;

      .hovered {
        &:hover {
          background-color: #3663d633;
        }
      }
    }
    .timer-button::-webkit-scrollbar {
      display: none;
    }
    .timer-container {
      display: flex;
      width: calc(((100vh - 15.5rem) * 0.47 * 0.935 * 0.17) - 4px);
      height: calc(((100vh - 12.5rem) * 0.47 * 0.935 * 0.17) - 4px);
      border-radius: 50%;

      font-size: x-small;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: 0px 0px 6px 0px #6a549e66;
    }
    .hovered {
      &:hover {
        background-color: #3663d633;
      }
    }
  }
}
