@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk&family=Roboto&display=swap");

.iconCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 146px;
  background-image: linear-gradient(
      45deg,
      var(--Condition) 25%,
      transparent 25%,
      transparent 75%,
      var(--Condition) 75%,
      var(--Condition)
    ),
    linear-gradient(
      45deg,
      var(--Condition) 25%,
      var(--ConditionUI) 25%,
      var(--ConditionUI) 75%,
      var(--Condition) 75%,
      var(--Condition)
    );
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
  border-radius: 2px;
  border: 1px solid var(--BorderStroke);

  .iconName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text);
  }
  &.active {
    border-radius: 4px;
    border: 3px solid var(--SupportButtonBg);
    border-radius: 2px;
    .iconDisplay {
      border-bottom: 3px solid var(--SupportButtonBg);
    }
    .iconFooter {
      background-color: var(--SupportButtonBg);
      border: none;
    }
    .iconName {
      color: white;
    }
  }
  .icon-dropdownMenu {
    width: 120px;
    display: flex;
    position: absolute;
    z-index: 999;
    transform: translate(45px, 65px);
    flex-direction: column;
    justify-content: center;
    padding: 0;
    border-radius: 2%;
    border: 1px solid var(--BorderStroke);
    background: var(--CardBg);
    box-shadow: 0px 4px 6px 0px rgba(120, 120, 120, 0.14);
    color: var(--text);
    cursor: pointer;

    .icon-dropdownMenu .disabled {
      color: var(--BorderStroke);
    }

    li {
      display: flex;
      align-self: center;
      align-items: center;
      border-bottom: 1px solid #ddd;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #4c36a5;
        color: #fff;
        border: none;
      }
    }
  }

  .iconDisplay {
    display: flex;
    width: 140px;
    height: 206px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom: 1px solid var(--BorderStroke);

    .iconImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 2px;
      z-index: 0;
      flex-shrink: 0;
    }
  }

  .iconFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
    padding: 5px 5px;
    color: var(--cool-black, #212525);
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: var(--CardBg);
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    .CopyIcon {
      width: 20%;
      color: #c4c3c9;
      cursor: pointer;

      &:not(.selected):hover {
        color: #4c36a5;
      }
    }
  }

  .custom-tooltip {
    & .MuiTooltip-tooltip {
      background-color: #16a34ab3;
      color: white;
      font-size: 16px;
      border-radius: 2px;
    }
  }
}
