.AlertDialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  .confirm-dialog {
    box-shadow: 0px 0px 0px -1px rgba(124, 77, 255, 0.2),
      0px 4px 5px 0px rgba(124, 77, 255, 0.2),
      0px 1px 10px 0px rgba(124, 77, 255, 0.2);
    z-index: 1000000000000111;
    padding: 30px 30px 20px 30px;
    background-color: white;
    width: 500px;
    min-height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    .confirm-dialog-header {
      height: 150px;
      overflow-y: auto;
      .confirm-message {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .mini-confirm-dialog-header {
      overflow-y: auto;
      .confirm-message {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .confirm-dialog-grid {
    display: grid;
    gap: 20px;
  }
}
