.RelationShip-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  .ReHeader {
    display: flex;
    flex-direction: row;
    height: 7%;
    min-height: 7%;
    max-height: 7%;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(72, 72, 72, 0.3);
    padding-top: 2%;
    padding-left: 2%;
    //padding-right: 2%;
    // background-color: white;
    z-index: 2;
    .goBack {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10%;
      height: 100%;
      .chevronIcon {
        color: var(---primary);
      }
    }
    .tabsList {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 90%;
      height: 100%;
      max-width: 90%;
      overflow-x: auto;
      .tabButtom {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-left: 3%;
        padding-right: 3%;
        font-size: small;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(---primary);
      }
    }
    .tabsList::-webkit-scrollbar {
      height: 2px;
    }
  }
  .ReContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc((100vh - 14.2rem) * 0.86) !important;
    max-height: calc((100vh - 14.2rem) * 0.86) !important;
    min-height: 50% !important;
    justify-content: center;
    align-items: flex-start;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .detail-empty {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    .emptyDetail-container {
      display: flex;
      width: 90%;
      height: 50px;
      border-radius: 9px;
      border: 1px solid #aaaaaa;
      justify-content: center;
      align-items: center;
    }
  }
}
