.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 3rem);
  max-height: calc(100vh - 3rem);
}

.container .mainContainer {
  background-color: var(--BorderStroke);
  display: flex;
  flex-direction: row;
  overflow: none;
  height: calc(100vh - 6rem);
  max-height: calc(100vh - 6rem);
  width: 100vw;
  align-self: flex-end;
  max-width: 100vw;
  // transition: width 0.5s ease-in-out;
}

.container .mainContainer .SplitPane {
  height: calc(100vh - 6rem) !important;
  max-height: calc(100vh - 6rem) !important;
  position: relative !important;
  background-color: var(--BorderStroke);
}

.container .mainContainer .Resizer {
  background: #000;
  opacity: 0;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.container .mainContainer .Resizer:hover {
  transition: all 0.65s ease;
}

.container .mainContainer .Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.container .mainContainer .Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.container .mainContainer .Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: url(./components/ComponentsLibrary/Assets/Resize.svg), auto;
}

.container .mainContainer .Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.container .mainContainer .Resizer.disabled {
  cursor: url(../Designer/components/ComponentsLibrary/Assets/NotAllowed.svg);
}

.container .mainContainer .Resizer.disabled:hover {
  border-color: transparent;
}

.container .mainContainer .sideBar {
  height: calc(100vh - 6rem);
  max-height: calc(100vh - 6rem);
}

.container .mainContainer .Properties {
  height: calc(100vh - 6rem);
  max-height: calc(100vh - 6rem);
}

.container .mainContainer .RevisionContainer {
  height: calc(100vh - 6rem);
  max-height: calc(100vh - 6rem);
  width: calc(100vw * 0.2);
  max-width: calc(100vw * 0.2);
  min-width: calc(100vw * 0.2);
  border-left: 1px solid var(--BorderStroke);
}

.container .mainContainer.transformed {
  width: calc(100vw - 20rem); /* Apply transformation */
  transform: translateX(0);
}

.container .mainContainer.transformed .RevisionContainer {
  transition: width 0.65s ease; /* Transition for width change */
}

.container .mainContainer:not(.transformed) .RevisionContainer {
  transition: width 0.65s ease; /* Transition for width change */
}

@keyframes transformAnimation {
  from {
    width: 100vw;
  }
  to {
    width: calc(100vw - 20rem);
  }
}
