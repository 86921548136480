.StepperContainer {
  .custom-connector {
    &.active .MuiStepConnector-line {
      border-left-color: var(--TitleContainer);
    }
  }
  .number {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
  }
  .stepDescription {
    padding-left: 20%;
    width: 470px;
    text-align: right;
    color: var(--Globe);
    .textDecriptions {
      font-family: "Hanken Grotesk";
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: var(--TitleContainer);
    }
    .questionDecriptions {
      text-align: left;
      margin-bottom: 15px;
    }
  }

  .StepLabel {
    color: var(--StepperBar);
    font-family: "Hanken Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 4%;
  }
  .StepLabel.active {
    color: var(--TitleContainer);
  }

  .test {
    width: 65px !important;
    height: 65px !important;
    font-size: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--SupportButtonBg);
    border: 2px solid var(--SupportButtonBg);
  }

  .test::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid var(--CardBg);
    box-sizing: border-box;
  }

  .test.inactive {
    background-color: var(--StepperBar);
    border: 2px solid var(--CardBg);
  }
  .ButtonSection {
    text-align: right;

    .ButtonStepper {
      background-color: var(--SupportButtonBg);
      border-radius: 0px;
      height: 44px;
      width: 144px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
