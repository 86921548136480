.iconManager {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 3rem);

  .loading-animation {
    display: flex;
    background-color: var(--header);
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  .upload-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 6rem);
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    padding: 20px;
    gap: 25px;
    background-color: var(--header);
  }

  .main-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 6rem);
    width: 100%;
    .icons-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(140px, 140px));
      grid-auto-rows: minmax(146px, 146px);
      width: calc(100% - 18.5rem);
      overflow-y: auto;
      padding-right: 15px;
      gap: 20px !important;
      padding: 1rem;
      padding-right: 0;
      background-color: var(--header);
    }
    .icon-sideBar {
      display: flex;
      height: calc(100vh - 6rem);
      width: 18.5rem;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
      overflow-y: hidden;
      margin-bottom: 20px;
      background-color: var(--CardBg);
    }
  }
  * {
    letter-spacing: normal !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
