.rightBar {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  border-left: 1px solid var(--BorderStroke);
  max-height: calc(100vh - 6rem) !important;
  height: calc(100vh - 6rem) !important;
  background-color: var(--header);
  .selectedTab {
    color: #000;
  }
  .tabsHeader {
    width: 100%;
    height: 3.5rem;
    min-height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px;
    color: var(--TitleContainer);
    border-bottom: 1px solid var(--BorderStroke);

    .headerTitle {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      height: 100%;
    }
    .header-CE {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 3.5rem;
      font-size: 16px;

      .Editbtn,
      .EditorBtn,
      .SaveBtn {
        color: var(--Globe);
        cursor: pointer;
      }

      .options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        gap: 10px;

        .bar {
          display: flex;
          width: 3px;
          min-width: 3px;
          border-radius: 50px;
          background-color: var(--separator);
          height: 24px;
        }
        .wrapperDropdown {
          display: flex;
          height: 100%;
        }
        .Dropdown-Menu {
          background-color: var(--header);
          border: 1px solid #ccc;
          border-radius: 2px;
        }
        .dropdownTitle {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border: 0px;
          background-color: transparent;
          color: var(--text);
          padding: 0px;
          cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
        }
        .Dropdown-Item {
          color: var(--text);
          font-family: "Hanken Grotesk";
          justify-content: space-between;
          cursor: url(../ComponentsLibrary//Assets/Pointer.svg), auto;
          &:hover {
            background-color: var(--primary);
            color: white;
          }
        }
        .Dropdown-Submenu {
          background-color: var(--header);
          border: 1px solid #ccc;
          .Selected-Dropdown-Item {
            background-color: var(--primary);
            color: white;
          }
        }
      }
      .right-options {
        display: flex;
        justify-content: center;
        gap: 8px;
      }
    }
  }
  .tabContent {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 9.5rem) !important;
    height: calc(100vh - 9.5rem) !important;
  }

  .editor-disabled {
    cursor: not-allowed;
  }

  .tabContent::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }

  .tabContent::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 10px;
  }

  .tabContent::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
