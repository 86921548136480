.swipe-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 36px 36px;
  gap: 2%;
  .close-button {
    display: flex;
    width: calc((100vh - 10.5rem) * 0.47 * 0.94 * 0.1);
    height: calc((100vh - 12.5rem) * 0.47 * 0.94 * 0.1);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: white;
    .timesIcon {
      color: var(---primary);
    }
  }
  .swipe {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-height: 35%;
    min-height: 7%;
    border-radius: 9px 9px 36px 36px;
    background-color: #f5f5f5;
    padding: 15px;
    gap: 2%;
    .swipe-title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      max-height: calc((100vh - 12.5rem) * 0.055);
      min-height: calc((100vh - 12.5rem) * 0.055);
      gap: 6px;
      .swipe-label {
        font-weight: 700;
        color: #6a549e;
      }
      .swipe-name {
        font-weight: 500;
        color: #222;
      }
    }
    .drop-swipe-zone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: white;
      border-radius: 5px;
      overflow-y: auto;
      padding: 1px 0;
    }
    .drop-swipe-zone::-webkit-scrollbar {
      display: none;
    }
  }
}

.drag-att-zone {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
}
