.ImxLoggerAction {
  display: flex;
  justify-content: space-between;
  background-color: var(--monitorInput);
  .action-title {
    font-family: Hanken Grotesk;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    align-self: center;
  }
  .toggle-button {
    // width: 20px;
    // height: 20px;
    // background: green;
  }
}
