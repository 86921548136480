.drop-list-zone {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  .headerList {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 33px;
    max-height: 33px;
    min-height: 33px;
    align-items: center;
    padding-right: 2%;
    gap: 4px;
    background-color: var(---listBg);

    .goBack {
      display: flex;
      height: 100%;
      width: 28px;
      min-width: 28px;
      max-width: 28px;
      justify-content: center;
      align-items: center;
      cursor: url(../../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      color: var(---primary);
    }

    .appTitle {
      display: flex;
      height: 100%;
      min-width: calc(100% - 64px);
      max-width: calc(100% - 64px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0 6px;
      cursor: url(../../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
      .List-label {
        color: var(---primary);
        margin-right: 2%;
      }
      .list-length {
        color: var(---separatorColor);
      }
    }
    .header-clssification {
      display: flex;
      height: 100%;
      width: 28px;
      min-width: 28px;
      max-width: 28px;
      justify-content: center;
      align-items: center;
    }
  }
  .path-clssification {
    display: flex;
    height: 7%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    .empty-path {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: #3663d633;
      }
    }
  }
  .detail-clssification {
    display: flex;
    height: 7%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    .empty-detail {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;

      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: #3663d633;
      }
    }
    .event-container {
      display: flex;
      padding: 1%;
      justify-content: end;
    }
  }
  .toolbar-container {
    display: flex;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #6f53a3;
  }
  .list-body-toolbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow-y: auto;
    padding-top: 6px;
    border-radius: 0 0 36px 36px;
    background-color: white;
  }
  .list-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 2px 0;
    justify-content: center;
    overflow-y: auto;
    border-radius: 0 0 36px 36px;
    padding-top: 2%;
  }
  .list-body-empty {
    height: 100%;
  }
  .drop-empty-list-zone {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 6px;

    .empty-list {
      display: flex;
      width: 90%;
      height: 50px;
      border-radius: 9px;
      border: 1px solid #aaaaaa;
      justify-content: center;
      align-items: center;
    }
  }
  .list-body-toolbar::-webkit-scrollbar {
    display: none;
  }
  .list-body::-webkit-scrollbar {
    display: none;
  }
}
