.BrowserFileContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  gap: 10px;
  min-height: 250px;
  width: 100%;

  .BrowserFile-mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    align-items: center;
    justify-items: center;
    .BrowserFile-instructions {
      display: grid;
      align-items: center;
      .BrowserFileLabel {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .BrowserFileLabel1 {
        text-align: center;
      }
      .BrowserFileButton {
        color: white;

        border-radius: 8px;
        margin: auto;
        padding: 4%;
      }
    }
  }
}
