.revisionItem-edit {
  display: flex;
  padding: 0.5rem 0.5625rem;
  justify-content: space-between;
  width: calc(100% - 5.5px);
  max-width: calc(100% - 5.5px);
  min-width: calc(100% - 5.5px);
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  margin-bottom: 10px;
  background-color: var(--SupportButtonBg);
  color: white;
  height: 3.7rem;
  max-height: 3.7rem;
  min-height: 3.7rem;
  .revision-content {
    display: flex;
    width: 92%;
    flex-direction: column;
    .revision-name {
      font-size: 16px;
      font-weight: 500;
    }
    .release-datetime {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .actions-btn {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    width: 20px;

    .moreOptions-btn {
      color: white;
      border-radius: 0px;
      width: 20px;
    }
  }
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
  .message {
    font-size: 16px;
    font-weight: 400;
  }
  .draftVersion {
    font-size: 16px;
    font-weight: 500;
  }
}

.renameContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  .renameMsg {
    font-size: 16px;
    font-weight: 400;
  }
}
