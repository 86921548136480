.performanceBar-container {
  height: 0.5rem;
  border-radius: 1.875rem;
  background: var(--PerformanceBar);
}

.performanceBar-fill {
  height: 100%;
  border-radius: inherit;
  background: #9c37da;
}
