.installationScripts {
  margin: 10px 0;
  padding-right: 10px;
  height: 80%;
  overflow-y: auto;

  .installation-script {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 2px;

    .installation-script-name {
      display: flex;
      color: var(--InstallerScript);
      font-family: "Hanken Grotesk";
      font-size: 16px;
      gap: 12px;
      line-height: normal;
    }
    .icon-hover {
      color: #aaaaaa;
      &:hover {
        color: #4c36a5;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 402px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 132px;
    border-radius: 81px;
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.popupContainer-script {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  justify-content: center;
  .title-popup-script {
    font-family: "Hanken Grotesk";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    word-wrap: break-word;
    color: var(--InstallerScript);
  }
}
