.settings-high-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 18rem);
  // max-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  // min-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.94);
  .settings-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    height: 92%;
    max-height: 92%;
    min-height: 92%;
    padding: 5% 4%;
    gap: 2%;

    .login-setting {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0% 0%;
      gap: 5px;
      &-ar {
        align-items: flex-end;
        justify-content: flex-end;
      }
      .username {
        display: flex;
        flex-direction: column;
        .username-label {
          color: #252221;
          font-family: Hanken Grotesk;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px;
        }
        .username-value {
          color: #212525;
          font-family: Hanken Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .domain {
        display: flex;
        flex-direction: column;

        .domain-label {
          color: #212525;
          font-family: Hanken Grotesk;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px;
        }
        .domain-value {
          color: #212525;
          font-family: Hanken Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
    .styles-settings {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0% 0%;
      gap: 10px;

      .theme {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: white;
        padding: 0 2%;
        border-radius: 9px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;

        .theme-left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .theme-title {
            color: #212525;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            opacity: 0.5;
          }
          .theme-value {
            color: #212525;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .theme-right {
          height: 28px;
          width: 28px;
          border-radius: 6px;
          background: var(---primary);
        }
      }
      .font {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: white;
        padding: 0 2%;
        border-radius: 9px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        .font-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .font-title {
            color: #212525;
            font-family: Hanken Grotesk;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 142.857% */
            opacity: 0.5;
          }
          .font-value {
            color: #212525;
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 125% */
          }
        }
      }
      .apparence {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: white;
        padding: 0 2%;
        border-radius: 9px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        .apparence-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .apparence-title {
            color: #212525;
            font-family: Hanken Grotesk;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            opacity: 0.5;
          }
          .apparence-value {
            color: #212525;
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
      .view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: white;
        padding: 0 2%;
        border-radius: 9px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        .view-label {
          display: flex;
          align-items: center;
          height: 40px;
          min-height: 40px;
          max-height: 40px;
        }
        .view-icon {
          display: flex;
          align-items: center;
          height: 40px;
          min-height: 40px;
          max-height: 40px;
          color: var(---primary);
        }
      }
      .language {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background-color: white;
        padding: 0 2%;
        border-radius: 9px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
      }
    }
  }
  .settings-container::-webkit-scrollbar {
    display: none;
  }
  .logout-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 8%;
    max-height: 8%;
    min-height: 8%;
    width: 100%;
    padding: 0 3%;
    background-color: var(---CardViewBgColor);
    box-shadow: 0px -5px 25px 0px #22222226;
    font-size: 14px;
    font-weight: 500;
    font-family: "Hanken Grotesk", sans-serif;

    color: var(---primary);
    border-radius: 9px 9px 0px 0px;

    .logout-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;
      width: 80px;
      // margin-left: %;
      cursor: pointer;
    }
  }
}
