.TableControlButtons {
  display: flex;
  justify-content: space-between;
  background: var(--StatusBarBg);
  border-bottom: 1px solid var(--StatusBarBorder);
  .rightButtons{
    display: flex;
    flex-direction: row;
    
  }
  .leftButtons{
    display: flex;
    flex-direction: row;.ipAddress {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      padding: 0.313rem 0.625rem;
      border-radius: 50px;
      color: var(--loggerServerNameColor);
      background: var(--loggerServerNameBackground);
      font-family: "Hanken Grotesk";
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      align-self: center;
      .ipAddressText{
        color: var(--loggerServerNameColor);
        margin-right: 7px;
        font-family: "Hanken Grotesk";
      font-size: 20px;
      font-weight: 300;
      }
    }
  }

}
