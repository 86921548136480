.SyncObjStructure {
  border: 1px solid rgba(238, 238, 238, 0.93);
  background-color: #fcfcfc;
  min-height: 12%;
  margin-bottom: 3%;
  padding: 20px 40px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  .SyncObjStructureHeader {
    display: flex;
    justify-content: space-between;
    padding: 1% 0%;

    .SyncObjStructureTitle {
      color: var(--Globe);
      font-family: "Hanken Grotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .SyncObjStructure-Expand-button {
      color: var(--ButtonSelected);
      border: none;
      cursor: pointer;
      font-size: 14px;
      background-color: #ffffff;
    }
  }
  .SyncObjStructureBody {
    max-height: 20%;
    border-top: 1px solid var(--BorderButtons);
    .setupBody {
      display: flex;
      flex-direction: column;
      min-height: 80%;

      .setupTitles {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        min-height: 100%;
        gap: 15px;
        margin: 20px 0px;

        .setup-messages {
          display: flex;
          flex: block;
          color: var(--Globe);
          font-family: "Hanken Grotesk";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          flex-direction: row;
          .setup-title {
            display: flex;
            margin-right: 10px;
            flex-shrink: 0;
          }
          .successMessage {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Green);
            font-family: "Hanken Grotesk";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 10px;
          }
          .errorMessage {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Red);
            font-family: "Hanken Grotesk";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 5px;
          }
          .setup-icon {
            display: flex;
            align-items: center;
            // justify-content: center;
            margin-right: 30px;
            height: 30px;
            width: 60px;
          }
        }
      }
      .setupFooter {
        display: flex;
        text-align: right;
        justify-content: end;
        .setup-next-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background-color: var(--ButtonSelected);
          color: #fff;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 2px;
          font-size: 16px;
          height: 44px;
        }
        .grey-out {
          background-color: #dcdcdc;
          color: #908d9a;

          cursor: not-allowed;
        }
      }
    }
  }
}
