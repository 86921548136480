.ace_search {
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.AceEditor {
  z-index: 0;
}
.fullscreenBtn {
  display: flex;
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  z-index: 1;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  border-top-left-radius: 2px;
}
.editorCompareLeft {
  border-right: 2px solid #ec5c55;
  border-left: 2px solid #ec5c55;
  border-bottom: 2px solid #ec5c55;
  border-radius: 0px 0px 6px 6px;
}
.editorCompareRight {
  border-right: 2px solid #36a5a5;
  border-left: 2px solid #36a5a5;
  border-bottom: 2px solid #36a5a5;
  border-radius: 0px 0px 6px 6px;
}
.editorCompareDraft {
  border-right: 2px solid #4c36a5;
  border-left: 2px solid #4c36a5;
  border-bottom: 2px solid #4c36a5;
  border-radius: 0px 0px 6px 6px;
}
.placeholder-text::before {
  content: attr(data-placeholder);
  color: #999;
  font-style: italic;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
