.titleMap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .title-icon {
    display: flex;
    height: 100%;
    width: 17%;
    justify-content: center;
    align-items: center;
  }
  .title-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 83%;
    height: 100%;
    font-size: small;
    font-weight: 700;
  }
}
