.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: #f2f0f6;
  border-radius: 0 0 36px 36px;
  .Page {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .drop-page-empty-zone {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 2%;
      .EmptyPage {
        display: flex;
        height: 45px;
        width: 100%;
        background-color: white;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        color: #414141;
        font-size: 18px;
        font-weight: 500;
      }
      .void {
        color: #bbb;
        font-size: 14px;
        font-weight: 300;
        background-color: transparent;
      }
    }
  }
  .NoPage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #aaa;
    cursor: url(../../../../../ComponentsLibrary/Assets/Pointer.svg), auto;
    background-color: white;
    background-image: repeating-linear-gradient(0deg, rgba(54, 99, 214, 0.6117647059), rgba(54, 99, 214, 0.6117647059) 9px, transparent 9px, transparent 19px, rgba(54, 99, 214, 0.6117647059) 19px), repeating-linear-gradient(90deg, rgba(54, 99, 214, 0.6117647059), rgba(54, 99, 214, 0.6117647059) 9px, transparent 9px, transparent 19px, rgba(54, 99, 214, 0.6117647059) 19px), repeating-linear-gradient(180deg, rgba(54, 99, 214, 0.6117647059), rgba(54, 99, 214, 0.6117647059) 9px, transparent 9px, transparent 19px, rgba(54, 99, 214, 0.6117647059) 19px), repeating-linear-gradient(270deg, rgba(54, 99, 214, 0.6117647059), rgba(54, 99, 214, 0.6117647059) 9px, transparent 9px, transparent 19px, rgba(54, 99, 214, 0.6117647059) 19px);
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;

  }
  .footer-nav {
    display: flex;
    height: 8%;
    max-height: 8%;
    width: 100%;
    z-index: 1;
    border-radius: 0px 0px 36px 36px;
    background-color: white;
  }
  .Page::-webkit-scrollbar {
    display: none;
  }
  .module-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .module-modal {
      display: flex;
      flex-direction: column;
      max-width: 85%;
      width: 85%;
      height: auto;
      max-height: 80%;
      z-index: 2;
      border-radius: 12px;
      background-color: white;
      padding: 10px;
      // gap: 10px;
      .module-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 25px;
      }
      .module-modal-apps {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: calc(100% - 25px);
        overflow-y: auto;
        padding: 0 10px;

        .modal-app {
          display: flex;
          width: 100%;
          height: 45px;
          max-height: 45px;
          min-height: 45px;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid #6a549e;
          gap: 10px;
          padding: 0 5px;
          .modal-app-container {
            display: flex;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background-color: green;
            .appModuleIcon {
              display: flex;
              height: 45px;
              justify-content: center;
              align-items: center;
              background-color: red;
            }
            .appModuleLabel {
              display: flex;
              height: 45px;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
        .aaa {
          background-color: green;
        }
        .last-modal-app {
          border-bottom: none;
        }
        .drop-apps-empty-module-matrix {
          display: flex;
          width: 100%;
          height: 80px;
          justify-content: center;
          align-items: center;
        }
      }
      .module-modal-apps::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
