.switcher {
  width: 28px;
  height: 15px;
  border-radius: 28px;
  position: relative;
  cursor: url(../../views/Designer/components/ComponentsLibrary/Assets/Pointer.svg),
    auto;
  transition: background-color 0.2s;
  &.active-switcher {
    background-color: var(--switcherBgActive);
  }
  &.inactif-switcher {
    background-color: var(--switcherBgInactif);
  }
}

.switcher-circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.2s;
  &.active-switcher-circle {
    background-color: var(--switcherCircleActive);
  }
  &.inactif-switcher-circle {
    background-color: var(--switcherCircleInactif);
  }
}
