.loading-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: #fff4;
  .loading-login-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .loadingLogo {
      display: flex;
      max-width: 25%;
    }

    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      background-color: transparent;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(---secondary);
        margin: 0 5px;
        opacity: 0.5;
        animation: pulse 1s infinite ease-in-out;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0.5;
      }
    }
  }
}
