.event {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  border-radius: 5px;
  min-height: calc((100vh - 10.5rem) * 0.9 * 0.055);
  max-height: calc((100vh - 10.5rem) * 0.9 * 0.055);
  justify-content: flex-start;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  color: var(---textColor);
  font-weight: 400;
  .event-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3%;
    color: var(---primary);
  }
}
.hovered {
  &:hover {
    background-color: #3663D633;
  }
}
