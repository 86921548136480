.CustomBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Hanken Grotesk", sans-serif;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border: none;
  flex-shrink: 0;
}

.CustomBtn:disabled {
  cursor: url(../../views/Designer/components/ComponentsLibrary/Assets/NotAllowed.svg),
    auto;
}
.customStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Hanken Grotesk", sans-serif;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border: none;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  .customStyleStart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    .labelBtn {
      display: inline-block;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Hanken Grotesk", sans-serif;
      padding-top: 3px;
    }
  }
}
.labelBtn {
  display: inline-block;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Hanken Grotesk", sans-serif;
}

.file-input {
  display: none;
}
