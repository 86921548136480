.message-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4px 0;
  .notification-title {
    display: flex;
    flex: 30%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 11px;
    font-weight: 600;
    word-wrap: break-word;
  }
  .notification-body {
    display: flex;
    flex: 70%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 11px;
    font-weight: 400;
    padding-top: 5px;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
  }
  .empty-message {
    justify-content: center;
    align-items: center;
  }
}
