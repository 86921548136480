.actionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 1rem;
  width: 100%;
  .actionHeader-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.625rem;
    width: 40%;
    .uSearch-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--SearchText);
    }
    .SearchPlaceHolder,
    .SearchPlaceHolder::placeholder {
      color: var(--SearchText);
      border: none;
      font-family: Hanken Grotesk;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.24px;
      text-align: left;
    }
    .uSearch-input {
      display: flex;
      height: 100%;
      width: 100%;
    }
  }
  .actionHeader-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-between;
  }
}
