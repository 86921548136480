.lookup-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  .header-lookup {
    display: flex;
    width: 100%;
    height: 8%;
    flex-direction: row;
    .searchbar {
      display: flex;
      height: 100%;
      width: 84%;
      min-width: 84%;
      max-width: 84%;
      padding-left: 2%;
      padding-top: 2%;
      padding-bottom: 2%;
      .input-lookup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 4%;
        padding-right: 4%;
        border-radius: 5px;
        background-color: #aa73e150;
        border: 1px solid #6f53a3;
        color: #838383;
        font-weight: 300;
        font-size: medium;
      }
    }
    .lookupNumber {
      display: flex;
      height: 100%;
      width: 16%;
      min-width: 16%;
      max-width: 16%;
      width: 10%;
      justify-content: center;
      align-items: center;
    }
  }
  .list-lookup {
    display: flex;
    width: 100%;
    height: 92%;
    flex-direction: column;
    overflow-y: auto;
    .lookup {
      display: flex;
      padding-left: 4%;
      padding-right: 4%;
      padding-top: 2%;
      padding-bottom: 2%;
      border-bottom: 1px solid #6f53a3;
    }
  }
  .list-lookup::-webkit-scrollbar {
    display: none;
  }
}
